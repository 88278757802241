export class SubsequentDeliveryUpdateModel {
    ordernumber: string;
    orderdate: string;
    posnumber: number;
    postype: number;
    quantity: number;

    constructor(obj: any = {}) {
        this.ordernumber = obj.ordernumber;
        this.orderdate = obj.orderdate;
        this.posnumber = obj.posnumber;
        this.postype = obj.postype;
        this.quantity = obj.quantity;
    }
}
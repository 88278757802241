import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'first-login-dialog',
    templateUrl: './first-login-dialog.component.html',
    styleUrls: ['./first-login-dialog.component.scss']
})
export class FirstLoginDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<FirstLoginDialogComponent>) {}

    ngOnInit(): void {
    }
}
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VaccineItem } from '../models/shop/vaccine-item.model';
import { UserRestService } from './api/user.rest-service';
import { VaccineRestService } from './api/vaccination-rest-service';
import { VaccineShipmentItem } from '../models/shop/vaccine-shipment-item.model';

@Injectable({
  providedIn: 'root',
})
export class VaccinationService implements OnDestroy {
  // Order
  public vaccineItems$ = new Subject<VaccineItem[]>();
  public archivedCartItems$ = new Subject<any[]>();
  public selectedCartItems$ = new Subject<any[]>();
  public endDate: string;
  // Shipment
  public allShipmentItems$ = new Subject<any[]>();
  public latestShipmentItemsForContractDoctors$ = new Subject<any[]>();
  public latestShipmentItemsForCompanyDoctors$ = new Subject<any[]>();
  public latestShipmentItemsForKids$ = new Subject<any[]>();
  public latestShipmentItemsForPharmacy$ = new Subject<any[]>();
  public latestShipmentItemsForZusatz$ = new Subject<any[]>();
  public oldLatestShipmentItemsForContractDoctors$ = new Subject<any[]>();
  public oldLatestShipmentItemsForCompanyDoctors$ = new Subject<any[]>();
  public lastShipmentItemsForContractDoctors$ = new Subject<VaccineShipmentItem[]>();
  public lastShipmentItemsForCompanyDoctors$ = new Subject<VaccineShipmentItem[]>();
  public lastShipmentItemsForKids$ = new Subject<VaccineShipmentItem[]>();
  public lastShipmentItemsForPharmacy$ = new Subject<VaccineShipmentItem[]>();
  public lastShipmentItemsForZusatz$ = new Subject<VaccineShipmentItem[]>();
  public selectedShipmentItems$ = new Subject<VaccineShipmentItem[]>();
  public shipmentDate: string;
  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    @Inject(VaccineRestService) public restService: VaccineRestService,
    @Inject(UserRestService) public userRestService: UserRestService
  ) {}

  loadVaccines(idf) {
    this.restService
      .requestVaccineCartForIdf(idf)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.returnObject) {
          this.endDate = response.returnObject.end;
          return this.vaccineItems$.next(response.returnObject.reservations);
        }
        return this.vaccineItems$;
      });
    return this.vaccineItems$.asObservable();
  }

  updateVaccineRequest(idf, data) {
    return this.restService.updateVaccineReservation(idf, data);
  }

  loadArchivedReservations(idf) {
    this.restService
      .getAllArchivedReservations(idf)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.returnObject) {
          return this.archivedCartItems$.next(response.returnObject);
        }
        return this.archivedCartItems$;
      });
    return this.archivedCartItems$.asObservable();
  }

  loadSingleCart(idf, id) {
    this.selectedCartItems$ = new Subject<any[]>();
    this.restService.getSingleCart(idf, id).subscribe((response) => {
      if (response.returnObject) {
        return this.selectedCartItems$.next(response.returnObject.reservations);
      }
      return this.selectedCartItems$;
    });
    return this.selectedCartItems$.asObservable();
  }

  /**
   * Shipment related functions
   */

  loadLastShipment(idf, type) {
    this.restService
      .requestLastShipmentForIdf(idf, type)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.returnObject) {
          this.shipmentDate = response.returnObject.shipmentDate;
          switch (type) {
            case 'O':
              this.lastShipmentItemsForCompanyDoctors$.next(response.returnObject.rations);
              break;
            case 'K':
              this.lastShipmentItemsForKids$.next(response.returnObject.rations);
              break;
            case 'P':
              this.lastShipmentItemsForPharmacy$.next(response.returnObject.rations);
              break;
            case 'Z':
              this.lastShipmentItemsForZusatz$.next(response.returnObject.rations);
              break;
            default:
              this.lastShipmentItemsForContractDoctors$.next(response.returnObject.rations);
              break;
          }
        }
      });


    switch (type) {
      case 'O':
        return this.lastShipmentItemsForCompanyDoctors$.asObservable();
      case 'K':
        return this.lastShipmentItemsForKids$.asObservable();
      case 'P':
        return this.lastShipmentItemsForPharmacy$.asObservable();
      case 'Z':
        return this.lastShipmentItemsForZusatz$.asObservable();
      default:
        return this.lastShipmentItemsForContractDoctors$.asObservable();
    }
  }

  loadAllShipments(idf) {
    this.restService
      .requestAllShipmentsForIdf(idf)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.returnObject) {
          return this.allShipmentItems$.next(response.returnObject);
        }
        return this.allShipmentItems$;
      });
    return this.allShipmentItems$.asObservable();
  }

  loadLatestShipments(idf, type) {
    this.restService
      .requestLatestShipmentsForIdf(idf, type)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
          if (type === 'A') {
            return response.returnObject ? this.latestShipmentItemsForContractDoctors$.next(response.returnObject) :
                this.latestShipmentItemsForContractDoctors$;
          }
          if (type === 'O') {
            return response.returnObject ? this.latestShipmentItemsForCompanyDoctors$.next(response.returnObject) :
                this.latestShipmentItemsForCompanyDoctors$;
          }
          if (type === 'V') {
            return response.returnObject ? this.oldLatestShipmentItemsForContractDoctors$.next(response.returnObject) :
                this.oldLatestShipmentItemsForContractDoctors$;
          }
          if (type === 'B') {
            return response.returnObject ? this.oldLatestShipmentItemsForCompanyDoctors$.next(response.returnObject) :
                this.oldLatestShipmentItemsForCompanyDoctors$;
          }
          if (type === 'K') {
            return response.returnObject ? this.latestShipmentItemsForKids$.next(response.returnObject) :
                this.latestShipmentItemsForKids$;
          }
          if (type === 'P') {
            return response.returnObject ? this.latestShipmentItemsForPharmacy$.next(response.returnObject) :
                this.latestShipmentItemsForPharmacy$;
          }
          if (type === 'Z') {
            return response.returnObject ? this.latestShipmentItemsForZusatz$.next(response.returnObject) :
                this.latestShipmentItemsForZusatz$;
          }
      });

    if (type === 'A') {
      return this.latestShipmentItemsForContractDoctors$.asObservable();
    }
    if (type === 'O') {
      return this.latestShipmentItemsForCompanyDoctors$.asObservable();
    }
    if (type === 'V') {
      return this.oldLatestShipmentItemsForContractDoctors$.asObservable();
    }
    if (type === 'B') {
      return this.oldLatestShipmentItemsForCompanyDoctors$.asObservable();
    }
    if (type === 'K') {
      return this.latestShipmentItemsForKids$.asObservable();
    }
    if (type === 'P') {
      return this.latestShipmentItemsForPharmacy$.asObservable();
    }
    if (type === 'Z') {
      return this.latestShipmentItemsForZusatz$.asObservable();
    }
  }

  loadSingleShipment(idf, id) {
    this.selectedShipmentItems$ = new Subject<any[]>();
    this.restService.getSingleShipment(idf, id).subscribe((response) => {
      if (response.returnObject) {
        return this.selectedShipmentItems$.next(response.returnObject.rations);
      }
      return this.selectedShipmentItems$;
    });
    return this.selectedShipmentItems$.asObservable();
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ManageAccountDataRestService} from '../../../../../core/services/api/manage-account-data.rest-service';
import {AccountInfo} from '../../../../../core/models/registration/registration-confirm-response.model';
import {OwnerData} from '../../../../../core/models/account/owner-data.model';

@Component({
    selector: "manage-owner-dialog",
    templateUrl: "./manage-owner-dialog.component.html",
    styleUrls: ["./manage-owner-dialog.component.scss"]
})
export class ManageOwnerDialogComponent implements OnInit {

    public loading: boolean = false;

    public owner: OwnerData;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ManageOwnerDialogComponent>) {
        this.owner = data.owner;
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close({data: 'cancel'});
    }

    onSuccess() {
        this.dialogRef.close({data: 'success'});
    }

    onLoad($event) {
        this.loading = $event;
    }

    onFail($event) {
        this.dialogRef.close({data: $event});
    }

}

import {Product} from "./product.model";
import {ProductType} from './product-type.model';

export class ProductsResponse {
    total: number;
    start: number;
    end: boolean;
    campaignInfo: any[];
    products: Product[]; // TODO: Maybe we need to keep this outside


    constructor(obj: any) {
        this.total = obj && obj.count || 0;
        this.start = obj && obj.page || 0;
        this.end = obj && obj.end || false;
        this.campaignInfo = [];

        if(obj && obj.campaigns){
            this.campaignInfo = obj.campaigns;
        }

        if (obj && obj.list && obj.hasOwnProperty("canCallAvailability")) {
            this.products = obj.list.map((product) => new Product({...product,
                ...{type: obj.type ? obj.type : product.orderItemType, canCallAvailability: obj.canCallAvailability}
            }));
        } else {
            this.products = obj.list.map((product) => new Product({...product,
                ...{type: obj.type ? obj.type : product.orderItemType}
            }))
        }
    }
}

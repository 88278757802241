import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs/internal/Observable";
import * as fromTopPrismaActions from '../actions/top-prisma.actions';
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {InvoicesRestService} from "../../services/api/invoices.rest-service";
import {WebshopRestService} from "../../services/api/webshop-rest.service";
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductType} from '../../models/shop/product-type.model';

@Injectable()
export class TopPrismaEffects {
    /**
     * In case of SearchInvoicesAction we're going to call the InvoicesService and then
     * we'll post a LoadInvoicesCompleteAction to the reducer or in case of failure
     * LoadInvoicesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadTopPrisma$: Observable<any> = this.actions$
        .pipe(ofType<fromTopPrismaActions.LoadTopPrismaAction>(fromTopPrismaActions.TopPrismaActionTypes.LOAD_TOPPRISMA))
        .pipe(map(action => action.payload), switchMap((productRequest) =>
            this.productRestService.loadTopPrisma(productRequest).pipe(
                switchMap(data => {
                    const prodResp = new ProductsResponse({...data.returnObject, ...{type: ProductType.TopPrisma}});
                    if (productRequest.page === 1) {
                        return [new fromTopPrismaActions.LoadTopPrismaCompleteAction(prodResp), new fromTopPrismaActions.GetBatchFromCacheAction()];
                    } else {
                        return [new fromTopPrismaActions.LoadTopPrismaCompleteAction(prodResp)];
                    }
                }),
                catchError((error) => of(new fromTopPrismaActions.LoadTopPrismaFailAction(error)))
            )
        ));

    @Effect()
    searchTopPrisma$: Observable<any> = this.actions$
        .pipe(ofType<fromTopPrismaActions.SearchShopAction>(fromTopPrismaActions.TopPrismaActionTypes.SEARCH_TOPPRISMA))
        .pipe(map(action => action.payload), switchMap((productRequest) => [new fromTopPrismaActions.LoadTopPrismaAction(productRequest)]));

    /**
     * @param {InvoicesRestService} productRestService
     * @param {Actions} actions$
     */
    constructor(
        private productRestService: WebshopRestService,
        private actions$: Actions
    ) {
    }
}

<form [formGroup]="userForm" [class.small]="small" [class.submitted]="submitted">
    <div class="row">
        <div class="col-md-6">
            <h4 *ngIf="showHeadlines">Persönliche Angaben</h4>
            <div class="pt-2">
                <mat-form-field appearance="outline">
                    <mat-label>Anrede *</mat-label>
                    <mat-select [formControlName]="'salutation'">
                        <mat-option *ngFor="let salutation of ['Herr', 'Frau']"
                                    [value]="salutation">
                            {{salutation}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="salutation.hasError('required') && submitted">Bitte wählen Sie eine Anrede</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Titel</mat-label>
                    <input data-cy="input-owner-title" matInput autocomplete="off" name="title"
                           formControlName="title">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input data-cy="input-owner-firstName" matInput autocomplete="off" name="firstName"
                           formControlName="firstName">
                    <mat-error *ngIf="firstName.hasError('required') && submitted">Bitte geben Sie Ihren Vornamen ein.
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input data-cy="input-owner-lastName" matInput autocomplete="off" name="lastName"
                           formControlName="lastName">
                    <mat-error *ngIf="lastName.hasError('required') && submitted">Bitte geben Sie Ihren Nachnamen ein.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-6">
            <h4 *ngIf="showHeadlines">Angaben zur Apotheke</h4>
            <div class="pt-2">
                <div>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Name der Apotheke</mat-label>
                        <input data-cy="input-account-name" matInput autocomplete="off" name="apotheke"
                               formControlName="name">
                        <mat-error *ngIf="name.hasError('required') && submitted">Bitte geben Sie den Namen Ihrer Apotheke
                            ein.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>IDF-Nummer</mat-label>
                    <input data-cy="input-account-idf" matInput autocomplete="off" name="idf"
                           formControlName="idf" readonly="readonly">
                    <mat-error *ngIf="idf.hasError('required') && submitted">Bitte geben Sie ihre IDF-Nummer ein</mat-error>
                    <mat-error *ngIf="idf.hasError('pattern') && submitted">Geben Sie eine 7-stellige Zahlenfolge ein.</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Straße und Hausnummer</mat-label>
                    <input data-cy="input-account-street" matInput autocomplete="off" name="street"
                           formControlName="street">
                    <mat-error *ngIf="street.hasError('required') && submitted">Bitte geben Sie Straße und Hausnummer Ihrer
                        Apotheke ein.
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>PLZ</mat-label>
                    <input data-cy="input-account-zipCode" matInput autocomplete="off" name="zipCode"
                           formControlName="zipCode">
                    <mat-error *ngIf="zipCode.hasError('required') && submitted">Bitte geben Sie Postleitzahl ihrer Apotheke
                        ein.
                    </mat-error>
                    <mat-error *ngIf="zipCode.hasError('pattern') && submitted">Ihre Postleitzahl muss 5 Ziffern
                        lang sein.
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Ort</mat-label>
                    <input data-cy="input-account-city" matInput autocomplete="off" name="city"
                           formControlName="city">
                    <mat-error *ngIf="city.hasError('required') && submitted">Bitte geben Sie den Ort ihrer Apotheke ein.
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>E-Mail</mat-label>
                    <input data-cy="input-account-email" matInput autocomplete="off" name="email"
                           formControlName="email" type="email">
                    <mat-error *ngIf="email.hasError('required') && submitted">Bitte geben Sie Ihre E-Mail-Adresse ein.
                    </mat-error>
                    <mat-error *ngIf="email.hasError('pattern') && submitted">Bitte geben Sie eine gültige E-Mail-Adresse
                        ein.
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Telefonnummer</mat-label>
                    <input data-cy="input-account-phone" matInput autocomplete="off" name="phone"
                           formControlName="phone">
                    <mat-error *ngIf="phone.hasError('required') && submitted">Bitte geben Sie die Telefonnummer Ihrer
                        Apotheke ein.
                    </mat-error>
                    <mat-error *ngIf="phone.hasError('pattern') && submitted">Bitte geben Sie eine gültige Telefonnummer ein.</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Faxnummer</mat-label>
                    <input data-cy="input-account-fax" matInput autocomplete="off" name="fax"
                           formControlName="fax">
                    <mat-error *ngIf="fax.hasError('pattern') && submitted">Bitte geben Sie eine gültige Faxnummer ein.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <button data-cy="cancel-account-update" class="btn-secondary" (click)="correct()">{{backButtonText}}</button>
        </div>
        <div class="col-md-6 text-right">
            <button data-cy="submit-account-update" class="btn-primary" (click)="apply()">Änderung beantragen</button>
        </div>
    </div>
</form>


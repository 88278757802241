import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DialogRestService} from '../../services/api/dialog.rest-service';
import {ResponseSnakeModel} from '../../models/response-snake.model';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import * as fromDialogActions from '../actions/dialog.actions';

@Injectable({
    providedIn: 'root',
})
export class DialogEffects {

    constructor(private dialogRestService: DialogRestService,
                private actions$: Actions) {
    }

    loadDialog$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromDialogActions.DialogLoadAction>(fromDialogActions.DialogActionTypes.LOAD_DIALOG))
            .pipe(map(action => action.payload),
                switchMap((action: any) => {
                        if (action) {
                            return this.dialogRestService.getActiveDialogsOnPage(action).pipe(
                                map((data: ResponseSnakeModel) => {
                                        console.log(data.return_object);
                                        return new fromDialogActions.DialogLoadCompleteAction(data.return_object);
                                    }
                                ),
                                catchError((error) => of(new fromDialogActions.DialogLoadFailAction(error)))
                            );
                        }
                    }
                )
            );
    });

    subscribeToDialog$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromDialogActions.SubscribeDialogInitAction>(fromDialogActions.DialogActionTypes.INIT_SUBSCRIBE_DIALOG))
            .pipe(map(action => action.payload),
                switchMap((action: any) =>
                    this.dialogRestService.getActiveDialogs().pipe(
                        map((data: ResponseSnakeModel) => {
                            return new fromDialogActions.SubscribeDialogCompleteAction(data.return_object);
                        }),
                        catchError((error) => of(new fromDialogActions.SubscribeDialogFailAction(error)))
                    )
                )
            );
    });
}
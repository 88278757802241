import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Product} from '../../../../../core/models/shop/product.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {CartItem} from '../../../../../core/models/shop/cart-item.model';
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {AddToCartConfirmDialogComponent} from '../add-to-cart-confirm-dialog/add-to-cart-confirm-dialog.component';
import {CartService} from '../../../../../core/services/cart.service';
import {MatomoService} from '../../../../../core/services/matomo.service';
import {Router} from '@angular/router';
import {ProductType} from '../../../../../core/models/shop/product-type.model';
import {NavService} from '../../../../../core/services/nav.service';
import {DokuLightService} from '../../../../../core/services/shop/doku-light.service';
import {ProductViewTypes} from '../../../../../core/models/enums/product-view-types.model';
import {AvailabilityStatus} from '../../../../../core/models/shop/product-availability-status.model';

@Component({
    selector: 'cart-provider',
    templateUrl: './cart-provider.component.html',
    styleUrls: ['./cart-provider.component.scss']
})
export class CartProviderComponent implements OnInit, OnDestroy {

    public ngUnsubscribe$ = new Subject<any>();
    public ProductType: typeof ProductType = ProductType;
    public text: string;

    public addToCartForm: FormGroup;
    public quantityInput: FormControl = new FormControl();

    public quantity = 1;

    @Output() activate: EventEmitter<string> = new EventEmitter<string>();
    @Output() deactivate: EventEmitter<string> = new EventEmitter<string>();
    @Output() changeQuantity: EventEmitter<any> = new EventEmitter<any>();
    @Output() showSubsequentDeliveryModal: EventEmitter<any> = new EventEmitter();

    @Input() product: Product;
    @Input() active: boolean;


    constructor(
        public dialog: MatDialog,
        @Inject(CartService) public cartService: CartService,
        public matomoService: MatomoService,
        public router: Router,
        public navService: NavService,
        public dukulightService?: DokuLightService,
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

    increaseQuantity(): void {
        if (this.quantity < 999) {
            this.quantity ++;
        }

        this.changeQuantity.emit({qty: this.quantity, product: this.product.id});
    }

    decreaseQuantity(): void {
        if (this.quantity > 1) {
            this.quantity --;
        }

        this.changeQuantity.emit({qty: this.quantity, product: this.product.id});
    }

    updateQuantity(e): void {
        if (e.srcElement.value < 1) {
            e.srcElement.value = 1;
            this.quantityInput.setValue(1);
        } else if (e.srcElement.value > 999) {
            e.srcElement.value = 999;
            this.quantityInput.setValue(999);
        }

        if (e.srcElement.value > 0) {
            this.quantity = e.srcElement.value;
        }

        this.changeQuantity.emit({qty: this.quantity, product: this.product.id});
    }

    openAddConfirmDialog(item: CartItem): MatDialogRef<AddToCartConfirmDialogComponent, MatDialogConfig> {
        const confirmDialogConfig = new MatDialogConfig();
        confirmDialogConfig.autoFocus = true;
        confirmDialogConfig.data = {perform: false, items: [item]};
        return this.dialog.open(AddToCartConfirmDialogComponent, confirmDialogConfig);
    }

    addToCart(): void {
        //     this.deactivate.emit(this.product.id);
        const item = new CartItem({
            product: this.product,
            quantity: this.quantity
        });
        if (item.quantity < 100) {
            this.cartService.addToCart([item]);
            this.matomoService.addItemToCart(item);
            this.matomoService.updateCartTotal(this.cartService.calculateTotals().total);
        } else {
            const dialogRef = this.openAddConfirmDialog(item);
            dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
                if (!result) {
                    return;
                } else {
                    this.cartService.addToCart([item]);
                    this.matomoService.addItemToCart(item);
                    this.matomoService.updateCartTotal(this.cartService.calculateTotals().total);
                }
            });
        }
    }

    loadProduct(pzn) {
        const type = this.navService.getRouteForProductType(this.product.type);
        this.router.navigate(['/product-details/' + type + '/' + pzn ], { replaceUrl: true });
    }

    sortPackage = (packages: any) => {
        return packages.sort((a: any, b: any) => Number(a.amountText.split(' ')[0]) - Number(b.amountText.split(' ')[0]));
    }

    ngOnInit() {
        if (this.product) {
            this.quantity = this.product.price[0].minQuantity || 1;
            this.addToCartForm = new FormGroup({
                quantityInput: new FormControl(this.quantity,
                    [
                        Validators.min(1),
                        Validators.max(999)
                    ])
            });
        }
        if (this.product.availabilityStatus !== AvailabilityStatus.SUBSEQUENT_ORDER &&
            this.product.availabilityStatus !== AvailabilityStatus.ENABLE
            && !this.product.promotion) {
            this.addToCartForm.get('quantityInput').setValue(null);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    get isAvailable(): boolean {
        return this.product.availabilityStatus === AvailabilityStatus.ENABLE ||
            this.product.availabilityStatus === AvailabilityStatus.SUBSEQUENT_ORDER ||
            this.product.promotion;
    }

    get availabilityText(): string {
        if (this.product.availabilityStatus === AvailabilityStatus.ENABLE || this.product.promotion) {
            return 'verfügbar';
        } else if (this.product.availabilityStatus === AvailabilityStatus.SUBSEQUENT_ORDER) {
            return 'nachlieferfähig';
        } else {
            return 'nicht verfügbar';
        }
    }

    get availabilityClass(): string {
        if (this.product.availabilityStatus === AvailabilityStatus.ENABLE || this.product.promotion) {
            return 'available';
        } else if (this.product.availabilityStatus === AvailabilityStatus.SUBSEQUENT_ORDER) {
            return 'deliverable-in';
        } else {
            return 'not-available';
        }
    }

    get availabilityIconClass(): string {
        return this.isAvailable ? 'fa-check' : 'fa-ban';
    }

    get isMultiplePackages(): boolean {
        return this.product.packageProducts.length > 1 && this.router.url.includes('product-details');
    }

    get packageClass(): string {
        return this.product.packageProducts.length > 3 ? 'box' : 'd-flex';
    }

    selectPackage(pack: any): void {
        if (this.product.id !== pack.pzn && pack.available) {
            this.loadProduct(pack.pzn);
        }
    }

    get buttonPositionStyle(): { bottom: string } {
        return { bottom: this.dukulightService.productViewType === ProductViewTypes.GALLERY ? '-20px' : '10px' };
    }

    get inputClass(): string {
        return this.isAvailable ? '' : 'input-not-available';
    }

    get canAddToCart(): boolean {
        return this.product.retailPrice > 0 &&
            (this.product.availabilityStatus === AvailabilityStatus.ENABLE || this.product.promotion) &&
            this.product.availabilityStatus !== AvailabilityStatus.SUBSEQUENT_ORDER;
    }

    get isDisabled(): boolean {
        return this.product.availabilityStatus === AvailabilityStatus.DISABLE && !this.product.promotion;
    }

    get isSubsequentOrder(): boolean {
        return this.product.retailPrice > 0 &&
            this.product.availabilityStatus === AvailabilityStatus.SUBSEQUENT_ORDER;
    }

    get isNotAvailable(): boolean {
        return this.product.retailPrice === 0 ||
            (this.product.availabilityStatus === AvailabilityStatus.DISABLE && !this.product.promotion);
    }

    openSubsequentDeliveryModal(product: Product) {
        this.showSubsequentDeliveryModal.emit(product);
    }

}

export class Totals {

    constructor(obj: any = {}) {
        this._quantityTotal = obj && obj.quantityTotal || 0;
        this._total = obj && obj.total || 0;
        this._paybackTotal = obj && obj._paybackTotal || 0;
        this._shipping = obj && obj.shipping || 0;
    }

    private _shipping: number;
    get shipping(): number {
        return this._shipping;
    }

    set shipping(value: number) {
        this._shipping = value;
    }

    private _quantityTotal: number;
    get quantityTotal(): number {
        return this._quantityTotal;
    }

    set quantityTotal(value: number) {
        this._quantityTotal = value;
    }

    private _total: number;
    get total(): number {
        return this._total;
    }

    set total(value: number) {
        this._total = value;
    }

    private _paybackTotal: number;
    get paybackTotal(): number {
        return this._paybackTotal;
    }

    set paybackTotal(value: number) {
        this._paybackTotal = value;
    }
}

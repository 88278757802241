export class AccountData {
    name: string;
    street: string;
    zipCode: string;
    city: string;
    email: string;
    phone: string;
    fax: string;
    idf: string;

    constructor(obj: any = {}) {
        this.name = obj && obj.name || null;
        this.street = obj && obj.street || null;
        this.zipCode = obj && obj.zipCode || null;
        this.city = obj && obj.city || null;
        this.email = obj && obj.email || null;
        this.phone = obj && obj.phone || null;
        this.fax = obj && obj.fax || null;
        this.idf = obj && obj.idf || null;
    }
}
import {Action} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {ProductRequest} from "../../models/shop/product-request.model";

export enum TopPrismaActionTypes {
    LOAD_TOPPRISMA = '[TOPPRISMA] Load TOPPRISMA',
    LOAD_TOPPRISMA_COMPLETE = '[TOPPRISMA] Load TOPPRISMA Complete',
    LOAD_TOPPRISMA_FAIL = '[TOPPRISMA] Load TOPPRISMA FAIL',
    SEARCH_TOPPRISMA = '[TOPPRISMA] Search',
    UPDATE_SCROLLING_POSITION = '[TOPPRISMA] Update Scrolling Position',
    GET_BATCH_FROM_CACHE = '[TOPPRISMA] Push batch in visible list'
}

export class LoadTopPrismaAction implements Action {
    readonly type = TopPrismaActionTypes.LOAD_TOPPRISMA;

    constructor(public payload: ProductRequest) { // == Query
    }
}

export class LoadTopPrismaCompleteAction implements Action {
    readonly type = TopPrismaActionTypes.LOAD_TOPPRISMA_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }
}

export class LoadTopPrismaFailAction implements Action {
    readonly type = TopPrismaActionTypes.LOAD_TOPPRISMA_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class SearchShopAction implements Action {
    readonly type = TopPrismaActionTypes.SEARCH_TOPPRISMA;

    constructor(public payload: ProductRequest) {};
}

export class UpdateScrollingPositionAction implements Action {
    readonly type = TopPrismaActionTypes.UPDATE_SCROLLING_POSITION;

    constructor(public payload: number) {};
}

export class GetBatchFromCacheAction implements Action {
    readonly type = TopPrismaActionTypes.GET_BATCH_FROM_CACHE;

    constructor() {};
}


export type TopPrismaActions =
    LoadTopPrismaAction |
    LoadTopPrismaCompleteAction |
    LoadTopPrismaFailAction |
    SearchShopAction |
    UpdateScrollingPositionAction |
    GetBatchFromCacheAction;
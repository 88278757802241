export class OwnerData {
    firstName: string;
    lastName: string;
    title: string;
    salutation: string;

    constructor(obj: any = {}) {
        this.firstName = obj && obj.firstName || null;
        this.lastName = obj && obj.lastName || null;
        this.title = obj && obj.title || null;
        this.salutation = obj && obj.salutation || null;
    }
}
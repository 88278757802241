import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {SubsequentDeliveryModel} from '../../models/subsequent-deliveries/subsequent.delivery.model';
import {SubsequentDeliveriesRequest} from '../../models/subsequent-deliveries/subsequent.deliveries.request';
import {SubsequentDeliveriesResponse} from '../../models/subsequent-deliveries/subsequent.deliveries.response';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductSearchRequest} from '../../models/shop/product-search-request.model';

export enum SubsequentDeliveriesActionsType {
    INIT_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Init',
    LOAD_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Load',
    LOAD_SUBSEQUENT_DELIVERIES_COMPLETED = '[SUBSEQUENT DELIVERIES] Load Completed',
    UPDATE_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Update',
    DELETE_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Delete',
    FAIL_SUBSEQUENT_DELIVERIES = '[SUBSEQUENT DELIVERIES] Failed',
    SEARCH_PRODUCT = '[SUBSEQUENT DELIVERIES] Search Product',
    LOAD_PRODUCTS = '[SUBSEQUENT DELIVERIES] Load Products Suggestion',
    LOAD_PRODUCTS_COMPLETE = '[SUBSEQUENT DELIVERIES] Load Products Complete',
    LOAD_PRODUCTS_FAILED = '[SUBSEQUENT DELIVERIES] Load Products Failed',
    RESET_SEARCH_RESULT = '[SUBSEQUENT DELIVERIES] Reset Products search',
    ADD_SUBSEQUENT_DELIVERY = '[SUBSEQUENT DELIVERIES] Add Subsequent Delivery',
    ADD_SUBSEQUENT_DELIVERY_COMPLETE = '[SUBSEQUENT DELIVERIES] Add Subsequent Delivery Complete',
    ADD_SUBSEQUENT_DELIVERY_FAILED = '[SUBSEQUENT DELIVERIES] Add Subsequent Delivery Failed'
}

export class InitSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.INIT_SUBSEQUENT_DELIVERIES;

    constructor() { // == Query
    }
}

export class LoadSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES;

    constructor(public payload: any) { // == Query
    }
}

export class LoadSubsequentDeliveriesCompleted implements Action{
    readonly type = SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES_COMPLETED;

    constructor(public payload: any) { // == Query
    }
}

export class UpdateSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.UPDATE_SUBSEQUENT_DELIVERIES;

    constructor(public payload: any) { // == Query
    }
}

export class DeleteSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.DELETE_SUBSEQUENT_DELIVERIES;

    constructor(public payload: any) { // == Query
    }
}

export class FailSubsequentDeliveries implements Action{
    readonly type = SubsequentDeliveriesActionsType.FAIL_SUBSEQUENT_DELIVERIES;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export class SearchShopAction implements Action {
    readonly type = SubsequentDeliveriesActionsType.SEARCH_PRODUCT;

    constructor(public payload: ProductSearchRequest) {}
}

export class LoadProducts implements Action {
    readonly type = SubsequentDeliveriesActionsType.LOAD_PRODUCTS;

    constructor(public payload: ProductSearchRequest) { // == Query
    }
}

export class LoadProductsCompleted implements Action {
    readonly type = SubsequentDeliveriesActionsType.LOAD_PRODUCTS_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }
}

export class LoadProductsFailed implements Action {
    readonly type = SubsequentDeliveriesActionsType.LOAD_PRODUCTS_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class ResetSearchResult implements Action {
    readonly type = SubsequentDeliveriesActionsType.RESET_SEARCH_RESULT;

    constructor() {}
}

export class AddNewSubsequentDelivery implements Action {
    readonly type = SubsequentDeliveriesActionsType.ADD_SUBSEQUENT_DELIVERY;

    constructor() {
    }
}

export class AddNewSubsequentDeliveryComplete implements Action {
    readonly type = SubsequentDeliveriesActionsType.ADD_SUBSEQUENT_DELIVERY_COMPLETE;

    constructor(public payload: any) {
    }
}

export class AddNewSubsequentDeliveryFailed implements Action {
    readonly type = SubsequentDeliveriesActionsType.ADD_SUBSEQUENT_DELIVERY_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type SubsequentDeliveriesActions = InitSubsequentDeliveries
| LoadSubsequentDeliveries
| LoadSubsequentDeliveriesCompleted
| UpdateSubsequentDeliveries
| DeleteSubsequentDeliveries
| FailSubsequentDeliveries
| SearchShopAction
| LoadProducts
| LoadProductsCompleted
| LoadProductsFailed
| ResetSearchResult
| AddNewSubsequentDelivery
| AddNewSubsequentDeliveryComplete
| AddNewSubsequentDeliveryFailed;

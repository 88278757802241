import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromReducers from '../../store/reducers';
import {ProductServiceAbstract} from './product-service.abstract';
import {WebshopRestService} from '../api/webshop-rest.service';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {Product} from '../../models/shop/product.model';

import {
    UberEmptyCart, UberLoadAction,
    UberLoadVendorsAction, UberReset,
    UberUpdateCart,
    UberUpdateScrollPositionAction
} from '../../store/actions/uber.actions';
import {ProductRequest} from '../../models/shop/product-request.model';

@Injectable()
export class UberService extends ProductServiceAbstract {

    constructor(public store: Store<fromReducers.State>,
                public shopService: WebshopRestService) {
        super(store, shopService);
    }

    private _uberCart$: Observable<any>;


    get uberCart$(): Observable<any> {
        return this._uberCart$;
    }

    set uberCart$(value: Observable<any>) {
        this._uberCart$ = value;
    }

    // =================
    // ALL Uber vendors
    // =================
    loadAllUberVendors(): Observable<any[]> {
        this.store.dispatch(new UberLoadVendorsAction());
        return this.getUberVendorsObservable();
    }

    getUberVendorsLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getUberVendorsLoading));
    }

    getUberVendorsObservable(): Observable<any[]> {
        return this.store.pipe(select(fromReducers.getUberVendors));
    }

    getUberVendorsErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getUberVendorsErrorResponse));
    }

    // ================
    // Uber by vendors
    // ================
    getProductsLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getUberLoading));
    }

    getProductsResponseObservable(): Observable<ProductsResponse> {
        return this.store.pipe(select(fromReducers.getUberResponse));
    }

    getProductsRequestObservable(): Observable<ProductRequest> {
        return this.store.pipe(select(fromReducers.getUberRequest));
    }

    getProductsObservable(): Observable<Product[]> {
        return this.store.pipe(select(fromReducers.getUberProducts));
    }

    getProductsErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getUberErrorResponse));
    }

    getScrollPositionObservable(): Observable<number> {
        return this.store.pipe(select(fromReducers.getUberScrollPosition));
    }

    getUberCartObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getUberCart));
    }

    updateUberCart(amount: number, product: Product): void {
        const updateUberCartAction: UberUpdateCart = new UberUpdateCart(
            {
                id: product.id + product.campaignId,
                cartItem: {amount, product}
            }
        );
        this.store.dispatch(updateUberCartAction);
    }

    emptyUberCart(campaignId) {
        const emptyUberCartAction: UberEmptyCart = new UberEmptyCart(campaignId);
        this.store.dispatch(emptyUberCartAction);
    }

    displayNewBatch(request: ProductRequest) {
    }

    getProductCacheObservable(): Observable<Product[]> {
        return this.store.pipe(select(fromReducers.getUberProductCache));
    }

    searchProducts(request: any) {
        this.store.dispatch(new UberLoadAction(request.vendorId));
    }

    updateScrollingPosition(pos: number) {
        return this.store.dispatch(new UberUpdateScrollPositionAction(pos));
    }

    resetProducts() {
        return this.store.dispatch(new UberReset());
    }
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs/internal/Observable";
import * as fromParagraph73Actions from '../actions/paragraph73.actions';
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {InvoicesRestService} from "../../services/api/invoices.rest-service";
import {WebshopRestService} from "../../services/api/webshop-rest.service";
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductType} from '../../models/shop/product-type.model';

@Injectable()
export class Paragraph73Effects {
    /**
     * In case of SearchInvoicesAction we're going to call the InvoicesService and then
     * we'll post a LoadInvoicesCompleteAction to the reducer or in case of failure
     * LoadInvoicesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadParagraph73$: Observable<any> = this.actions$
        .pipe(ofType<fromParagraph73Actions.LoadParagraph73Action>(fromParagraph73Actions.Paragraph73ActionTypes.LOAD_PARAGRAPH73))
        .pipe(map(action => action.payload), switchMap((productRequest) =>
            this.productRestService.loadParagraph73(productRequest).pipe(
                switchMap(data => {
                    const prodResp = new ProductsResponse({...data.returnObject, ...{type: ProductType.Paragraph73}});
                    if (productRequest.page === 1) {
                        return [new fromParagraph73Actions.LoadParagraph73CompleteAction(prodResp), new fromParagraph73Actions.GetBatchFromCacheAction()];
                    } else {
                        return [new fromParagraph73Actions.LoadParagraph73CompleteAction(prodResp)];
                    }
                }),
                catchError((error) => of(new fromParagraph73Actions.LoadParagraph73FailAction(error)))
            )
        ));

    @Effect()
    searchParagraph73$: Observable<any> = this.actions$
        .pipe(ofType<fromParagraph73Actions.SearchShopAction>(fromParagraph73Actions.Paragraph73ActionTypes.SEARCH_PARAGRAPH73))
        .pipe(map(action => action.payload), switchMap((productRequest) => [new fromParagraph73Actions.LoadParagraph73Action(productRequest)]));




    /**
     * @param {InvoicesRestService} productRestService
     * @param {Actions} actions$
     */
    constructor(
        private productRestService: WebshopRestService,
        private actions$: Actions
    ) {
    }
}

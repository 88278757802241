import { map, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { RestServiceAbstract } from '../../abstracts/rest-service.abstract';
import {Observable, Subject} from 'rxjs';
import {OrderRequest} from '../../models/shop/order-request.model';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Response} from '../../models/response.model';
import {ProductType} from '../../models/shop/product-type.model';
import {ProductFile} from '../../models/shop/product-file.model';
import {HttpResponse} from '@angular/common/http';
import {placeholdersToParams} from "@angular/compiler/src/render3/view/i18n/util";
import {ProductSearchRequest} from "../../models/shop/product-search-request.model";

@Injectable({
    providedIn: 'root',
})
export class WebshopRestService extends RestServiceAbstract implements OnDestroy {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();
    public download = new Subject<String>();

    private filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

    /**
     * Required by special offers effect to retrieve special offers
     * and add them ti state on LoadSpecialOffersAction
     *
     * @returns {Observable<Response>}
     */
    public loadSpecialOffers(productRequest: ProductRequest) {
        return this.get(`/resource/product/offers/pin`, {params: productRequest}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public loadTopPrisma(productRequest: ProductRequest) {
        return this.get(`/resource/product/offers/topprisma`, {params: productRequest}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public loadExtraPrisma(productRequest: ProductRequest) {
        return this.get(`/resource/product/offers/extraprisma`, {params: productRequest}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.ExtraPrisma}});
            })
        );
    }

    public loadDokuLight(productRequest: ProductSearchRequest) {
        return this.get(`/resource/product/v2/search`, {params: productRequest}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.DokuLight}});
            })
        );
    }

    public loadSubsequentDelivery(productRequest: ProductSearchRequest) {
        return this.get(`/resource/product/v2/search`, {params: productRequest}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.SubsequentDelivery}});
            })
        );
    }

    public loadParagraph73(productRequest: ProductRequest) {
        return this.get(`/resource/product/offers/paragraph73`, {params: productRequest}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.Paragraph73, canCallAvailability: false}});
            })
        );
    }

    public loadPayback(productRequest: ProductRequest) {
        return this.get(`/resource/product/offers/payback`, {params: productRequest}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.Payback, canCallAvailability: false}});
            })
        );
    }

    public loadExclusiveOffers() {
        return this.get(`/resource/product/offers/exclusive`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.OffersExclusive, canCallAvailability: false}});
            })
        );
    }

    public loadProFax() {
        return this.get(`/resource/product/offers/profax/campaigns`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.Profax, canCallAvailability: false}});
            })
        );
    }

    public loadPromo() {
        return this.get('/resource/product/offers/promo').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.Promo, canCallAvailability: false}});
            })
        );
    }

    public loadUberVendors() {
        return this.get('/resource/product/offers/uber').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.Uber, canCallAvailability: false}});
            })
        );
    }

    public loadUberByVendorId(vendorId: number) {
        return this.get(`/resource/product/offers/uber/${vendorId}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response({...data, ...{type: ProductType.Uber, canCallAvailability: false}});
            })
        );
    }

    public getShippingCostParagraph73() {
        return this.get(`/resource/product/shippingcostparagraph73`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * @param pzns
     * @return {Observable<Response>}
     */
    public loadDetailedAvailability(pzns: string[]): Observable<Response> {
        const params = {artikelMengen: pzns};
        return this.get(`/resource/order/availability`, {params: params}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * @param request
     * @return {Observable<Object>}
     */
    public order(request: OrderRequest): Observable<Response> {
        return this.post(`/resource/order/order`, request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public loadProduct(id: string, type: ProductType, withDetails: boolean) {
        let apiUrl = ``;
        let params = {};

        switch (type) {
            case ProductType.Paragraph73:
                if (withDetails) {
                    apiUrl = `/resource/product/detailsparagraph73`;
                } else {
                    apiUrl = `/resource/product/fullparagraph73`;
                }
                params = {params: {artNo: id}};
                break;
            case ProductType.OffersExclusive:
                if (withDetails) {
                    apiUrl = `/resource/product/detailsexclusive`;
                } else {
                    apiUrl = `/resource/product/fullexclusive`;
                }
                params = {params: {pzn: id}};
                break;
            default:
                if (withDetails) {
                    apiUrl = `/resource/product/v2/details`;
                } else {
                    apiUrl = `/resource/product/v2/full`;
                }
                params = {params: {pzn: id}};
        }

        return this.get(apiUrl, params).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public loadProductDetails(id: string, type: ProductType) {
        return this.loadProduct(id, type, true);
    }

    public loadFullProduct(id: string, type: ProductType) {
        return this.loadProduct(id, type, false);
    }

    /**
     * Required by invoices list to request invoice download
     *
     * @returns {Observable<any>}
     * @param file
     */
    public downloadFile(file: ProductFile) {
        this.log.info(file);
        this.get(file, {
            observe: 'response',
            responseType: 'blob'
        }).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.hasOwnProperty('body')) {
                    const blob = new Blob([response.body], {type: 'application/zip'});
                    const fileURL = URL.createObjectURL(blob);

                    // get file name
                    let filename = 'phoenix_download.zip';
                    const disposition = response.headers.get('content-disposition');
                    if (disposition && disposition.indexOf('attachment') !== 1) {
                        const matches = this.filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        fileLink.download = filename;
                        fileLink.dispatchEvent(new MouseEvent(`click`, {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        }));
                    }
                    fileLink.remove();
                }
            }
        );
    }

    downloadFileForPaybackConfig() {
        this.log.info(ProductFile.PaybackConfig);
        return this.get(
            ProductFile.PaybackConfig,
            {
                responseType: 'blob'
            }
        )
            .pipe(map(
                (response: HttpResponse<any>) => {
                    if (response) {
                        const respAsBlob: Blob = <Blob>(<any>response);
                        const fileURL = URL.createObjectURL(respAsBlob);
                        const filename = 'PHOENIX Pharmacy Portal Payback Tools.zip';
                        const fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(respAsBlob, filename);
                        } else {
                            fileLink.download = filename;
                            fileLink.dispatchEvent(new MouseEvent(`click`, {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));
                        }
                        fileLink.remove();
                    }
                })
            );
    }

    public getFileDate(type, idf) {
        return this.get('/resource/download/fileDate', {params: {type: type, idf: idf}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return data;
            })
        );
    }

    downloadPrismaFile(idf) {
        this.startDownload();
        this.get('/resource/download/lists', {
            params: {idf: idf},
            observe: 'response',
            responseType: 'blob'
        })
            .subscribe(
                (response: HttpResponse<any>) => {
                    if (response && response.hasOwnProperty('body')) {
                        const blob = new Blob([response.body], {type: 'application/zip'});
                        const fileURL = URL.createObjectURL(blob);

                        // get file name
                        let filename = 'angebot_phoenix.zip';
                        const disposition = response.headers.get('content-disposition');
                        if (disposition && disposition.indexOf('attachment') !== 1) {
                            const matches = this.filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) {
                                filename = matches[1].replace(/['"]/g, '');
                            }
                        }

                        const fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, filename);
                        } else {
                            fileLink.download = filename;
                            fileLink.dispatchEvent(new MouseEvent(`click`, {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));
                        }
                        fileLink.remove();
                        this.stopDownload();
                    }
                }
            );

    }

    downloadBasisFile(idf) {
        this.startDownload();
        this.get('/resource/download/basisListe', {
            params: {idf: idf},
            observe: 'response',
            responseType: 'blob'
        }).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.hasOwnProperty('body')) {
                    const blob = new Blob([response.body], {type: 'application/zip'});
                    const fileURL = URL.createObjectURL(blob);

                    // get file name
                    let filename = 'basisliste_phoenix.zip';
                    const disposition = response.headers.get('content-disposition');
                    if (disposition && disposition.indexOf('attachment') !== 1) {
                        const matches = this.filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        fileLink.download = filename;
                        fileLink.dispatchEvent(new MouseEvent(`click`, {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        }));
                    }
                    fileLink.remove();
                    this.stopDownload();                }
            }
        );
    }

    watchDownload(): Observable<any> {
        return this.download.asObservable();
    }

    startDownload() {
        this.download.next('start');
    }

    stopDownload() {
        this.download.next('end');

    }


    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properlly.
        this.ngUnsubscribe$.complete();
    }

}

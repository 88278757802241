import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromReducers from '../../store/reducers';
import {WebshopRestService} from '../api/webshop-rest.service';
import {ProductServiceAbstract} from './product-service.abstract';
import {
    GetBatchFromCacheAction,
    LoadExclusiveOffersAction,
    SearchShopAction,
    UpdateScrollingPositionAction
} from '../../store/actions/exclusive-offers.actions';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Observable} from 'rxjs';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {Product} from '../../models/shop/product.model';

@Injectable({
    providedIn: 'root'
})
export class ExclusiveOffersService extends ProductServiceAbstract {

    constructor(public store: Store<fromReducers.State>, @Inject(WebshopRestService) public webshopRestService: WebshopRestService) {
        super(store, webshopRestService);
    }

    updateScrollingPosition(pos: number): void {
        this.store.dispatch(new UpdateScrollingPositionAction(pos));
    }

    searchProducts(request: ProductRequest = new ProductRequest({})): void {
        this.store.dispatch(new SearchShopAction());
    }

    displayNewBatch(request: ProductRequest = new ProductRequest({})): void {
        let end: boolean = false;
        this.store.pipe(select(fromReducers.getExclusiveOffersResponse))
            .subscribe(response => end = response.end).unsubscribe();

        // determine how much cache is left
        let cacheSize: number = 0;
        this.store.pipe(select(fromReducers.getExclusiveOffersProductCache))
            .subscribe(cache => cacheSize = cache.length).unsubscribe();

        if (!end && cacheSize < 2 * 20) {
            this.store.dispatch(new LoadExclusiveOffersAction());
        }

        // get batch from cache
        this.store.dispatch(new GetBatchFromCacheAction());
    }

    loadAllExclusiveProducts(): void {
        this.store.dispatch(new LoadExclusiveOffersAction());
    }

    /**
     * GETTERS
     */
    getProductsLoadingObservable(): Observable<boolean> {
        this.productsLoading = this.store.pipe(select(fromReducers.getExclusiveOffersLoading));
        return this.productsLoading;
    }

    getProductsResponseObservable(): Observable<ProductsResponse> {
        this.productsResponse$ = this.store.pipe(select(fromReducers.getExclusiveOffersResponse));
        //this.checkAvailability();
        return this.productsResponse$;
    }

    getProductsRequestObservable(): Observable<ProductRequest> {
        this.productsRequest$ = this.store.pipe(select(fromReducers.getExclusiveOffersRequest));
        //this.checkAvailability();
        return this.productsRequest$;
    }

    getProductsObservable(): Observable<Product[]> {
        this.products$ = this.store.pipe(select(fromReducers.getExclusiveOffersProducts));
        return this.products$;
    }

    getScrollPositionObservable(): Observable<number> {
        this.scrollPosition$ = this.store.pipe(select(fromReducers.getExclusiveOffersScrollPosition));
        return this.scrollPosition$;
    }

    getProductCacheObservable(): Observable<Product[]> {
        this.productCache$ = this.store.pipe(select(fromReducers.getExclusiveOffersProductCache));
        return this.productCache$;
    }
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserService} from '../../../core/services/user.service';
import {NavType} from '../../../core/models/nav-item.model';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {LindaRestService} from '../../../core/services/api/linda.rest-service';
import {MatomoService} from '../../../core/services/matomo.service';


@Component({
    selector: 'app-idf-selection-dialog',
    templateUrl: './idf-selection-dialog.component.html',
    styleUrls: ['./idf-selection-dialog.component.scss']
})
export class IdfSelectionDialogComponent implements OnInit, OnDestroy {

    public NavType: typeof NavType = NavType;
    private ngUnsubscribe$ = new Subject<any>();
    private idfs = [];

    constructor(@Inject('DA_STORE_URL') private DA_STORE_URL: string,
                @Inject('DA_STORE_REFERRER') private DA_STORE_REFERRER: string,
                @Inject('ND_URL') private ND_URL: string,
                @Inject('ND_REFERRER') private ND_REFERRER: string,
                @Inject('ND_FLAG') private ND_FLAG: string,
                @Inject(MAT_DIALOG_DATA) public data: {
                    navType: NavType,
                    userName: string,
                    imageFileName: string,
                    heading: string,
                    message: string,
                    authTokens: Observable<any>,
                    authTokensLoading: Observable<boolean>
                },
                private lindaRestService: LindaRestService,
                @Inject(UserService) public userService: UserService,
                private matomoService: MatomoService) {
        this.userService.selectContextualizedIdfsObservable('all').pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
            this.idfs = res;
        });
    }

    ngOnInit() {
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    goToLinda(idf){
        this.lindaRestService.getRedirect(idf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ret => {
            if (ret.errorCode === null) {
                this.matomoService.trackEvent('LINDA', 'Klick auf Jetzt entdecken!','LINDA api');
                window.location = ret.returnObject.url + ret.returnObject.token;
            }
        });
    }

    getLabel(key) {
        return this.idfs?.find(idf => idf.idf === key).name;
    }
}

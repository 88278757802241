<h2 mat-dialog-title>Ändern Sie Ihre Angaben zur Apotheke</h2>
<div mat-dialog-content>
    <p>Hier haben Sie die Möglichkeit Änderungen an Angaben der Apotheke zu beantragen.</p>
    <spinner *ngIf="loading"></spinner>
    <div class="row panel-body">
        <div class="col-md-12">
            <edit-data [class]="loading ? 'invisible' : ''"
                       [restService]="manageAccountDataRestService"
                       [data]="account"
                       [showHeadlines]="false"
                       [small]="true"
                       backButtonText="Abbrechen"
                       (application)="onSuccess()"
                       (correction)="onClose()"
                       (fail)="onFail($event)"
                       (loading)="onLoad($event)"></edit-data>
        </div>
    </div>
</div>
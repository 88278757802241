import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {DialogPopupInteface} from '../../../core/interfaces/dialog-popup.interface';
import {MatomoService} from '../../../core/services/matomo.service';
import {DialogRestService} from '../../../core/services/api/dialog.rest-service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {UserService} from '../../../core/services/user.service';
import {takeUntil, takeLast, shareReplay} from 'rxjs/operators';
import {CommunicationRestService} from '../../../core/services/api/communication.rest-service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-communication-dash-dialog',
    templateUrl: './communication-dash-dialog.component.html',
    styleUrls: ['./communication-dash-dialog.component.scss']
})
export class CommunicationDashDialogComponent implements OnInit, OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();
    public parsedBody = [];
    public sent = false;
    private main;
    loading = true;
    subscribed = false;
    success: boolean = false;
    message = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogPopupInteface,
                private dialogRef: MatDialogRef<CommunicationDashDialogComponent>,
                private matomoService: MatomoService,
                private dialogRestService: DialogRestService,
                private userService: UserService,
                private communicationService: CommunicationRestService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.userService.loadFullIdfs().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(idfs => {
                this.main = idfs.returnObject[0];
                if (this.main) {
                    this.parseBody(this.data.body);
                    this.loading = false;
                }
            },
            error => {
                this.dialogRef.close();
                this.loading = false;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private parseBody(body: string) {
        this.parsedBody = [];
        if (body !== undefined) {
            const matches = body.match(/\[form=([0-9]*)]/g);
            const email = body.match(/\[email=([a-zA-Z,_]+)]/g);
            if (matches) {
                for (const match of matches) {
                    const split = body.split(match);
                    this.parsedBody.push({data: split[0], type: 'string'});
                    this.parsedBody.push({data: match.replace(/\D/g, '').trim(), type: 'form'});
                    if (split.length > 1) {
                        this.parsedBody.push({data: split[1], type: 'string'});
                    }
                }
            } else if (email) {
                const split = body.split(email[0]);
                this.parsedBody.push({data: split[0], type: 'string'});
                const type =  email[0].replace('[email=', '')
                this.parsedBody.push({data:type.replace(']', '').trim(), email: this.main.email, type: 'email'});
                if (split.length > 1) {
                    this.parsedBody.push({data: split[1], type: 'string'});
                }
            } else {
                this.parsedBody.push({data: body, type: 'string'});
            }
        }
    }

    onSubscribe(email, type) {
        let values = {};
        values['type'] = type;
        values['active'] = true;
        values['emails'] = [email];
        this.communicationService.saveConfig(this.main.idf, values).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
            this.success = true;
            this.message = 'Ihre E-Mail Adresse wurde erfolgreich gespeichert.<br> Das Abonnement für Belieferungsinformationen ist nun aktiv.';
            this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup Link', 'Abonnement OK', 2);
            setTimeout(() => {
                this.dialogRef.close();
                this.success = false;
            },5000);
        });
    }

  onSuccess($event: any) {
    this.dialogRef.close();
  }

    protected readonly window = window;


    redirect() {
        this.dialogRef.close();
        this.router.navigate([this.data.link]);
    }

    subscribe(name) {
        this.dialogRestService.subscribeToDialogOffer(this.data.id).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
            if (res.return_object !== null && res.error_code === null) {
                this.subscribed = true;
                if (this.data.link != null) {
                    window.open(this.data.link, this.data.target);
                }
                // this.dialogRef.close();
            }
        });
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup Link', name, 1);
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {ThemePalette} from '@angular/material/core';
import {UserService} from '../../../../../core/services/user.service';
import {Observable, Subject} from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TreuhandService} from '../../../../../core/services/integrations/treuhand.service';
import {
    TreuhandConfigModel,
} from '../../../../../core/models/integrations/treuhand.config.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TreuhandCopyModalComponent} from './treuhand-copy-modal/treuhand-copy-modal.component';


@Component({
    selector: 'app-treuhand',
    templateUrl: './treuhand.component.html',
    styleUrls: ['./treuhand.component.scss']
})
export class TreuhandComponent implements OnInit, OnDestroy {
    public primary: ThemePalette = 'primary';
    public idfs$: Observable<any[]>;
    public currentlyDisplayedIdf: string;
    public input = false;
    public mainIdf: string;
    public ngUnsubscribe$ = new Subject<any>();
    public showFlash = false;

    public treuhandForm: FormGroup;
    public submitted = false;
    public isAnyDocTypeEmailActive = false;
    public showErrorMsg = false;
    public showSuccessMsg = false;
    public fieldsetDisabled = false;


    public treuhandConfig$: Observable<TreuhandConfigModel>;
    public treuhandCofigLoading$: Observable<boolean>;
    public updateTreuhandConfigError$: Observable<any>;
    public updateTreuhandConfigLoading$: Observable<boolean>;
    public idfsWithConfig$: Observable<any>;
    public idfsWithConfigLoading$: Observable<boolean>;
    public enabledConfigIdfs = [];
    public technicalEmailAddress = 'dokumentversand@phoenixgroup.eu';

    public documentTypes = [
        {
            title: 'Elektronische PAYBACK-Sammelrechnung',
            enumDocument: 'E_COLLECTIVE_INVOICE_PAYBACK',
            enumSend: 'E_COLLECTIVE_INVOICE_PAYBACK-send',
            formControlName: 'paybackEmail',
            toggleFormControlName: 'activePayback',
        },
        {
            title: 'Elektronische Sammelrechnung',
            enumDocument: 'E_COLLECTIVE_INVOICE',
            enumSend: 'E_COLLECTIVE_INVOICE-send',
            formControlName: 'sammelrechnungEmail',
            toggleFormControlName: 'activeSammelrechnung',
        },
        {
            title: 'Elektronische Valuta',
            enumDocument: 'E_VALUTA',
            enumSend: 'E_VALUTA-send',
            formControlName: 'valutaEmail',
            toggleFormControlName: 'activeValuta',
        },
        {
            title: 'Elektronischer Kontoauszug',
            enumDocument: 'E_ACCOUNT_STATEMENT',
            enumSend: 'E_ACCOUNT_STATEMENT-send',
            formControlName: 'kontoauszugEmail',
            toggleFormControlName: 'activeKontoauszug',
        },
        {
            title: 'Elektronische Vorteilserläuterung / Kaufstruktur',
            enumDocument: 'E_ADVANTAGE_CALCULATION',
            enumSend: 'E_ADVANTAGE_CALCULATION-send',
            formControlName: 'kaufstrukturEmail',
            toggleFormControlName: 'activeKaufstruktur',
        },
    ];

    constructor(public userService: UserService,
                private clipboard: Clipboard,
                public treuhandService: TreuhandService,
                private dialog: MatDialog) {
        // this.idfs$ = this.userService.selectContextualizedIdfsObservable('orders');
        this.input = this.userService.isPhoenixUser() || this.userService.isInternalUser();

        this.userService.selectedIdf$.subscribe(idf => {
                if (idf) {
                    this.currentlyDisplayedIdf = idf.idf;
                    this.mainIdf = idf.idf;
                    this.treuhandService.loadTreuhandConfig(this.currentlyDisplayedIdf);
                    this.treuhandService.getTreuhandConfiguredIdfs(this.currentlyDisplayedIdf);
                }
            });


        this.treuhandConfig$ = this.treuhandService.getTreuhandConfigObservable();
        this.treuhandCofigLoading$ = this.treuhandService.getTreuhandConfigLoadingObservable();
        this.updateTreuhandConfigLoading$ = this.treuhandService.updateConfigurationLoadingObservable();
        this.updateTreuhandConfigError$ = this.treuhandService.updateConfigurationErrorObservable();
        this.idfsWithConfig$ = this.treuhandService.getTreuhandConfigIdfsObservable();
        this.idfsWithConfigLoading$ = this.treuhandService.getTreuhandConfigIdfsLoadingObservable();

        this.buildForm();
    }

    ngOnInit(): void {
        this.treuhandConfig$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response) => {
            if (response) {
                this.buildForm();
                this.isAnyDocTypeEmailActive = Object.values(response.send).some(val => val === true);
                if (Object.keys(response.documentMappings).length > 0) {
                    this.fieldsetDisabled = false;
                    for (const key in response.documentMappings) {
                        if (response.documentMappings.hasOwnProperty(key)) {
                            const doc = this.documentTypes.find(item => item.enumDocument === key);
                            this.treuhandForm.get(doc.formControlName).setValue(response.documentMappings[key]);
                        }
                    }
                    for (const key in response.send) {
                        if (response.send.hasOwnProperty(key)) {
                            const doc = this.documentTypes.find(item => item.enumSend === key);
                            this.treuhandForm.get(doc.toggleFormControlName).setValue(response.send[key]);
                            this.updateSlideToggleStatus(doc.formControlName, doc.toggleFormControlName);
                        }
                    }
                } else {
                    this.fieldsetDisabled = true;
                    this.buildForm();
                }
            }
        });
        this.idfsWithConfig$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response) => {
           if (response) {
               this.enabledConfigIdfs = response;
           }
        });
    }

    buildForm() {
        const formGroupFields = this.getFormControlsFields();
        this.treuhandForm = new FormGroup(formGroupFields);
    }

    getFormControlsFields() {
        const formGroupFields = {};
        this.documentTypes.forEach((doc) => {
            formGroupFields[doc.formControlName] = new FormControl('',
                [Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]);
            formGroupFields[doc.toggleFormControlName] = new FormControl({value: false, disabled: true});
        });
        return formGroupFields;
    }

    onChangeIdf(idf: string) {
        this.currentlyDisplayedIdf = idf;
        this.treuhandService.loadTreuhandConfig(idf);
        this.treuhandService.getTreuhandConfiguredIdfs(idf);
    }

    enableForm() {
        this.fieldsetDisabled = false;
    }

    showSelectIdfModal() {
        this.idfs$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((idfs) => {
            const idfList = idfs.filter((item) => this.enabledConfigIdfs.includes(item.idf) && item.idf !== this.currentlyDisplayedIdf);
            const addNewDialogConfig = new MatDialogConfig();
            addNewDialogConfig.autoFocus = false;
            addNewDialogConfig.data = {idfs: idfList, targetIdf: this.currentlyDisplayedIdf, perform: false};
            this.dialog.open(TreuhandCopyModalComponent, addNewDialogConfig);
        });
    }

    copyText() {
        this.showFlash = true;
        setTimeout(() => {
            this.showFlash = false;
        }, 1000);
        return this.clipboard.copy(this.technicalEmailAddress);
    }

    updateSlideToggleStatus(formFieldName: string, toggleFormControlName: string) {
        if (this.treuhandForm.get(`${formFieldName}`).valid && this.treuhandForm.get(`${formFieldName}`).value !== '') {
            this.treuhandForm.get(toggleFormControlName).enable();
        } else {
            this.treuhandForm.get(toggleFormControlName).setValue(false);
            this.treuhandForm.get(toggleFormControlName).disable();
        }
    }

    onDeactivate() {
        const data: TreuhandConfigModel = {
            idf: this.currentlyDisplayedIdf,
            documentMappings: {},
            send: {}
        };
        this.updateConfig(data);
    }

    onSubmit() {
        this.submitted = true;
        const data: TreuhandConfigModel = {
            idf: this.currentlyDisplayedIdf,
            documentMappings: {},
            send: {}
        };
        if (this.treuhandForm.valid) {
            // we still have to check each field entry individually
            // as we cannot set required validation for each of them
            // because we might not send all of them as payload.
            for (let i = 0; i < this.documentTypes.length; i++) {
                if (this.treuhandForm.get(this.documentTypes[i].formControlName).value === this.technicalEmailAddress) {
                    this.treuhandForm.get(this.documentTypes[i].formControlName).setErrors({ restrictedEmail : 'Verwenden Sie diese E-Mail-Adresse nur im treuhand-Portal.' });
                    return;
                }
                if (this.treuhandForm.get(this.documentTypes[i].formControlName).value !== '') {
                    data.documentMappings[this.documentTypes[i].enumDocument] = this.treuhandForm.get(this.documentTypes[i].formControlName).value;
                    data.send[this.documentTypes[i].enumSend] = this.treuhandForm.get(this.documentTypes[i].toggleFormControlName).value;
                }
            }
            if (Object.keys(data.documentMappings).length === 0) {
                // means we haven't made any changes
            } else {
                this.updateConfig(data);
            }
        }
    }

    updateConfig(data: TreuhandConfigModel) {
        this.treuhandService.updateConfiguration(data);
        this.updateTreuhandConfigLoading$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((loading) => {
            if (!loading) {
                this.updateTreuhandConfigError$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((error) => {
                    if (error) {
                        this.showErrorMsg = true;
                        setTimeout(() => {
                            this.showErrorMsg = false;
                        }, 2000);
                    } else {
                        this.treuhandService.loadTreuhandConfig(this.currentlyDisplayedIdf);
                        this.treuhandService.getTreuhandConfiguredIdfs(this.currentlyDisplayedIdf);
                        this.showSuccessMsg = true;
                        setTimeout(() => {
                            this.showSuccessMsg = false;
                        }, 2000);
                    }
                });
            }
        });
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

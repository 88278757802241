import {DialogActionTypes, DialogActions} from '../actions/dialog.actions';

export interface State {
    dialogs: any[];
    dialogLoading: boolean;
    dialogError: any;
}

export const initState: State = {
    dialogs: [],
    dialogLoading: false,
    dialogError: null
};

export function reducer(state = initState, action: DialogActions) {
    switch (action.type) {
        case DialogActionTypes.INIT_DIALOG:
            break;
        case DialogActionTypes.LOAD_DIALOG:
            return {
                ...state,
                dialogLoading: true
            };
        case DialogActionTypes.LOAD_DIALOG_COMPLETED:
            return {
                ...state,
                dialogs: action.payload,
                dialogLoading: false
            };
        case DialogActionTypes.LOAD_DIALOG_FAILED:
            return {
                ...state,
                dialogError: action.payload,
                dialogLoading: false
            };
        default:
            return state;
    }
}

export const getDialogLoading = (state: State) => state.dialogLoading;
export const getDialogs = (state: State) => state.dialogs;
export const getDialogError = (state: State) => state.dialogError;
<div _ngcontent-mkm-c238 class="payback-block text-center p-l mb-s row align-items-center no-gutters mt-l" *ngIf="type==='payback'" >
    <div class="col-3">
        <img src="https://cspappsacc.blob.core.windows.net/payback/Footer_element_store_image_left.png">
    </div>
    <div class="col-6">
        <p _ngcontent-mkm-c238 class="text-center promo-text">Weitere Infos zum PAYBACK Programm sowie Werbemittel, Grafiken und
            Gestaltungselemente finden Sie hier:</p>
        <ng-container *ngIf="tokens.length === 1">
            <a class="btn btn-primary btn-{{type}}"
               href="{{this.DA_STORE_URL}}?user={{this.userService.getUser().username}}&token={{tokens[0].value}}&idf={{tokens[0].key}}&referrer={{this.DA_STORE_REFERRER}}"
               target="_blank"
               (click)="trackClick('Phoenix Store')">
                PHOENIX STORE
            </a>
        </ng-container>
        <ng-container *ngIf="tokens.length > 1">
            <button class="btn btn-primary btn-{{type}}"
                    (click)="openIdfSelectionDialog(
                    NavType.DEINEAPOTHEKESTORE,
                     'assets/img/tiles/svg/phoenixstore-kachel.svg',
                     'Anmeldung im PHOENIX Store',
                     'Bitte wählen Sie die Apotheke aus, mit der Sie sich im PHOENIX Store anmelden wollen.',
                      tokens);
                      trackClick('Phoenix Store')">
                PHOENIX STORE
            </button>
        </ng-container>
    </div>
    <div class="col-3">
        <img src="https://cspappsacc.blob.core.windows.net/payback/Footer_element_image_right.png">
    </div>
</div>


<div *ngIf="type==='pkp'">
    <ng-container *ngIf="tokens.length === 1">
        <a class="btn btn-cms"
           href="{{this.DA_STORE_URL}}?user={{this.userService.getUser().username}}&token={{tokens[0].value}}&idf={{tokens[0].key}}&referrer={{this.DA_STORE_REFERRER}}"
           target="_blank"
           (click)="trackClick('Phoenix Store')">
            ZUM PHOENIX STORE
        </a>
    </ng-container>
    <ng-container *ngIf="tokens.length > 1">
        <button class="btn btn-cms"
                (click)="openIdfSelectionDialog(
                NavType.DEINEAPOTHEKESTORE,
                 'assets/img/tiles/svg/phoenixstore-kachel.svg',
                 'Anmeldung im PHOENIX Store',
                 'Bitte wählen Sie die Apotheke aus, mit der Sie sich im PHOENIX Store anmelden wollen.',
                  tokens);
                  trackClick('Phoenix Store')">
            ZUM PHOENIX STORE
        </button>
    </ng-container>
</div>

import {SearchProductRequest} from './search-product-request.model';

export class SearchAvailableProductRequest extends SearchProductRequest {

    availableOnly: boolean;

    constructor(obj: any = {}) {
        super(obj);
        this.availableOnly = obj && obj.availableOnly || false;
    }

}
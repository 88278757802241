import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {DialogLoadAction} from '../store/actions/dialog.actions';
import {map, takeUntil} from 'rxjs/operators';
import * as fromReducers from '../store/reducers';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    showPopup = true;
    data = [];
    dialogData$: Observable<any>;
    private ngUnsubscribe$ = new Subject<any>();

    constructor(public store: Store<fromReducers.State>) {

    }

    getActiveDialogs(page: any) {
        this.store.dispatch(new DialogLoadAction(page));
        this.dialogData$ = this.store.pipe(select(fromReducers.getDialogs));

        return this.dialogData$;
    }

    isDialogLoading(){
        return this.store.pipe(select(fromReducers.getDialogLoading));
    }

    setShowPopup(b: boolean) {
        this.showPopup = b;
    }

    setDialogData(data) {
        this.dialogData$ = data;
    }

    public isShowPopup() {
        return this.showPopup;
    }

    public getDialogData() {
        return this.dialogData$;
    }


    selectDialog(dialogs) {
        const highestPrio = dialogs[0].priority;
        const prioritized = dialogs.filter(d => d.priority === highestPrio);

        if (prioritized.length === 1) {
            return prioritized[0];
        }
        const selected = [];
        selected.push(prioritized[Math.floor(prioritized.length * Math.random())]);

        return selected[0];
    }

    getShowPopup(){
        return this.showPopup;
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessageComponent} from './message.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule, TranslateModule, RouterModule
    ],
    declarations: [MessageComponent],
    exports: [MessageComponent]
})
export class MessageModule {
}

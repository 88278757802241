import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductRequest} from '../../models/shop/product-request.model';

export enum ExclusiveOffersActionTypes {
    LOAD_EXCLUSIVE_OFFERS = '[EXCLUSIVE_OFFERS] Load Pin',
    LOAD_EXCLUSIVE_OFFERS_COMPLETE = '[EXCLUSIVE_OFFERS] Load Pin Complete',
    LOAD_EXCLUSIVE_OFFERS_FAIL = '[EXCLUSIVE_OFFERS] Load Pin FAIL',
    SEARCH_EXCLUSIVE_OFFERS = '[EXCLUSIVE_OFFERS] Search',
    UPDATE_SCROLLING_POSITION = '[EXCLUSIVE_OFFERS] Update Scrolling Position',
    GET_BATCH_FROM_CACHE = '[EXCLUSIVE_OFFERS] Push batch in visible list'
}

export class LoadExclusiveOffersAction implements Action {
    readonly type = ExclusiveOffersActionTypes.LOAD_EXCLUSIVE_OFFERS;

    constructor() { // == Query
    }
}

export class LoadExclusiveOffersCompleteAction implements Action {
    readonly type = ExclusiveOffersActionTypes.LOAD_EXCLUSIVE_OFFERS_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }
}

export class LoadExclusiveOffersFailAction implements Action {
    readonly type = ExclusiveOffersActionTypes.LOAD_EXCLUSIVE_OFFERS_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class SearchShopAction implements Action {
    readonly type = ExclusiveOffersActionTypes.SEARCH_EXCLUSIVE_OFFERS;

    constructor() {}
}

export class UpdateScrollingPositionAction implements Action {
    readonly type = ExclusiveOffersActionTypes.UPDATE_SCROLLING_POSITION;

    constructor(public payload: number) {};
}

export class GetBatchFromCacheAction implements Action {
    readonly type = ExclusiveOffersActionTypes.GET_BATCH_FROM_CACHE;

    constructor() {};
}


export type ExclusiveOffersActions =
    LoadExclusiveOffersAction |
    LoadExclusiveOffersCompleteAction |
    LoadExclusiveOffersFailAction |
    SearchShopAction |
    UpdateScrollingPositionAction |
    GetBatchFromCacheAction;
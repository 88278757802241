import {Component, Inject, OnInit} from '@angular/core';
import {VideoDialogComponent} from '../../../public/video-dialog/video-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-linda-wissen',
  templateUrl: './linda-wissen.component.html',
  styleUrls: ['./linda-wissen.component.scss']
})
export class LindaWissenComponent implements OnInit {

  constructor(
      public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openDialog(typ): void {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      data: {type: typ},
    });
  }
}

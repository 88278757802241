import {Component, Inject, OnInit} from '@angular/core';
import {BlobService} from '../../../core/services/blob.service';
import {MatomoService} from '../../../core/services/matomo.service';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
    public slides = [];

    constructor(private blobService: BlobService,
                private matomoService: MatomoService,
                @Inject('CDN') private CDN
    ) {
    }

    ngOnInit(): void {
        this.getActiveSlides();
    }

    async getActiveSlides() {
        const allSlides = JSON.parse(<string>await this.blobService.getData('slider.json', 'marketing-management'));
        const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'de_DE');
        for (const slide of allSlides) {
            const startDate = formatDate(slide.start, 'yyyy-MM-dd', 'de_DE');
            const endDate = formatDate(slide.end, 'yyyy-MM-dd', 'de_DE');
            if (currentDate >= startDate && currentDate <= endDate) {
                this.slides.push({name: slide.title, link: slide.link, image: slide.image, order: slide.order});
            }
        }


        this.shuffleSlides(this.slides);
    }

    mtmEvent(action, value) {
        this.matomoService.trackEvent('Klick auf Startseite Slider', action, value);
    }

    showIndicators(slides) {
        if (slides.length > 1) {
            return true;
        }
        return false;
    }

    shuffleSlides(slides) {
        slides.sort(() => Math.random() - 0.5)
    }

    sortSlides(slides) {
        slides.sort(function (a, b) {
            return a.order - b.order;
        });
    }
}

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {DialogData} from "../../core/interfaces/dialog-data.interface";
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import {ErrorDialogComponent} from '../error/error.component';
import {NavService} from '../../core/services/nav.service';

@Component({
  selector: 'app-idf-select',
  templateUrl: './idf-select.component.html',
  styleUrls: ['./idf-select.component.scss']
})
export class IdfSelectComponent implements OnInit {
  public currentlyDisplayedIdf: any = null;
  public ngUnsubscribe$ = new Subject<any>();
  idfsDetails: any;
  public isAdmin = false;
  public isSelect = false;
  private navigation;
  public canUse = false;

  constructor(
      @Inject(UserService) public userService: UserService,
      private activated: ActivatedRoute,
      public dialog: MatDialog,
      private router: Router,
      private navService: NavService
  ) {
    this.navigation = this.userService.getNav();
    userService.loadFullIdfs().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((idfs) => {
      if (idfs.returnObject) {
        this.idfsDetails = idfs.returnObject;
        this.isSelect = this.idfsDetails.length > 1;
        this.currentlyDisplayedIdf = this.idfsDetails[0];
        this.userService.selectIdf(this.idfsDetails[0]);
      } else {
        this.setIdf(9999910);
      }
    });

    this.isAdmin = this.userService.isPhoenixUser() || this.userService.isInternalUser();
    this.canUse = this.userService.isInternalUser() && this.userService.canSelectIdf() || this.userService.isPhoenixUser();
  }

  ngOnInit(): void {
  }

  canSwitch(url,idf) {
    let has = false;
    const requiredAuthorities = this.navigation.getAuthoritiesForRoute(url);


    if (requiredAuthorities && requiredAuthorities.length > 0) {
      has = this.navService.isVisibleForUser(url);
    }

    const feature = this.navigation.getFeatureForRoute(url);
    if (feature !== '') {
      has = this.userService.canUseFeature(feature);
    }

    const details = this.navigation.getDetailsForRoute(url);
    const excludedDetails = this.navigation.getExcludedDetailsForRoute(url);
    has = this.userService.hasDetails(idf, details, excludedDetails);

    return has;
  }

  openIdfSelect() {
    const dialogRef = this.dialog.open(IdfSelectDialog, {
      data: {
        idfs: this.idfsDetails,
        currentIdf: this.currentlyDisplayedIdf,
        isAdmin: this.isAdmin
      },
      disableClose: true,
      panelClass: 'idf-select-dialog',
      closeOnNavigation: true
    });

    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if(result) {
        this.currentlyDisplayedIdf = result;
        this.userService.selectIdf(result);

        const url = this.router.routerState.snapshot.url;

        if(!this.isAdmin) {
            if(!this.userService.isOwner() && !this.canSwitch(url, result) && url.indexOf('/dashboard') === -1) {
              this.dialog.open(ErrorDialogComponent,{
                width: '600px',
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true,
                backdropClass: 'opaque'});
            }
        }
      }
    })
  }



  setIdf(idf: any) {
    this.userService.loadIdfDetails(idf)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(details=>{
      this.currentlyDisplayedIdf = details.returnObject;
      this.userService.selectIdf(this.currentlyDisplayedIdf)
    })
  }


  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy() {
    this.ngUnsubscribe$.next('');
    this.ngUnsubscribe$.complete();
  }
}



@Component({
  selector: 'idf-select-dialog',
  templateUrl: 'idf-select-dialog.html',
  styleUrls: ['./idf-select-dialog.scss']
})
export class IdfSelectDialog {

  selectedIdf: any;
  constructor(public dialogRef: MatDialogRef<IdfSelectDialog>,
              @Inject(UserService) public userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  closeDialog() {
    this.dialogRef.close(this.selectedIdf);
  }
}
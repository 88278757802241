import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RangeToggleComponent} from './range-toggle.component';
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatRippleModule } from "@angular/material/core";

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatRippleModule,
        MatButtonToggleModule
    ],
    declarations: [RangeToggleComponent],
    exports: [RangeToggleComponent]
})
export class RangeToggleModule {
}

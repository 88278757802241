import {DocType} from './doc-type.model';

export class DocTypes {
    idf: number;
    dc: number[];
    docTypes: DocType[];

    constructor(obj: any) {
        this.idf = obj && obj.idf || null;
        this.dc = obj && obj.dc || null;
        this.docTypes = obj && obj.docTypes || null;
        if (obj && obj.docTypes) {
            this.docTypes = obj.docTypes.map((dt) => new DocType(dt));
        }

    }
}

<h2 mat-dialog-title>Produkte hinzufügen</h2>
<div mat-dialog-content>
    Möchten Sie wirklich
    <div *ngFor="let item of data.items">
        <b>{{ item.quantity }}</b> x <b [innerHTML]="item.product.name | safeHtml"></b>
        ({{item.price | currency:'EUR':'symbol':'1.2-2'}} Stückpreis) <br/>zu einer Summe von <b>{{ item.price * item.quantity
        | currency:'EUR':'symbol':'1.2-2'}}</b>
    </div>
     in den Warenkorb legen?
</div>
<div mat-dialog-actions>
    <button class="btn-secondary" mat-button [mat-dialog-close]="false" data-cy="add-to-cart-no">
        <span>Nein</span>
        <i class="material-icons">
            close
        </i>
    </button>
<!--    <button class="btn-primary btn-round" mat-button matTooltip="Zur Kasse" (click)="cash()">
        <i class="material-icons">
            credit_card
        </i>
    </button>
    <button class="btn-primary btn-round" mat-button matTooltip="Zum Warenkorb" (click)="cart()">
        <i class="material-icons">
            shopping_cart
        </i>
    </button>-->
    <button class="btn-primary float-right" mat-button (click)="add()" cdkFocusInitial data-cy="add-to-cart-yes">
        <span>Ja</span>
        <i class="material-icons">
            check
        </i>
    </button>
</div>

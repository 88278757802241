import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LogService} from "../../../../../../core/services/utility/log.service";
import {ManageAccountDataRestService} from '../../../../../../core/services/api/manage-account-data.rest-service';
import {OwnerData} from '../../../../../../core/models/account/owner-data.model';

@Component({
    selector: 'edit-owner',
    templateUrl: './edit-owner.component.html',
    styleUrls: ['./edit-owner.component.scss']
})
export class EditOwnerComponent implements OnInit {
    @Input() data: any;

    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() success: EventEmitter<any> = new EventEmitter<any>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() fail: EventEmitter<string> = new EventEmitter<string>();

    public userForm: FormGroup;
    public salutation: FormControl = new FormControl('', Validators.required);
    public title: FormControl = new FormControl('');
    public firstName: FormControl = new FormControl('', Validators.required);
    public lastName: FormControl = new FormControl('', Validators.required);
    public submitted = false;
    constructor(@Inject(LogService) public log: LogService,
                @Inject(ManageAccountDataRestService) public manageAccountDataRestService: ManageAccountDataRestService) {
        this.userForm = new FormGroup({
            salutation: this.salutation,
            title: this.title,
            firstName: this.firstName,
            lastName: this.lastName
        });
    }

    ngOnInit() {
        if (!this.data) {
            this.data = new OwnerData();
        }
        this.userForm.setValue(this.data);
    }

    onCancel() {
        this.submitted = false;
        this.cancel.next();
    }

    onSubmit() {
        this.submitted = true;
        if (!this.userForm.valid) {
            return;
        }
        this.loading.next(true);
        const data = {...this.userForm.value};
        this.manageAccountDataRestService.changeOwner(data).subscribe(
            result => {
                this.loading.next(false);
                this.log.info(result);
            },
            err => {
                this.loading.next(false);
                this.log.error(err);
                if (err.error && err.error.errorCode) {
                    this.fail.next(err.error.errorCode);
                } else {
                    this.fail.next(null);
                }
            });
        this.success.next();
    }
}

import {HttpErrorResponse} from '@angular/common/http';
import {TrackAndTraceActions, TrackActionsTypes} from '../actions/track.actions';

export interface State {
    trackErrorResponse: HttpErrorResponse;
    trackLoading: boolean;
    trackResponse: any;
    delayLoading: boolean;
    delay: any;
    delayError: HttpErrorResponse;
}

export const initialState: State = {
    trackErrorResponse: null,
    trackLoading: false,
    trackResponse: null,
    delayLoading: false,
    delay: null,
    delayError: null
};

export function reducer(state = initialState, action: TrackAndTraceActions) {
    switch (action.type) {
        case TrackActionsTypes.TRACK_START:
            return {
                ...state,
                trackLoading: true,
                trackErrorResponse: null
            };
        case TrackActionsTypes.TRACK_COMPLETE:
            return {
                ...state,
                trackResponse: action.payload,
                trackLoading: false
            };
        case TrackActionsTypes.TRACK_FAILED:
            return {
                ...state,
                trackErrorResponse: action.payload,
                trackLoading: false
            };
            case TrackActionsTypes.TRACK_IDF_START:
            return {
                ...state,
                trackLoading: true,
                trackErrorResponse: null
            };
        case TrackActionsTypes.TRACK_IDF_COMPLETE:
            return {
                ...state,
                trackResponse: action.payload,
                trackLoading: false
            };
        case TrackActionsTypes.TRACK_IDF_FAILED:
            return {
                ...state,
                trackErrorResponse: action.payload,
                trackLoading: false
            };
        case TrackActionsTypes.DELAYS_LOAD:
            return {
                ...state,
                delayLoading: true,
                delayError: null
            };
        case TrackActionsTypes.DELAYS_COMPLETED:
            return {
                ...state,
                delay: action.payload,
                delayLoading: false
            };
        case TrackActionsTypes.DELAYS_FAILED:
            return {
                ...state,
                delayError: action.payload,
                delayLoading: false
            };
        default:
            return state;
    }
}

export const getTrackLoading = (state: State) => state.trackLoading;
export const getTrackErrorResponse = (state: State) => state.trackErrorResponse;
export const getTrackResponse = (state: State) => state.trackResponse;
export const getDelaysLoading = (state: State) => state.delayLoading;
export const getDelayError = (state: State) => state.delayError;
export const getDelay = (state: State) => state.delay;

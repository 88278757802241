import {HttpErrorResponse} from '@angular/common/http';
import {ReklamationActions, ReklamationActionTypes} from '../actions/reklamation.actions';

export interface State {
    reklamationListLoading: boolean;
    reklamationListErrorResponse: HttpErrorResponse;
    reklamationList: any;
}

export const initialState: State = {
    reklamationListLoading: false,
    reklamationListErrorResponse: null,
    reklamationList: null
};

export function reducer(state = initialState, action: ReklamationActions): State {
    switch (action.type) {
        case ReklamationActionTypes.GET_REKLAMATION_LIST:
            return {
                ...state,
                reklamationListLoading: true,
                reklamationListErrorResponse: null,
                reklamationList: null
            };
        case ReklamationActionTypes.GET_REKLAMATION_LIST_COMPLETE:
            return {
                ...state,
                reklamationListLoading: false,
                reklamationList: action.payload
            };
        case ReklamationActionTypes.GET_REKLAMATION_LIST_FAIL:
            return {
                ...state,
                reklamationListLoading: false,
                reklamationListErrorResponse: action.payload
            };
        default:
            return state;
    }
}

export const getReklamationLoading = (state: State) => state.reklamationListLoading;
export const getReklamationError = (state: State) => state.reklamationListErrorResponse;
export const getReklamationList = (state: State) => state.reklamationList;

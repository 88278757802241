import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationHeadingComponent } from './registration-heading.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [RegistrationHeadingComponent],
    exports: [RegistrationHeadingComponent]
})
export class RegistrationHeadingModule { }

import { Component,Inject, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromReducers from '../../../core/store/reducers';
import {UserLogoutAction} from '../../../core/store/actions/user.actions';
import {Router} from '@angular/router';
import {MatomoService} from '../../../core/services/matomo.service';
import {UrlService} from '../../../core/services/url.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
      private router: Router,
      public store: Store<fromReducers.State>,
      public urlService: UrlService,
      // @Inject() LOGIN,
      private matomoService: MatomoService
  ) {
    store.dispatch(new UserLogoutAction());
  }

  ngOnInit() {
    this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Logout erfolgreich');
    this.urlService.lastUrl = 'logout';
    sessionStorage.clear();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 5200);
  }

}

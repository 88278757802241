import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../../../../core/validators/must-match.validator';
import {PasswordValidator} from '../../../../../core/validators/password.validator';
import {NewPasswordRestService} from '../../../../../core/services/api/new-password.rest-service';
import {ManageAccountDataRestService} from '../../../../../core/services/api/manage-account-data.rest-service';
import {DialogData} from '../../../../../core/interfaces/dialog-data.interface';
import {AccountInfo} from '../../../../../core/models/registration/registration-confirm-response.model';

@Component({
    selector: "manage-account-dialog",
    templateUrl: "./manage-account-dialog.component.html",
    styleUrls: ["./manage-account-dialog.component.scss"]
})
export class ManageAccountDialogComponent implements OnInit {

    public loading: boolean = false;

    public account: AccountInfo;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ManageAccountDialogComponent>,
                @Inject(ManageAccountDataRestService) public manageAccountDataRestService: ManageAccountDataRestService) {
        this.account = new AccountInfo(data.account);
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close({data: 'cancel'});
    }

    onSuccess() {
        this.dialogRef.close({data: 'success'});
    }

    onLoad($event) {
        this.loading = $event;
    }

    onFail($event) {
        this.dialogRef.close({data: $event});
    }

}

import {Inject, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {ProductServiceAbstract} from "./product-service.abstract";
import {WebshopRestService} from "../api/webshop-rest.service";
import * as fromReducers from "../../store/reducers";
import {Product} from "../../models/shop/product.model";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {ProductRequest} from '../../models/shop/product-request.model';
import {SearchProductRequest} from '../../models/shop/search-product-request.model';
import {
    GetBatchFromCacheAction,
    LoadParagraph73Action,
    SearchShopAction,
    UpdateScrollingPositionAction
} from '../../store/actions/paragraph73.actions';

@Injectable()
export class Paragraph73Service extends ProductServiceAbstract {
    constructor(public store: Store<fromReducers.State>, @Inject(WebshopRestService) public webshopRestService: WebshopRestService) {
        super(store, webshopRestService);
    }

    updateScrollingPosition(pos: number): void {
        this.store.dispatch(new UpdateScrollingPositionAction(pos));
    }

    searchProducts(request: SearchProductRequest = new SearchProductRequest({})): void {
        this.store.dispatch(new SearchShopAction(request));
    }

    displayNewBatch(request: SearchProductRequest = new SearchProductRequest({})): void {
        let end: boolean = false;
        this.store.pipe(select(fromReducers.getParagraph73Response))
            .subscribe(response => end = response.end).unsubscribe();

        // determine how much cache is left
        let cacheSize: number = 0;
        this.store.pipe(select(fromReducers.getParagraph73ProductCache))
            .subscribe(cache => cacheSize = cache.length).unsubscribe();

        if (!end && cacheSize < 2 * 20) {
            this.store.dispatch(new LoadParagraph73Action(request));
        }

        // get batch from cache
        this.store.dispatch(new GetBatchFromCacheAction());
    }


    /**
     * GETTERS
     */
    getProductsLoadingObservable(): Observable<boolean> {
        this.productsLoading = this.store.pipe(select(fromReducers.getParagraph73Loading));
        return this.productsLoading;
    }

    getProductsResponseObservable(): Observable<ProductsResponse> {
        this.productsResponse$ = this.store.pipe(select(fromReducers.getParagraph73Response));
        //this.checkAvailability();
        return this.productsResponse$;
    }

    getProductsRequestObservable(): Observable<ProductRequest> {
        this.productsRequest$ = this.store.pipe(select(fromReducers.getParagraph73Request));
        //this.checkAvailability();
        return this.productsRequest$;
    }

    getProductsObservable(): Observable<Product[]> {
        this.products$ = this.store.pipe(select(fromReducers.getParagraph73Products));
        return this.products$;
    }

    getScrollPositionObservable(): Observable<number> {
        this.scrollPosition$ = this.store.pipe(select(fromReducers.getParagraph73ScrollPosition));
        return this.scrollPosition$;
    }

    getProductCacheObservable(): Observable<Product[]> {
        this.productCache$ = this.store.pipe(select(fromReducers.getParagraph73ProductCache));
        return this.productCache$;
    }

}
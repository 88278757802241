import {Action} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {ProductRequest} from "../../models/shop/product-request.model";

export enum SpecialOffersActionTypes {
    LOAD_PIN = '[PIN] Load Pin',
    LOAD_PIN_COMPLETE = '[PIN] Load Pin Complete',
    LOAD_PIN_FAIL = '[PIN] Load Pin FAIL',
    SEARCH_PIN = '[PIN] Search',
    UPDATE_SCROLLING_POSITION = '[PIN] Update Scrolling Position',
    GET_BATCH_FROM_CACHE = '[PIN] Push batch in visible list'
}

export class LoadSpecialOffersAction implements Action {
    readonly type = SpecialOffersActionTypes.LOAD_PIN;

    constructor(public payload: ProductRequest) { // == Query
    }
}

export class LoadSpecialOffersCompleteAction implements Action {
    readonly type = SpecialOffersActionTypes.LOAD_PIN_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }
}

export class LoadSpecialOffersFailAction implements Action {
    readonly type = SpecialOffersActionTypes.LOAD_PIN_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class SearchShopAction implements Action {
    readonly type = SpecialOffersActionTypes.SEARCH_PIN;

    constructor(public payload: ProductRequest) {};
}

export class UpdateScrollingPositionAction implements Action {
    readonly type = SpecialOffersActionTypes.UPDATE_SCROLLING_POSITION;

    constructor(public payload: number) {};
}

export class GetBatchFromCacheAction implements Action {
    readonly type = SpecialOffersActionTypes.GET_BATCH_FROM_CACHE;

    constructor() {};
}


export type SpecialOffersActions =
    LoadSpecialOffersAction |
    LoadSpecialOffersCompleteAction |
    LoadSpecialOffersFailAction |
    SearchShopAction |
    UpdateScrollingPositionAction |
    GetBatchFromCacheAction;
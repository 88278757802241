<div id="footer">
    <div class="meta-footer d-md-flex meta-footer d-md-flex align-items-center flex-wrap">
        <img id="logo" class="mr-4" src="assets/img/phoenix-logo_corp.svg" alt="PHOENIX Pharmahandel GmbH & Co KG"/>
        <div class="flex-grow-1 text-left">
            <a class="mr-2 pb-1" [routerLink]="open ? '/open/page/impressum' : '/page/impressum'"
               [queryParams]="{heading: 'Impressum'}">
                Impressum
            </a>
            <a class="mr-2 pb-1"
               [routerLink]="open ? '/open/page/nutzungsbedingungen-portal' : '/page/nutzungsbedingungen-portal'"
               [queryParams]="{heading: 'Nutzungsbedingungen PHOENIX Portal'}">
                Nutzungsbedingungen
            </a>
            <a class="mr-2 pb-1" href="https://cspappsacc.blob.core.windows.net/cms/files/agb.pdf"
               download="phx-verkaufs-und-lieferbedingungen" target="_blank">
                Verkaufs- und Lieferbedingungen
            </a>
            <a class="mr-2 pb-1"
               href="https://cspappsacc.blob.core.windows.net/cms/files/2023-09-01_ARB_Allgemeine Retourenbedingungen_final.pdf"
               download="phx-retourenbedingungen" target="_blank">
                Retourenbedingungen
            </a>
            <a class="mr-2 pb-1" [routerLink]="open ? '/open/page/datenschutz' : '/page/datenschutz'"
               [queryParams]="{ heading: 'Datenschutzerklärung'}">
                Datenschutz
            </a>
        </div>

        <p class="copyright">© {{ currentYear }} PHOENIX Pharmahandel GmbH & Co KG</p>
    </div>
</div>

import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LogService} from "../../core/services/utility/log.service";
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

    router: Router;
    error: any;
    errorJson: string;
    errorJsonForMailBody: string;
    status: string;

    constructor(router: Router,
                private route: ActivatedRoute,
                @Inject('ADMIN_MAIL') private ADMIN_MAIL: string,
                @Inject(LogService) private log: LogService) {
        this.router = router;
        /**
         * Passed in by {@link error.interceptor.ts}
         * @type {string | null}
         */
        this.errorJson = this.route.snapshot.queryParamMap.get('error');
        this.error = JSON.parse(this.errorJson);
        this.errorJsonForMailBody = encodeURIComponent(this.errorJson);
        this.log.info('Error: ', this.error);
    }
}

@Component({
    selector: 'error-dialog',
    templateUrl: '../error/error-dialog.html',
    styleUrls: ['../error/error-dialog.scss']
})
export class ErrorDialogComponent implements OnDestroy {
    seconds = 5;
    interval;
    dialog;

    constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
                private router: Router) {
        this.dialog = dialogRef;
        setTimeout(() => {
            dialogRef.close();
            this.seconds = 5;
            this.router.navigate(['dashboard']);
        }, 5000);

        this.interval = setInterval(() => {
            this.seconds = this.seconds - 1;
        }, 1000);
    }

    ngOnDestroy() {
        this.dialog.close();
        clearInterval(this.interval);
    }
}
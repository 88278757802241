import {ProFaxActions, ProFaxActionTypes} from '../actions/pro-fax.actions';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';
import {CartActions, CartActionTypes} from '../actions/cart.actions';


export interface State {
    proFaxRequest: ProductRequest;
    proFaxResponse: ProductsResponse;
    proFaxErrorResponse: HttpErrorResponse;
    proFaxLoading: boolean,
    proFaxProducts: Product[];
    proFaxProductCache: Product[];
    proFaxScrollPosition: number;
    proFaxCart: any;
}

export const initialState: State = {
    proFaxRequest: null,
    proFaxResponse: null,
    proFaxErrorResponse: null,
    proFaxLoading: false,
    proFaxProducts: [],
    proFaxProductCache: [],
    proFaxScrollPosition: 0,
    proFaxCart: {}
};

export function reducerForCartActions(state = initialState, action: CartActions): State {
    switch (action.type) {
        case CartActionTypes.ADD_CART_ITEM_ACTION:
            if (action.payload.length === 0) {
                return state;
            } else {
                return {
                    ...state,
                    proFaxCart: Object.keys(state.proFaxCart)
                        .filter(id => id !== action.payload[0].product.id + action.payload[0].product.campaignId)
                        .reduce((obj, id) => {
                            obj[id] = state.proFaxCart[id];
                            return obj;
                        }, {})
                }
            }
        default:
            return state;
    }
}

export function reducer(state = initialState, action: ProFaxActions): State {
    switch (action.type) {
        case ProFaxActionTypes.LOAD_PROFAX:
            return {
                ...state,
                proFaxRequest: action.payload,
                proFaxLoading: true
            };
        case ProFaxActionTypes.LOAD_PROFAX_COMPLETE:
            return {
                ...state,
                proFaxProductCache: [...state.proFaxProductCache, ...action.payload.products],
                proFaxResponse: action.payload,
                proFaxLoading: false
            };
        case ProFaxActionTypes.LOAD_PROFAX_FAIL:
            return {
                ...state,
                proFaxErrorResponse: action.payload,
                proFaxLoading: false
            };
        case ProFaxActionTypes.SEARCH_PROFAX:
            return {
                ...state,
                proFaxProducts: [],
                proFaxProductCache: [],
                proFaxLoading: true
            };
        case ProFaxActionTypes.UPDATE_SCROLLING_POSITION:
            return {
                ...state,
                proFaxScrollPosition: action.payload
            };
        case ProFaxActionTypes.GET_BATCH_FROM_CACHE:
            return {
                ...state,
                proFaxProducts: [...state.proFaxProducts, ...state.proFaxProductCache.slice(0, 800)],
                proFaxProductCache: state.proFaxProductCache.slice(800)
            };
        case ProFaxActionTypes.UPDATE_PROFAX_CART:
            return {
                ...state,
                proFaxCart: {...state.proFaxCart, ...{[action.payload.id]: action.payload.cartItem}}
            };
        case ProFaxActionTypes.EMPTY_PROFAX_CART:
            const newProfaxCart = Object.keys(state.proFaxCart)
                .filter(pfid => pfid.indexOf(action.payload) < 0)
                .reduce((newCart, pfid) => {
                    newCart[pfid] = state.proFaxCart[pfid];
                    return newCart;
                }, {});
            return {
                ...state,
                proFaxCart: newProfaxCart
            };
        default:
            return state;
    }
}

export const getProFaxLoading = (state: State) => state.proFaxLoading;
export const getProFaxRequest = (state: State) => state.proFaxRequest;
export const getProFaxResponse = (state: State) => state.proFaxResponse;
export const getProFaxErrorResponse = (state: State) => state.proFaxErrorResponse;
export const getProFaxProductCache = (state: State) => state.proFaxProductCache;
export const getProFaxProducts = (state: State) => state.proFaxProducts;
export const getProFaxScrollPosition = (state: State) => state.proFaxScrollPosition;
export const getProFaxCart = (state: State) => state.proFaxCart;
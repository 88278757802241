import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductRequest} from '../../models/shop/product-request.model';

export enum PromoActionTypes{
    PROMO_LOAD = '[PROMO] Loading',
    PROMO_LOAD_COMPLETE = '[PROMO] Loading Complete',
    PROMO_LOAD_FAIL = '[PROMO] Loading Failed',
    PROMO_SEARCH = '[PROMO] Search',
    PROMO_SCROLL_POSITION = '[PROMO] Update Scroll Position',
    PROMO_BATCH_FROM_CACHE = '[PROMO] Push batch in visible list from cache',
    PROMO_UPDATE_CART = '[PROMO] Update cart',
    PROMO_EMPTY_CART = '[PROMO] Empty cart'
}

export class PromoLoadAction implements Action {
    readonly type = PromoActionTypes.PROMO_LOAD;

    constructor(public payload: ProductRequest) {}
}

export class PromoLoadCompleteAction implements Action {
    readonly type = PromoActionTypes.PROMO_LOAD_COMPLETE;

    constructor(public payload: ProductsResponse) {}

}

export class PromoLoadFailAction implements Action {
    readonly type = PromoActionTypes.PROMO_LOAD_FAIL;

    constructor(public payload: HttpErrorResponse) {}
}

export class PromoSearchAction implements Action {
    readonly type = PromoActionTypes.PROMO_SEARCH;

    constructor(public payload: ProductRequest) {}
}

export class PromoUpdateScrollPositionAction implements Action {
    readonly type = PromoActionTypes.PROMO_SCROLL_POSITION;

    constructor(public payload: number) {}
}

export class PromoBatchFromCache implements Action {
    readonly type = PromoActionTypes.PROMO_BATCH_FROM_CACHE;

    constructor() {}
}

export class PromoUpdateCart implements Action {
    readonly type = PromoActionTypes.PROMO_UPDATE_CART;
    constructor(public payload: any) {}
}

export class PromoEmptyCart implements Action {
    readonly type = PromoActionTypes.PROMO_EMPTY_CART;
    constructor(public payload: string) {}
}

export type PromoActions = PromoLoadAction |
    PromoLoadCompleteAction |
    PromoLoadFailAction |
    PromoSearchAction |
    PromoUpdateScrollPositionAction |
    PromoBatchFromCache |
    PromoUpdateCart |
    PromoEmptyCart;

export class SubsequentDeliveriesRequest{
    private _idf: string;

    constructor(obj: any = {}) {
        this._idf = obj.idf
    }

    getIdf(): string {
        return this._idf;
    }

    setIdf(value: string) {
        this._idf = value;
    }
}
import {ApplicationRef, Component,destroyPlatform, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute,NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../../core/services/api/auth.service';
import {Response} from '../../../core/models/response.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ChangePasswordDialogComponent} from '../../account/components/account-data/change-password/change-password-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {AlertDialogComponent} from '../../shared/alert-dialog/alert-dialog.component';
import {VideoDialogComponent} from '../../public/video-dialog/video-dialog.component';
import {noEmailValidator} from '../../shared/validator/noEmail.validator';
import {noIdfValidator} from '../../shared/validator/noIdf.validator';
import {environment} from '../../../../environments/environment';
import {MatomoService} from '../../../core/services/matomo.service';
import {UrlService} from '../../../core/services/url.service';
import {LindaRestService} from "../../../core/services/api/linda.rest-service";

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    public loginForm: FormGroup;
    public username: FormControl = new FormControl('', [
        Validators.required,
        Validators.pattern('^([a-z0-9-]){1,}[.]([a-z0-9-.]){1,}$'),
        noEmailValidator(),
        noIdfValidator()
    ]);
    public password: FormControl = new FormControl('', [
        Validators.required
    ]);
    public submitted = false;
    public hide = true;
    public loading = true;
    public error: any;
    public action: string = null;
    public i = 1;
    public ngUnsubscribe$ = new Subject<any>(); // emit unsubscription
    public showWizard = false;
    isIe = false;


    constructor(@Inject(AuthService) public authService: AuthService,
                public formBuilder: FormBuilder,
                public router: Router,
                public route: ActivatedRoute,
                public dialog: MatDialog,
                private urlService: UrlService,
                public matomoService: MatomoService,
                private appRef: ApplicationRef,
                private lindaService: LindaRestService
    ) {
        this.loading = true;
        if(this.urlService.lastUrl==='logout'){
            this.urlService.lastUrl='login';
            window.location.reload();
        }

        this.loginForm = this.formBuilder.group({
            username: this.username,
            password: this.password,
        });
        this.checkBrowser();

    }

    ngOnInit() {
        this.loading=false;
        this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Login Visit TEST')
        for (let i = 0; i < 10000; i++) {
            clearInterval(i);
        }
        this.route.params.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(params => {
                this.action = params['action'];
            });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(VideoDialogComponent, {
            panelClass: 'dialog',
            data: {type: 'hgf'}
        });
    }

    openCovidDialog(): void {
        const dialogRef = this.dialog.open(CovidDialog, {});
    }

    submit() {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }
        // stop here if form is invalid
        let url = 'dashboard';
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        if (returnUrl) {
            url = returnUrl;
        }
        this.loading = true;
        this.authService.login(this.loginForm.value).subscribe(
            data => {
                this.authService.getUserResponseFromGateway().subscribe(userData => {
                    if (new Response(userData).returnObject.passwordIdle === true) {
                        const changePasswordConfig = new MatDialogConfig();
                        changePasswordConfig.autoFocus = true;
                        changePasswordConfig.width = '50vw';
                        changePasswordConfig.disableClose = true;
                        changePasswordConfig.data = {
                            oldPassword: this.password.value,
                            showBackButton: false
                        };
                        this.dialog.open(ChangePasswordDialogComponent, changePasswordConfig).afterClosed()
                            .pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
                            if (result.data === 'success') {
                                const alertDialogConfig = new MatDialogConfig();
                                alertDialogConfig.width = '50vw';
                                alertDialogConfig.data = {
                                    message: 'Ihr Passwort konnte erfolgreich geändert werden.',
                                    ok: 'weiter zum Apothekenportal',
                                    heading: 'Passwortänderung'
                                };
                                this.dialog.open(AlertDialogComponent, alertDialogConfig).afterClosed()
                                    .pipe(takeUntil(this.ngUnsubscribe$))
                                    .subscribe(res => {

                                        if(userData.returnObject.authoritiesEnum.indexOf('LINDA_RSM') !== -1){
                                            this.lindaService.getGVLSignin().subscribe((signIn)=>{
                                                const lindaData = signIn.returnObject;
                                                if(lindaData) {
                                                    sessionStorage.setItem('gvl', lindaData.url + lindaData.token);
                                                }
                                                this.router.navigateByUrl(url);
                                                this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Login erfolgreich');
                                                this.loading = true;
                                            })
                                        }else{
                                            this.router.navigateByUrl(url);
                                            this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Login erfolgreich');
                                            this.loading = true;
                                        }
                                        this.router.navigateByUrl(url);
                                        this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Login erfolgreich');
                                        this.loading = true;
                                    });
                            } else if (result.data === 'cancel') {
                                // pass
                            } else if (result.data) {
                                this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Login fehlgeschlagen');
                                this.router.navigate(['fehler'], {queryParams: {error: JSON.stringify(result.data)}});
                            }
                        });

                    } else {
                        if (window['_paq'] !== undefined) {
                            window['_paq'].push(['FormAnalytics::trackFormConversion', 'Login', 'mtm-logformconv']);
                        }
                        this.matomoService.setFormConversion('mtm-logformconv');
                        this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Login erfolgreich');
                        if(userData.returnObject.authoritiesEnum.indexOf('LINDA_RSM') !== -1){
                                this.lindaService.getGVLSignin().subscribe((signIn)=>{
                                    const lindaData = signIn.returnObject;
                                    if(lindaData) {
                                        sessionStorage.setItem('gvl', lindaData.url + lindaData.token);
                                    }
                                    this.router.navigateByUrl(url);
                                    this.loading = true;
                                })
                        }else{
                            this.router.navigateByUrl(url);
                            this.loading = true;
                        }
                    }
                });
            },

            error => {
                this.error = error;
                if (this.i > 1) {
                    this.showWizard = true;
                }
                this.loading = false;
                this.matomoService.trackEvent('ALLGEMEINE KPI‘s', 'Login fehlgeschlagen');
                this.i++;
            });
    }

    resetWizard() {
        this.showWizard = false;
    }

    /**
     * unsubcribe all subscriptions on destroy
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    isQaVisible(): boolean {
        return environment.qa;
    }

    private redirectToDashboard() {
        const isAuthenticated = this.authService.isAuthenticated();

        if (isAuthenticated) {
            this.router.navigateByUrl('dashboard');
        }
    }

    // Checking is user browser IE
    // Can be removed after upgrade to Angular 13
    checkBrowser() {
        const agent = window.navigator.userAgent.toLowerCase();
        if (agent.indexOf('trident') > -1) {
            this.isIe =  true;
        }
    }
}

@Component({
    selector: 'covid-dialog',
    templateUrl: 'covid-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class CovidDialog {

    constructor(
        public dialogRef: MatDialogRef<CovidDialog>) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {TreuhandConfigModel} from '../../../models/integrations/treuhand.config.model';

export enum TreuhandActionTypes {
    GET_TREUHAND_CONFIG = '[TREUHAND] Get Treuhand Config',
    GET_TREUHAND_CONFIG_COMPLETE = '[TREUHAND] Get Treuhand Config complete',
    GET_TREUHAND_CONFIG_FAIL = '[TREUHAND] Get Treuhand Config failed',
    SET_TREUHAND_CONFIG = '[TREUHAND] Set Treuhand Config',
    SET_TREUHAND_CONFIG_COMPLETE = '[TREUHAND] Set Treuhand Config complete',
    SET_TREUHAND_CONFIG_FAIL = '[TREUHAND] Set Treuhand Config failed',
    COPY_TREUHAND_CONFIG = '[TREUHAND] Copy Treuhand Config',
    COPY_TREUHAND_CONFIG_COMPLETE = '[TREUHAND] Copy Treuhand Config complete',
    COPY_TREUHAND_CONFIG_FAIL = '[TREUHAND] Copy Treuhand Config failed',
    GET_TREUHAND_CONFIG_IDFS = '[TREUHAND] Get Treuhand Config Idfs',
    GET_TREUHAND_CONFIG_IDFS_COMPLETE = '[TREUHAND] Get Treuhand Config Idfs complete',
    GET_TREUHAND_CONFIG_IDFS_FAIL = '[TREUHAND] Get Treuhand Config Idfs failed',
}

export class GetTreuhandConfigAction implements Action {
    readonly type = TreuhandActionTypes.GET_TREUHAND_CONFIG;

    constructor(public payload: any) {
    }
}

export class GetTreuhandConfigCompleteAction implements Action {
    readonly type = TreuhandActionTypes.GET_TREUHAND_CONFIG_COMPLETE;

    constructor(public payload: TreuhandConfigModel) {
    }
}

export class GetTreuhandConfigFailAction implements Action {
    readonly type = TreuhandActionTypes.GET_TREUHAND_CONFIG_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class SetTreuhandConfigAction implements Action {
    readonly type = TreuhandActionTypes.SET_TREUHAND_CONFIG;

    constructor(public payload: TreuhandConfigModel) {
    }
}

export class SetTreuhandConfigCompleteAction implements Action {
    readonly type = TreuhandActionTypes.SET_TREUHAND_CONFIG_COMPLETE;

    constructor(public payload: TreuhandConfigModel) {
    }
}

export class SetTreuhandConfigFailAction implements Action {
    readonly type = TreuhandActionTypes.SET_TREUHAND_CONFIG_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class CopyTreuhandConfigAction implements Action {
    readonly type = TreuhandActionTypes.COPY_TREUHAND_CONFIG;

    constructor(public payload: any) {
    }
}

export class CopyTreuhandConfigCompleteAction implements Action {
    readonly type = TreuhandActionTypes.COPY_TREUHAND_CONFIG_COMPLETE;

    constructor(public payload: any) {
    }
}

export class CopyTreuhandConfigFailAction implements Action {
    readonly type = TreuhandActionTypes.COPY_TREUHAND_CONFIG_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class GetTreuhandConfigIdfsAction implements Action {
    readonly type = TreuhandActionTypes.GET_TREUHAND_CONFIG_IDFS;

    constructor(public payload: any) {
    }
}

export class GetTreuhandConfigIdfsCompleteAction implements Action {
    readonly type = TreuhandActionTypes.GET_TREUHAND_CONFIG_IDFS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class GetTreuhandConfigIdfsFailAction implements Action {
    readonly type = TreuhandActionTypes.GET_TREUHAND_CONFIG_IDFS_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type TreuhandActions =
    GetTreuhandConfigAction |
    GetTreuhandConfigCompleteAction |
    GetTreuhandConfigFailAction |
    SetTreuhandConfigAction |
    SetTreuhandConfigCompleteAction |
    SetTreuhandConfigFailAction |
    CopyTreuhandConfigAction |
    CopyTreuhandConfigCompleteAction |
    CopyTreuhandConfigFailAction |
    GetTreuhandConfigIdfsAction |
    GetTreuhandConfigIdfsCompleteAction |
    GetTreuhandConfigIdfsFailAction;

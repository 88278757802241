<div id="slider-marketing" class="carousel slide mb-xl" data-ride="carousel" data-interval="3000">
        <ol class="carousel-indicators mb-0">
            <li data-target="#slider-marketing"
                *ngFor="let slide of slides; let i = index"
                [ngClass]="showIndicators(slides) ? ( i === 0 ? 'active' : ''): 'hidden' "
                [attr.data-slide-to]="i">
            </li>
        </ol>

    <div class="carousel-inner">
        <div class="carousel-item"
             *ngFor="let slide of slides; let i = index"
             [ngClass]="{'active' : i === 0}">
            <a *ngIf="slide.link"
               href="{{slide.link}}"
               [attr.aria-label]="slide.name"
               target="_blank"
               class="mtm-slide"
               [id]="'slide-' + i"
               (click)="mtmEvent(slide.name, i+1)">
                <img src="{{ CDN }}/marketing-management/{{slide.image}}"
                     alt="{{slide.name}}">
            </a>
            <span *ngIf="!slide.link"
                  [id]="'slide-' + i">
                <img src="{{ CDN }}/marketing-management/{{slide.image}}"
                     alt="{{slide.name}}"
                     class="mtm-slide"/>
            </span>
        </div>
    </div>
    <a class="carousel-control-prev"
       href="#slider-marketing"
       role="button"
       data-slide="prev"
       *ngIf="slides?.length > 1">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Vorher</span>
    </a>
    <a class="carousel-control-next" href="#slider-marketing" role="button" data-slide="next"
       *ngIf="slides?.length > 1">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Nächste</span>
    </a>
</div>

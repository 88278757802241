import {HttpErrorResponse} from '@angular/common/http';
import {TreuhandActionTypes, TreuhandActions} from '../../actions/integrations/treuhand.actions';

export interface State {
    treuhandConfig: any;
    getTreuhandConfigLoading: boolean;
    getTreuhandConfigErrorResponse: HttpErrorResponse;
    setTreuhandConfigLoading: boolean;
    setTreuhandConfigErrorResponse: HttpErrorResponse;
    copyTreuhandConfigLoading: boolean;
    copyTreuhandConfigErrorResponse: HttpErrorResponse;
    treuhandConfigIdfs: any;
    getTreuhandConfigIdfsLoading: boolean;
    getTreuhandConfigIdfsErrorResponse: HttpErrorResponse;
}

export const initialState: State = {
    treuhandConfig: null,
    getTreuhandConfigLoading: false,
    getTreuhandConfigErrorResponse: null,
    setTreuhandConfigLoading: false,
    setTreuhandConfigErrorResponse: null,
    copyTreuhandConfigLoading: false,
    copyTreuhandConfigErrorResponse: null,
    treuhandConfigIdfs: null,
    getTreuhandConfigIdfsLoading: false,
    getTreuhandConfigIdfsErrorResponse: null
};

export function reducer(state = initialState, action: TreuhandActions): State {
    switch (action.type) {
        case TreuhandActionTypes.GET_TREUHAND_CONFIG:
            return {
                ...state,
                getTreuhandConfigLoading: true,
                getTreuhandConfigErrorResponse: null
            };
        case TreuhandActionTypes.GET_TREUHAND_CONFIG_COMPLETE:
            return {
                ...state,
                treuhandConfig: action.payload,
                getTreuhandConfigLoading: false
            };
        case TreuhandActionTypes.GET_TREUHAND_CONFIG_FAIL:
            return {
                ...state,
                getTreuhandConfigLoading: false,
                getTreuhandConfigErrorResponse: action.payload
            };
        case TreuhandActionTypes.SET_TREUHAND_CONFIG:
            return {
                ...state,
                setTreuhandConfigLoading: true,
                setTreuhandConfigErrorResponse: null
            };
        case TreuhandActionTypes.SET_TREUHAND_CONFIG_COMPLETE:
            return {
                ...state,
                setTreuhandConfigLoading: false
            };
        case TreuhandActionTypes.SET_TREUHAND_CONFIG_FAIL:
            return {
                ...state,
                setTreuhandConfigLoading: false,
                setTreuhandConfigErrorResponse: action.payload
            };
        case TreuhandActionTypes.COPY_TREUHAND_CONFIG:
            return {
                ...state,
                copyTreuhandConfigLoading: true,
                copyTreuhandConfigErrorResponse: null
            };
        case TreuhandActionTypes.COPY_TREUHAND_CONFIG_COMPLETE:
            return {
                ...state,
                copyTreuhandConfigLoading: false
            };
        case TreuhandActionTypes.COPY_TREUHAND_CONFIG_FAIL:
            return {
                ...state,
                copyTreuhandConfigLoading: false,
                copyTreuhandConfigErrorResponse: action.payload
            };
        case TreuhandActionTypes.GET_TREUHAND_CONFIG_IDFS:
            return {
                ...state,
                getTreuhandConfigIdfsLoading: true,
                getTreuhandConfigIdfsErrorResponse: null
            };
        case TreuhandActionTypes.GET_TREUHAND_CONFIG_IDFS_COMPLETE:
            return {
                ...state,
                treuhandConfigIdfs: action.payload,
                getTreuhandConfigIdfsLoading: false
            };
        case TreuhandActionTypes.GET_TREUHAND_CONFIG_IDFS_FAIL:
            return {
                ...state,
                getTreuhandConfigIdfsLoading: false,
                getTreuhandConfigIdfsErrorResponse: action.payload
            };
        default:
            return state;
    }
}

export const getTreuhandLoading = (state: State) => state.getTreuhandConfigLoading;
export const getTreuhandConfig = (state: State) => state.treuhandConfig;
export const getTreuhandError = (state: State) => state.getTreuhandConfigErrorResponse;
export const setTreuhandLoading = (state: State) => state.setTreuhandConfigLoading;
export const setTreuhandError = (state: State) => state.setTreuhandConfigErrorResponse;
export const copyTreuhandLoading = (state: State) => state.copyTreuhandConfigLoading;
export const copyTreuhandError = (state: State) => state.copyTreuhandConfigErrorResponse;
export const getTreuhandIdfsLoading = (state: State) => state.getTreuhandConfigIdfsLoading;
export const getTreuhandConfigIdfs = (state: State) => state.treuhandConfigIdfs;
export const getTreuhandIdfsError = (state: State) => state.getTreuhandConfigIdfsErrorResponse;

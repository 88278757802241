import {NgModule} from '@angular/core';
import {SuccessComponent} from './success.component';
import {MessageModule} from '../message/message.module';

@NgModule({
    imports: [
        MessageModule
    ],
    declarations: [SuccessComponent],
    exports: [SuccessComponent]
})
export class SuccessModule {
}

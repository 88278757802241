import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import {WebshopRestService} from '../../services/api/webshop-rest.service';
import * as fromPromoActions from '../actions/promo.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class PromoEffects {

    @Effect()
    loadPromo$: Observable<any> = this.actions$
        .pipe(ofType<fromPromoActions.PromoLoadAction>(fromPromoActions.PromoActionTypes.PROMO_LOAD))
        .pipe(map(action => action.payload), switchMap((productRequest) => {
            return this.productRestService.loadPromo().pipe(
                switchMap(data => {
                    const resp = new ProductsResponse({...data.returnObject});
                    if (productRequest.page === 1) {
                        return [new fromPromoActions.PromoLoadCompleteAction(resp), new fromPromoActions.PromoBatchFromCache()];
                    } else {
                        return [new fromPromoActions.PromoLoadCompleteAction(resp)];
                    }
                }),
                catchError((error) => of(new fromPromoActions.PromoLoadFailAction(error)))
            );
        }));

    @Effect()
    searchPromo$: Observable<any> = this.actions$
        .pipe(ofType<fromPromoActions.PromoSearchAction>(fromPromoActions.PromoActionTypes.PROMO_SEARCH))
        .pipe(map(action => action.payload), switchMap((request) => [new fromPromoActions.PromoLoadAction(request)]));


    constructor(
        private productRestService: WebshopRestService,
        private actions$: Actions
    ) {
    }

}
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../../../../core/validators/must-match.validator';
import {PasswordValidator} from '../../../../../core/validators/password.validator';
import {NewPasswordRestService} from '../../../../../core/services/api/new-password.rest-service';

export interface ChangePasswordDialogData {
    oldPassword?: string;
    showBackButton?: boolean;
}

@Component({
    selector: 'change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

    public loading = false;

    constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
                @Inject(NewPasswordRestService) public newPasswordRestService: NewPasswordRestService,
                @Inject(MAT_DIALOG_DATA) public data: ChangePasswordDialogData) {
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close({data: 'cancel'});
    }

    onSuccess() {
        this.dialogRef.close({data: 'success'});
    }

    onLoad($event) {
        this.loading = $event;
    }

    onFail($event) {
        this.dialogRef.close({data: $event});
    }

}

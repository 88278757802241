import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;
  @Input("open") public open = false;

  constructor(@Inject('CMS_URL') public CMS_URL,
              @Inject(UserService) private userService: UserService) {
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}

import {map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from 'app/core/abstracts/rest-service.abstract';
import {Observable, Subject} from 'rxjs';
import {Response} from 'app/core/models/response.model';
import {SubUserModel} from 'app/modules/account/components/sub-user-management/model/sub-user.model';

@Injectable({
    providedIn: 'root',
})
export class SubUserRestService extends RestServiceAbstract implements OnDestroy {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public loadSubUsers(): Observable<Response> {
        return this.get(`/usermanagement/user/subusers`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public create(sum: SubUserModel): Observable<Response> {
        return this.post(`/usermanagement/user/subusers/create`, sum).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public update(sum: SubUserModel): Observable<Response> {
        return this.patch(`/usermanagement/user/subusers/update/${sum.id}`, sum).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public remove(sum: SubUserModel): Observable<Response> {
        return this.delete(`/usermanagement/user/subusers/delete/${sum.id}`, sum).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public requestAuthTree(): Observable<Response> {
        return this.get(`/usermanagement/user/subusers/authtree`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properlly.
        this.ngUnsubscribe$.complete();
    }
}

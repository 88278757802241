import {Invoice} from './invoice.model';

export class InvoicesResponse {
    results: {[id: string]: Invoice[]} = {};
    constructor(obj: any) {
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                this.results[obj.items[i].dc] = obj.items.map((result) => new Invoice(result));
            }
        }
    }
}

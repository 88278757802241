import {ExclusiveOffersActions, ExclusiveOffersActionTypes} from '../actions/exclusive-offers.actions';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';


export interface State {
    exclusiveOffersRequest: ProductRequest;
    exclusiveOffersResponse: ProductsResponse;
    exclusiveOffersErrorResponse: HttpErrorResponse;
    exclusiveOffersLoading: boolean,
    exclusiveOffersProducts: Product[];
    exclusiveOffersProductCache: Product[];
    exclusiveOffersScrollPosition: number;
}

export const initialState: State = {
    exclusiveOffersRequest: null,
    exclusiveOffersResponse: null,
    exclusiveOffersErrorResponse: null,
    exclusiveOffersLoading: false,
    exclusiveOffersProducts: [],
    exclusiveOffersProductCache: [],
    exclusiveOffersScrollPosition: 0
};

export function reducer(state = initialState, action: ExclusiveOffersActions): State {
    switch (action.type) {
        case ExclusiveOffersActionTypes.LOAD_EXCLUSIVE_OFFERS:
            return {
                ...state,
                exclusiveOffersLoading: true
            };
        case ExclusiveOffersActionTypes.LOAD_EXCLUSIVE_OFFERS_COMPLETE:
            return {
                ...state,
                exclusiveOffersProductCache: [...state.exclusiveOffersProductCache, ...action.payload.products],
                exclusiveOffersResponse: action.payload,
                exclusiveOffersLoading: false
            };
        case ExclusiveOffersActionTypes.LOAD_EXCLUSIVE_OFFERS_FAIL:
            return {
                ...state,
                exclusiveOffersErrorResponse: action.payload,
                exclusiveOffersLoading: false
            };
        case ExclusiveOffersActionTypes.SEARCH_EXCLUSIVE_OFFERS:
            return {
                ...state,
                exclusiveOffersProducts: [],
                exclusiveOffersProductCache: [],
                exclusiveOffersLoading: true
            };
        case ExclusiveOffersActionTypes.UPDATE_SCROLLING_POSITION:
            return {
                ...state,
                exclusiveOffersScrollPosition: action.payload
            };
        case ExclusiveOffersActionTypes.GET_BATCH_FROM_CACHE:
            return {
                ...state,
                exclusiveOffersProducts: [...state.exclusiveOffersProducts, ...state.exclusiveOffersProductCache.slice(0, 20)],
                exclusiveOffersProductCache: state.exclusiveOffersProductCache.slice(20)
            };
        default:
            return state;
    }
}

export const getExclusiveOffersLoading = (state: State) => state.exclusiveOffersLoading;
export const getExclusiveOffersRequest = (state: State) => state.exclusiveOffersRequest;
export const getExclusiveOffersResponse = (state: State) => state.exclusiveOffersResponse;
export const getExclusiveOffersErrorResponse = (state: State) => state.exclusiveOffersErrorResponse;
export const getExclusiveOffersProductCache = (state: State) => state.exclusiveOffersProductCache;
export const getExclusiveOffersProducts = (state: State) => state.exclusiveOffersProducts;
export const getExclusiveOffersScrollPosition = (state: State) => state.exclusiveOffersScrollPosition;
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationPublicComponent} from './notification-public.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [NotificationPublicComponent],
    exports: [NotificationPublicComponent]
})
export class NotificationPublicModule {
}

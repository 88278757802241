import {TopPrismaActions, TopPrismaActionTypes} from '../actions/top-prisma.actions';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';


export interface State {
    topPrismaRequest: ProductRequest;
    topPrismaResponse: ProductsResponse;
    topPrismaErrorResponse: HttpErrorResponse;
    topPrismaLoading: boolean;
    topPrismaProducts: Product[],
    topPrismaProductCache: Product[],
    topPrismaScrollPosition: number;
}

export const initialState: State = {
    topPrismaRequest: null,
    topPrismaResponse: null,
    topPrismaErrorResponse: null,
    topPrismaLoading: false,
    topPrismaProducts: [],
    topPrismaProductCache: [],
    topPrismaScrollPosition: 0
};

export function reducer(state = initialState, action: TopPrismaActions): State {
    switch (action.type) {
        case TopPrismaActionTypes.LOAD_TOPPRISMA:
            return {
                ...state,
                topPrismaRequest: action.payload,
            };
        case TopPrismaActionTypes.LOAD_TOPPRISMA_COMPLETE:
            return {
                ...state,
                topPrismaProductCache: [...state.topPrismaProductCache, ...action.payload.products],
                topPrismaResponse: action.payload,
                topPrismaLoading: false
            };
        case TopPrismaActionTypes.LOAD_TOPPRISMA_FAIL:
            return {
                ...state,
                topPrismaErrorResponse: action.payload,
                topPrismaLoading: false
            };
        case TopPrismaActionTypes.SEARCH_TOPPRISMA:
            return {
                ...state,
                topPrismaProducts: [],
                topPrismaProductCache: [],
                topPrismaLoading: true
            };
        case TopPrismaActionTypes.UPDATE_SCROLLING_POSITION:
            return {
                ...state,
                topPrismaScrollPosition: action.payload
            };
        case TopPrismaActionTypes.GET_BATCH_FROM_CACHE:
            return {
                ...state,
                topPrismaProducts: [...state.topPrismaProducts, ...state.topPrismaProductCache.slice(0, 20)],
                topPrismaProductCache: state.topPrismaProductCache.slice(20)
            };
        default:
            return state;
    }
}

export const getTopPrismaLoading = (state: State) => state.topPrismaLoading;
export const getTopPrismaResponse = (state: State) => state.topPrismaResponse;
export const getTopPrismaRequest = (state: State) => state.topPrismaRequest;
export const getTopPrismaErrorResponse = (state: State) => state.topPrismaErrorResponse;
export const getTopPrismaProductCache = (state: State) => state.topPrismaProductCache;
export const getTopPrismaProducts = (state: State) => state.topPrismaProducts;
export const getTopPrismaScrollPosition = (state: State) => state.topPrismaScrollPosition;

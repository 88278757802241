export class DocType {
    attribute: string;
    value: string;
    distributionCenters: any[];

    constructor(obj: any) {
        this.attribute = obj && obj.attribute || null;
        this.value = obj && obj.value || null;
        this.distributionCenters = obj && obj.distributionCenters || null;

    }
}

export class TrackItem {
    order: String;
    delayInformation: String;
    delayInMinutes: number;
    deliveredAt: String;
    expectedTimeOfArrivalMax: Date;
    expectedTimeOfArrivalMin: Date;
    plannedTimeOfArrival: Date;
    tourId: number;
    center: String;
    dc: any;
    idf: String;
    package: Object[];
    delayNotification: DelayNotification;

    constructor(obj: any = {}) {
        this.dc = obj.dc;
        this.idf = obj.idf;
        this.delayNotification = obj.delayNotification ? new DelayNotification(obj.delayNotification) : null;

        if (obj.tour) {
            obj = obj.tour;
        }

        this.order = obj.cscOrderNo ? obj.cscOrderNo : obj.order;
        this.tourId = obj.tourId;
        this.delayInformation = obj.delayInformation;
        this.delayInMinutes = obj.delayInMinutes;
        this.deliveredAt = obj.deliveredAt;
        this.expectedTimeOfArrivalMax = obj.expectedTimeOfArrivalMax ? new Date(obj.expectedTimeOfArrivalMax) : null;
        this.expectedTimeOfArrivalMin = obj.expectedTimeOfArrivalMin ? new Date(obj.expectedTimeOfArrivalMin) : null;
        this.plannedTimeOfArrival = obj.plannedTimeOfArrival ? new Date(obj.plannedTimeOfArrival) : null;
        this.center = obj.center;
        this.package = obj.package;
    }
}

export class DelayNotification {
    distributionCenter: number;
    tour: String;
    reason: String;
    details: String;
    delay: String;
    day: Date;

    constructor(obj: any = {}) {
        this.distributionCenter= obj.distributionCenter ? obj.distributionCenter : null;
        this.tour= obj.tour ? obj.tour : null;
        this.reason= obj.reason ? obj.reason : null;
        this.details= obj.details ? obj.details : null;
        this.delay= obj.delay ? obj.delay : null;
        this.day= obj.day ? new Date(obj.day) : new Date();
    }
}

import {Action} from '@ngrx/store';
import {InvoicesRequest} from "../../models/invoices-request.model";
import {InvoicesResponse} from "../../models/invoices-response.model";
import {HttpErrorResponse} from "@angular/common/http";
import {DocTypesRequest} from "../../models/doc-types-request.model";

export enum InvoicesActionTypes {
    SET_BRANCHES_FOR_INVOICE_SEARCH = '[Invoices] Set branch for invoice search',
    GET_INVOICES_BY_BRANCH_ID = '[Invoices] Get invoices by branchId',
    SEARCH_INVOICES = '[Invoices] Search Invoices',
    SEARCH_INVOICES_COMPLETE = '[Invoices] Search Invoices Complete',
    SEARCH_INVOICES_FAIL = '[Invoices] Search Invoices Fail',
    RESET_SEARCH_INVOICES_RESPONSE = '[Invoices] Reset invoice response',
    LOAD_DOC_TYPES = '[Invoices] Load User Document Types',
    LOAD_DOC_TYPES_COMPLETE = '[Invoices] Load User Document Types complete',
    LOAD_DOC_TYPES_FAIL = '[Invoices] Load User Document Types FAIL',
    LOAD_ALL_DOC_TYPES = '[Invoices] Load All User Document Types',
    LOAD_ALL_DOC_TYPES_COMPLETE = '[Invoices] Load All User Document Types complete',
    LOAD_ALL_DOC_TYPES_FAIL = '[Invoices] Load All User Document Types FAIL',
    SHOW_INVOICE_CONTACT_FORM_VIEW = '[Invoices] Show Contact Form in View',
    HIDE_INVOICE_CONTACT_FORM_VIEW = '[Invoices] hide Contact Form in View'
}

export class SetBranchesForInvoiceSearch implements Action {
    readonly type = InvoicesActionTypes.SET_BRANCHES_FOR_INVOICE_SEARCH;

    constructor(public payload: any) {
    }
}

export class SearchInvoicesAction implements Action {
    readonly type = InvoicesActionTypes.SEARCH_INVOICES;

    constructor(public payload: InvoicesRequest) { // == Query
    }
}

export class SearchInvoicesCompleteAction implements Action {
    readonly type = InvoicesActionTypes.SEARCH_INVOICES_COMPLETE;

    constructor(public payload: InvoicesResponse) {
    }
}

export class SearchInvoicesFailAction implements Action {
    readonly type = InvoicesActionTypes.SEARCH_INVOICES_FAIL;

    constructor(public payload: InvoicesResponse) {
    }
}

export class ResetSearchInvoicesResponse implements Action {
    readonly type = InvoicesActionTypes.RESET_SEARCH_INVOICES_RESPONSE;

    constructor() {
    }
}

export class LoadDocTypesAction implements Action {
    readonly type = InvoicesActionTypes.LOAD_DOC_TYPES;

    constructor(public payload: DocTypesRequest) { // == Query
    }
}

export class LoadDocTypesCompleteAction implements Action {
    readonly type = InvoicesActionTypes.LOAD_DOC_TYPES_COMPLETE;

    constructor(public payload: any, public request: DocTypesRequest) { // == Query
    }
}

export class LoadDocTypesFailAction implements Action {
    readonly type = InvoicesActionTypes.LOAD_DOC_TYPES_FAIL;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export class LoadAllDocTypesAction implements Action {
    readonly type = InvoicesActionTypes.LOAD_ALL_DOC_TYPES;

    constructor() { // == Query
    }
}

export class LoadAllDocTypesCompleteAction implements Action {
    readonly type = InvoicesActionTypes.LOAD_ALL_DOC_TYPES_COMPLETE;

    constructor(public payload: any) { // == Query
    }
}

export class LoadAllDocTypesFailAction implements Action {
    readonly type = InvoicesActionTypes.LOAD_ALL_DOC_TYPES_FAIL;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export class ShowInvoiceContactFormViewAction implements Action {
    readonly type = InvoicesActionTypes.SHOW_INVOICE_CONTACT_FORM_VIEW;

    constructor() { // == query
    }
}

export class HideInvoiceContactFormViewAction implements Action {
    readonly type = InvoicesActionTypes.HIDE_INVOICE_CONTACT_FORM_VIEW;

    constructor() { // == query
    }
}

export type InvoicesActions =
    SetBranchesForInvoiceSearch |
    SearchInvoicesAction |
    SearchInvoicesCompleteAction |
    SearchInvoicesFailAction|
    ResetSearchInvoicesResponse |
    LoadDocTypesAction|
    LoadDocTypesCompleteAction|
    LoadDocTypesFailAction|
    LoadAllDocTypesAction|
    LoadAllDocTypesCompleteAction|
    LoadAllDocTypesFailAction |
    ShowInvoiceContactFormViewAction |
    HideInvoiceContactFormViewAction;

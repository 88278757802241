import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PasswordFormComponent} from "./password-form.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import {NotificationModule} from "../../shared/notification/notification.module";
import {SpinnerModule} from "../../shared/spinner/spinner.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        NotificationModule,
        SpinnerModule,
    ],
    declarations: [PasswordFormComponent],
    exports: [PasswordFormComponent]
})
export class PasswordFormModule {
}

import {Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {CartService} from "../../../../../core/services/cart.service";
import {CartItem} from "../../../../../core/models/shop/cart-item.model";
import {Observable} from "rxjs";
import {Totals} from "../../../../../core/models/totals.model";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'add-to-cart-notifier',
    templateUrl: './add-to-cart-notifier.component.html',
    styleUrls: ['./add-to-cart-notifier.component.scss']
})
export class AddToCartNotifierComponent implements OnInit, OnChanges {
    @Input() cartItems: Observable<CartItem[]>;
    @Input() latestItems: Observable<CartItem[]>;
    public totals: Totals;
    public show: boolean = false;
    public quantity: number;
    public price: number;
    public names: string;
    public plural: string;

    constructor(@Inject(CartService) public cartService: CartService,
                public router: Router) {
    }

    ngOnInit() {
    }

    ngOnChanges(e) {
        let currentUrl = this.router.url;
        if (e.cartItems
            && e.cartItems.currentValue
            && e.cartItems.currentValue.length != 0
            && e.cartItems.currentValue.length >= e.cartItems.previousValue.length
            && currentUrl != '/warenkorb') {
            this.quantity = e.latestItems.currentValue.reduce((sum, q) => {
                return sum + (+q.quantity);
            }, 0);
            this.price = e.latestItems.currentValue.reduce((totalPrice, p) => {
                return totalPrice + (+p.quantity * p.price);
            }, 0.0);
            this.names = e.latestItems.currentValue.map(i => i.product.name).join(", ");
            if (e.latestItems.currentValue.length > 1) {
                this.plural = "n";
            } else {
                this.plural = "";
            }
            this.show = true;
            this.totals = this.cartService.calculateTotals();
            setTimeout(() => {
                this.show = false
            }, 4000);  //1
        }
    }

}

import {Injectable} from '@angular/core';
import {CartItem} from '../models/shop/cart-item.model';
import {Product} from '../models/shop/product.model';

@Injectable({
    providedIn: 'root',
})

export class MatomoService {

    constructor() {
        try {
            if (!this.isInitialized()) {
                console.warn('Matomo has not yet been initialized! (Did you forget to inject it?)');
            }
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    private isInitialized(): boolean {
        return window['_paq'] !== undefined;
    }

    viewProduct(product: Product) {
        const args: any[] = [];
        args.push(product.id);
        args.push(product.name);
        args.push(product.type);
        args.push(product.retailPrice);

        if (this.isInitialized()) {
            window['_paq'].push(['setEcommerceView', ...args]);
        }
    }

    addItemToCart(item: CartItem) {
        const args: any[] = [];
        args.push(item.product.id);
        args.push(item.product.name);
        args.push(item.product.type);
        args.push(item.price);
        args.push(item.quantity);

        if (this.isInitialized()) {
            window['_paq'].push(['addEcommerceItem', ...args]);
        }
    }

    removeItemFromCart(pzn) {
        if (this.isInitialized()) {
            window['_paq'].push(['removeEcommerceItem', pzn]);
        }
    }

    updateCartTotal(total: number) {
        if (this.isInitialized()) {
            window['_paq'].push(['trackEcommerceCartUpdate', total.toFixed(2)]);
        }
    }

    clearCart() {
        if (this.isInitialized()) {
            window['_paq'].push(['clearEcommerceCart']);
        }
        this.updateCartTotal(0);
    }

    trackOrder(order, total) {
        const args: any[] = [];
        args.push(order.orderId);
        args.push((total.total * 1.19 + total.shipping).toFixed(2));
        args.push((total.total).toFixed(2));
        args.push((total.total * 0.19).toFixed(2));
        args.push((total.shipping).toFixed(2));

        if (this.isInitialized()) {
            window['_paq'].push(['trackEcommerceOrder', ...args]);
        }
    }

    trackEvent(category: string, action: string, name?: string, value?: number) {
        const args: any[] = [category, action];
        if (!!name) {
            args.push(name);
        }
        if (typeof value === 'number') {
            args.push(value);
        }
        if (this.isInitialized()) {
            window['_paq'].push(['trackEvent', ...args]);
        }
    }

    setUserType(type) {
        if (this.isInitialized()) {
            window['_paq'].push(['setCustomDimension', 1, type]);
        }
    }

    setUserGroup(group) {
        if (this.isInitialized() && !!group) {
            window['_paq'].push(['setCustomDimension', 2, group]);
        }
    }

    setUserIdf(idf) {
        if (this.isInitialized()) {
            window['_paq'].push(['setCustomDimension', 3, idf]);
        }
    }

    setUserDC(dc) {
        if (this.isInitialized()) {
            window['_paq'].push(['setCustomDimension', 4, dc]);
        }
    }

    setFormConversion(id) {
        if (this.isInitialized()) {
            window['_paq'].push(['FormAnalytics::trackFormConversion', id]);
        }
    }

    trackVisibleContentImpressions(checkOnScroll: boolean, timeInterval: number) {
        if (this.isInitialized()) {
            window['_paq'].push(['trackVisibleContentImpressions', checkOnScroll, timeInterval]);
        }
    }
    trackVisibleContentImpressions2() {
        if (this.isInitialized()) {
            window['_paq'].push(['trackVisibleContentImpressions']);
        }
    }

    trackAllContentImpressions() {
        if (this.isInitialized()) {
            window['_paq'].push(['trackAllContentImpressions']);
        }
    }

    trackContentImpressionsWithinNode(element) {
        if (this.isInitialized()) {
            window['_paq'].push(['trackContentImpressionsWithinNode', element]);
        }
    }
}

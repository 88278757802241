/**
 * ngrx cart actions
 */
import {Action} from '@ngrx/store';
import {CartItem} from "../../models/shop/cart-item.model";

export enum CartActionTypes {
    ADD_CART_ITEM_ACTION = '[Cart] Add cart item',
    UPDATE_CART_ITEM_ACTION = '[Cart] Update cart item',
    UPDATE_CART_ITEMS_ACTION = '[Cart] Update cart items',
    REMOVE_CART_ITEM_ACTION = '[Cart] Remove cart item',
    CLEAR_CART_ACTION = '[Cart] Clear cart',
}

export class AddCartItemAction implements Action {
    readonly type = CartActionTypes.ADD_CART_ITEM_ACTION;

    constructor(public payload: CartItem[]) {
    }
}
export class UpdateCartItemAction implements Action {
    readonly type = CartActionTypes.UPDATE_CART_ITEM_ACTION;

    constructor(public payload: CartItem) {
    }
}
export class UpdateCartItemsAction implements Action {
    readonly type = CartActionTypes.UPDATE_CART_ITEMS_ACTION;

    constructor(public payload: CartItem[]) {
    }
}
export class RemoveCartItemAction implements Action {
    readonly type = CartActionTypes.REMOVE_CART_ITEM_ACTION;

    constructor(public payload: string) {
    }
}
export class ClearCartAction implements Action {
    readonly type = CartActionTypes.CLEAR_CART_ACTION;

    constructor() {
    }
}

export type CartActions = AddCartItemAction
    | UpdateCartItemAction
    | UpdateCartItemsAction
    | RemoveCartItemAction
    | ClearCartAction;

import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ResponseSnakeModel} from '../../models/response-snake.model';

@Injectable({
  providedIn: 'root'
})

export class DialogRestService extends RestServiceAbstract {

  public getActiveDialogs() {
    return this.get('/cms/dialogs').pipe(map(data => {
      return new ResponseSnakeModel(data);
    }));
  }

  public getActiveDialogsOnPage(page: string) {
    return this.get('/cms/dialogs/'+page).pipe(map(data => {
      return new ResponseSnakeModel(data);
    }));
  }

  public subscribeToDialogOffer(id) {
   return this.post('/cms/dialogs/' + id, {subscribe: true}).pipe(map(data => {
      return new ResponseSnakeModel(data);
    }));
  }

  public optOutToDialogOffer(id) {
   return this.post('/cms/dialogs/' + id, {subscribe: false}).pipe(map(data => {
      return new ResponseSnakeModel(data);
    }));
  }
}

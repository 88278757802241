import {Component, Inject, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WebshopRestService} from '../../../../core/services/api/webshop-rest.service';
import {MatomoService} from '../../../../core/services/matomo.service';
import {UserService} from '../../../../core/services/user.service';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {InternalUserAuthority} from '../../../../core/models/enums/internal-user-authority.model';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'download-area',
    templateUrl: './download-area.component.html',
    styleUrls: ['./download-area.component.scss']
})
export class DownloadAreaComponent implements OnInit, OnDestroy {

    public data;
    public config: any;
    public prismaDatum;
    public basisDatum;
    public input = false;
    public dateLoading = true;
    public loading = false;
    public selectedIdf;
    // public mainIdf;
    public idfs$: Observable<any[]>;

    userForm = this.fb.group({
        idfInput: ['']
    });
    public ngUnsubscribe$ = new Subject<any>(); // emit unsubscription


    constructor(public translate: TranslateService,
                @Inject(WebshopRestService) public webshopRestService: WebshopRestService,
                public matomoService: MatomoService,
                public fb: FormBuilder,
                public route: ActivatedRoute,
                @Inject(UserService) private userService: UserService) {
        // this.idfs$ = this.userService.selectContextualizedIdfsObservable('orders');

        this.input = this.userService.isPhoenixUser() || this.userService.isInternalUser();
        this.data = this.route.snapshot.data;
        this.config = this.data.downloadArea;
        this.userService.selectedIdf$.subscribe(idf => {
                this.loading = true;
                if (idf) {
                    this.selectedIdf = idf.idf;
                    // this.userForm.get('idfInput').setValue(idf.idf);
                    // this.mainIdf = idf.idf;
                    this.loading = false;
                    this.fileDate('OTHER');
                    this.fileDate('BASISLISTE');
                }
            });
        if (this.input) {
            this.checkIdfChangeRight();
        }
    }

    checkIdfChangeRight() {
        // Check idf selection change authority for internal user
        if (this.input && !this.userService.canInternalUserChangeIdfSelection(InternalUserAuthority.ORDERS_DELIVERIES_ADMINISTRATION)) {
            this.userForm.get('idfInput').disable();
        }
    }

    downloadFile(type) {
        this.webshopRestService.watchDownload().subscribe(down=>{
            this.loading = down === 'start';
       })
        if (type === 'OTHER') {
           this.webshopRestService.downloadPrismaFile(this.selectedIdf);
        } else {
            this.webshopRestService.downloadBasisFile(this.selectedIdf);
        }

    }

    matomoEvent(action) {
        this.matomoService.trackEvent('PHOENIX Angebotsdateien', action);
    }

    // setIdf(event) {
    //     this.dateLoading = true;
    //     this.selectedIdf = event.value;
    //     this.fileDate('OTHER');
    //     this.fileDate('BASISLISTE');
    // }

    fileDate(type) {
        this.dateLoading=true;
        this.webshopRestService.getFileDate(type, this.selectedIdf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
            if (type === 'OTHER') {
                this.prismaDatum = res;
                console.log(this.prismaDatum);
            } else {
                this.basisDatum = res;
            }
            this.dateLoading = false;
        }, error => {
            this.dateLoading = false;
        });
    }

    hasAuthority(conf: any) {
        if (conf.hasOwnProperty('authorities')) {
            return this.userService.hasRequiredAuthority(conf.authorities);
        }
        return true;
    }

    getType(conf) {
        if (conf.hasOwnProperty('authorities')) {
            return conf.authorities;
        }
        return 'OTHER';
    }

    ngOnInit(): void {

    }

    /**
     * unsubcribe all subscriptions on destroy
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

}

export class Invoice {
    filename: string;
    invNr: number;
    invDate: Date;
    idf: number;
    dc: number;
    id: number;
    pdfSize: number;
    type: string;

  constructor(obj: any) {
      this.filename = obj && obj.filename || null;
      this.invNr = obj && obj.invNr || 0;
      this.invDate = obj && obj.invDate && new Date(obj.invDate) || new Date();
      this.idf = obj && obj.idf || 0;
      this.dc = obj && obj.dc || 0;
      this.id = obj && obj.id || 16;
      this.pdfSize = obj && obj.pdfSize || 0;
      this.type = obj && obj.type || null;
  }
}

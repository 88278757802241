import { Injectable } from '@angular/core';

export abstract class Log {
    dir: any;
    info: any;
    warn: any;
    error: any;
}

@Injectable()
export class LogService implements Log {
    dir: any;
    info: any;
    warn: any;
    error: any;
    invokeConsoleMethod(type: string, args?: any): void {}
}

<form [formGroup]="userForm" class="small" [class.submitted]="submitted">
    <div class="row">
        <div class="col-md-6">
            <div class="pt-2">
                <mat-form-field appearance="outline">
                    <mat-label>Anrede *</mat-label>
                    <mat-select [formControlName]="'salutation'">
                        <mat-option *ngFor="let salutation of ['Herr', 'Frau']"
                                    [value]="salutation">
                            {{salutation}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="salutation.hasError('required')">Bitte wählen Sie eine Anrede</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input data-cy="input-owner-firstName" matInput autocomplete="off" name="firstName"
                           formControlName="firstName">
                    <mat-error *ngIf="firstName.hasError('required') && submitted">Bitte geben Sie Ihren Vornamen ein.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-6">
            <div class="pt-2">
                <mat-form-field appearance="outline">
                    <mat-label>Titel</mat-label>
                    <input data-cy="input-owner-title" matInput autocomplete="off" name="title"
                           formControlName="title">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input data-cy="input-owner-lastName" matInput autocomplete="off" name="lastName"
                           formControlName="lastName">
                    <mat-error *ngIf="lastName.hasError('required') && submitted">Bitte geben Sie Ihren Nachnamen ein.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row buttons">
        <div class="col-md-6 text-center text-md-left">
            <button data-cy="cancel-owner-update" class="btn-secondary" (click)="onCancel()">Abbrechen</button>
        </div>
        <div class="col-md-6 text-center text-md-right">
            <button data-cy="submit-owner-update" class="btn-primary" (click)="onSubmit()">Änderung beantragen</button>
        </div>
    </div>
</form>


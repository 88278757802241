<div class="content">
    <div class="widget">
<!--        <div class="row justify-content-center align-items-center d-flex">-->
            <div class="d-flex flex-row align-items-center">
                <img src="assets/img/integrations/treuhand.svg" alt="treuhand"
                     style="max-height: 60px">
                <h1 class="p-0 ml-4">my.treuhand</h1>
            </div>
<!--            <div class="col-md-4 col-sm-4 ">-->
<!--                <form>-->
<!--                    <mat-form-field *ngIf="!input" appearance="outline">-->
<!--                        <mat-label>IDF-Nummer</mat-label>-->
<!--                        <mat-select-->
<!--                                [(value)]="currentlyDisplayedIdf"-->
<!--                                (selectionChange)="onChangeIdf($event.source.value)"-->
<!--                                name="idf">-->
<!--                            <mat-option *ngIf="(idfs$ | async)?.length === 0">-->
<!--                                <i class="fal fa-spinner-third fa-spin"></i> IDFs werden geladen.-->
<!--                            </mat-option>-->
<!--                            <mat-option *ngFor="let idf of (idfs$ | async)" [value]="idf.idf">{{ idf.idf }}-->
<!--                                ({{idf.name}})-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                    <mat-form-field *ngIf="input" appearance="outline">-->
<!--                        <mat-label>IDF-Nummer</mat-label>-->
<!--                        <input [value]="currentlyDisplayedIdf"-->
<!--                               (change)="onChangeIdf($event.target.value)"-->
<!--                               matInput name="idf" appTrim>-->
<!--                    </mat-form-field>-->
<!--                </form>-->
<!--            </div>-->
<!--        </div>-->

        <span class="notification text-center mt-3" type="info">
            Um die E-Mail Weiterleitung der PHOENIX Belege an das <a class="downloadLink"
                                                                     href="https://my.treuhand-hannover.de/Login/"
                                                                     target="_blank">my.treuhand Portal</a>.
            einzurichten, verwenden Sie bitte folgende technische E-Mail Adresse:
            <b [class.flashBG]="showFlash">{{technicalEmailAddress}}</b>
            <i class="fal fa-copy ml-2 cursorPointer" (click)="copyText()"></i>
        </span>

        <!--  loading spinner  -->
        <div class="row" *ngIf="(treuhandCofigLoading$ | async)">
            <div class="col-md-12">
                <spinner></spinner>
            </div>
        </div>

        <!--  notifications -->
        <notification [ngClass]="!showSuccessMsg ? 'd-none' : 'visible'" type="info"
                      message="Ihre Anfrage wurde erfolgreich übermittelt."></notification>
        <notification [ngClass]="!showErrorMsg ? 'd-none' : 'visible'" type="error"
                      message="Ihre Anfrage konnte nicht übermittelt werden."></notification>

        <div class="row"
             *ngIf="mainIdf !== currentlyDisplayedIdf && fieldsetDisabled && !(treuhandCofigLoading$ | async)">
            <div class="col-md-8 d-flex justify-content-center align-items-center">
                Für diese IDF ist aktuell keine Konfiguration hinterlegt. Möchten Sie die Übertragung einrichten?
            </div>
            <div class="col-md-4">
                <button class="btn-primary mt-0" type="button" (click)="enableForm()">JA</button>
            </div>
        </div>
        <ng-container *ngIf="(idfsWithConfig$ | async)?.length > 0">
            <ng-container *ngIf="((idfsWithConfig$ | async)?.length === 1 && enabledConfigIdfs[0] !== this.currentlyDisplayedIdf)
                                || (idfsWithConfig$ | async)?.length > 1">
                <div *ngIf="(!fieldsetDisabled || this.mainIdf === this.currentlyDisplayedIdf)">
                    <a class="downloadLink" (click)="showSelectIdfModal()">
                        Konfiguration einer anderen IDF übernehmen.
                    </a>
                </div>
            </ng-container>
        </ng-container>

        <!-- Form -->
        <form [formGroup]="treuhandForm"
              *ngIf="!(treuhandCofigLoading$ | async)"
              [ngStyle]="(fieldsetDisabled  && mainIdf !== currentlyDisplayedIdf && {'opacity': 0.5})">
            <fieldset [disabled]="fieldsetDisabled && mainIdf !== currentlyDisplayedIdf">
                <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2 text-center">
                        Belegtyp an treuhand
                        übertragen
                    </div>
                </div>
                <div class="row" *ngFor="let doc of documentTypes">
                    <div class="col-md-4 d-flex align-items-center">
                        {{doc.title}}
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>treuhand E-Mail Adresse</mat-label>
                            <input matInput
                                   autocomplete="off"
                                   name="{{doc.formControlName}}"
                                   formControlName="{{doc.formControlName}}"
                                   type="email"
                                   (input)="updateSlideToggleStatus(doc.formControlName,doc.toggleFormControlName)"
                            >
                            <mat-error *ngIf="treuhandForm.get(doc.formControlName).hasError('pattern') && submitted">
                                Bitte geben Sie eine gültige E-Mail-Adresse ein.
                            </mat-error>
                            <mat-error
                                    *ngIf="treuhandForm.get(doc.formControlName).hasError('restrictedEmail') && submitted">
                                Diese Email-Adresse nur im Treuhand Portal hinterlegen.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <div class="text-center slid">
                            <mat-slide-toggle formControlName="{{doc.toggleFormControlName}}"
                                              [color]="primary"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <button class="btn-secondary" type="button" (click)="onDeactivate()">DEAKTIVIEREN</button>
                        <button class="btn-primary" type="button" (click)="onSubmit()">KONFIGURATION SPEICHERN</button>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12 text-right">
                        <i class="fas fa-check-circle mr-1"
                           [ngClass]="isAnyDocTypeEmailActive ? 'phx-cta' : 'phx-corporate-light'"></i> treuhand
                        Schnittstelle aktiv
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>

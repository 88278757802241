import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {SubUserModelRepository} from '../../../modules/account/components/sub-user-management/repository/sub-user-model.repository';

export enum SubUserActionTypes {
    LoadSubUsers = '[SubUser] Load SubUsers',
    LoadSubUsersComplete = '[SubUser] Load SubUsers complete',
    LoadSubUsersFailed = '[SubUser] Load SubUsers failed',
    InitAuthTree = '[SubUser] Init SubUser Creation',
    InitAuthTreeComplete = '[SubUser] Init SubUser Creation complete',
    InitAuthTreeFailed = '[SubUser] Init SubUser Creation failed',
    AddSubUser = '[SubUser] Add SubUser',
    UpdateSubUser = '[SubUser] Update SubUser',
    RemoveSubUser = '[SubUser] Remove SubUser',
}

export class LoadSubUsers implements Action {
    readonly type = SubUserActionTypes.LoadSubUsers;
}

export class LoadSubUsersComplete implements Action {
    readonly type = SubUserActionTypes.LoadSubUsersComplete;

    constructor(public payload: any) { // SubUser[]
    }
}

export class LoadSubUsersFailed implements Action {
    readonly type = SubUserActionTypes.LoadSubUsersFailed;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class InitAuthTree implements Action {
    readonly type = SubUserActionTypes.InitAuthTree;
}

export class InitAuthTreeComplete implements Action {
    readonly type = SubUserActionTypes.InitAuthTreeComplete;

    constructor(public payload: any) { // SubUser[]
    }
}

export class InitAuthTreeFailed implements Action {
    readonly type = SubUserActionTypes.InitAuthTreeFailed;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class AddSubUser implements Action {
    readonly type = SubUserActionTypes.AddSubUser;

    constructor(public payload: SubUserModelRepository) {
    }
}

export class UpdateSubUser implements Action {
    readonly type = SubUserActionTypes.UpdateSubUser;

    constructor(public payload: SubUserModelRepository) {
    }
}

export class RemoveSubUser implements Action {
    readonly type = SubUserActionTypes.RemoveSubUser;

    constructor(public payload: number) {
    }
}

export type SubUserActions =
    LoadSubUsers |
    LoadSubUsersComplete |
    LoadSubUsersFailed |
    InitAuthTree |
    InitAuthTreeComplete |
    InitAuthTreeFailed |
    AddSubUser |
    UpdateSubUser |
    RemoveSubUser;

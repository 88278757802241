import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {BlobService} from '../../core/services/blob.service';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-notices',
    templateUrl: './notices.component.html',
    styleUrls: ['./notices.component.scss']
})
export class NoticesComponent implements OnInit {

    public notices = [];
    @Input() noticeLocation: string;

    constructor(
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private blobService: BlobService
    ) {
        this.getNotices();
    }

    ngOnInit(): void {
    }

    async getNotices() {
        const notices = JSON.parse(<string>await this.blobService.getData('notices.json', 'marketing-management'));
        const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'de_DE');
        for (const notice of notices) {
            if (notice.active === true) {
                if (!notice.start && !notice.end) {
                    this.notices.push(notice);
                } else if (!notices.end) {
                    const startDate = formatDate(notice.start, 'yyyy-MM-dd', 'de_DE');
                    if (currentDate >= startDate) {
                        this.notices.push(notice);
                    }
                } else {
                    const startDate = formatDate(notice.start, 'yyyy-MM-dd', 'de_DE');
                    const endDate = formatDate(notice.end, 'yyyy-MM-dd', 'de_DE');

                    if (currentDate >= startDate && currentDate <= endDate) {
                        this.notices.push(notice);
                    }
                }
            }

        }
    }

    showNotice(filter: string) {
        let route;
        if (this.noticeLocation !== 'login') {
            route = this.router.url.replace(/^\/|\/$/g, '');
        } else {
            route = this.activatedRoute.snapshot.routeConfig.path;
        }
        return !(filter !== null && filter !== route && !route.includes(filter));
    }

}


<div class="content container owner">
    <h2 mat-dialog-title>Ändern Sie Ihre persönlichen Angaben</h2>
    <div mat-dialog-content>
        <p>Hier haben Sie die Möglichkeit Änderungen Ihrer persönlichen Daten zu beantragen.</p>
        <spinner *ngIf="loading"></spinner>
        <div class="row panel-body">
            <div class="col-md-12">
                <edit-owner [data]="owner"
                            (loading)="onLoad($event)"
                            (cancel)="onClose()"
                            (success)="onSuccess()"
                            (fail)="onFail($event)">

                </edit-owner>
            </div>
        </div>
    </div>
</div>

<notification *ngIf="error && !(loading.asObservable() | async)" type="error" [message]="error.message"></notification>
<ng-container *ngIf="!(loading.asObservable() | async)">
    <div class="row">
        <div class="col-md-12">
            <p class="pt-2">
                Bitte legen Sie ein Passwort fest. Drei der folgenden Kriterien müssen erfüllt sein: <b>ein Klein-</b> oder <b>Großbuchstabe</b>,
                <b>eine Zahl</b> oder <b>ein Sonderzeichen</b>. Die minimale Passwortlänge beträgt 8 Zeichen.
            </p>
        </div>
    </div>
    <form [formGroup]="passwordForm" [class.submitted]="submitted">
        <div *ngIf="requiresPassword" class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Altes Passwort</mat-label>
                    <input matInput formControlName="oldPassword" data-cy="old-password-field"
                           [type]="hide ? 'password' : 'text'" autocomplete="off">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="oldPassword.hasError('required') && submitted">
                        Bitte geben Sie Ihr altes Passwort ein.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Neues Passwort</mat-label>
                    <input matInput formControlName="newPassword" data-cy="new-password-field"
                           [type]="hide ? 'password' : 'text'" autocomplete="off">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="newPassword.hasError('minlength') && submitted">
                            Ihr Passwort muss mindestens 8 Zeichen lang sein.
                        </mat-error>
                        <mat-error *ngIf="newPassword.hasError('required') && submitted">
                            Bitte geben Sie ein neues Passwort ein.
                        </mat-error>
                        <mat-error *ngIf="newPassword.hasError('weak') && submitted">
                            Ihr Passwort muss weitere der folgenden Kategorien enthalten:
                        </mat-error>
                        <mat-error *ngIf="newPassword.hasError('noLower') && submitted">
                            Kleinbuchstabe a-z
                        </mat-error>
                        <mat-error *ngIf="newPassword.hasError('noUpper') && submitted">
                            Großbuchstabe A-Z
                        </mat-error>
                        <mat-error *ngIf="newPassword.hasError('noNumber') && submitted">
                            Zahl 0-9
                        </mat-error>
                        <mat-error *ngIf="newPassword.hasError('noSpecialCharacter') && submitted">
                            Sonderzeichen ~!@#$%&*_-+=`|()&#123;&#125;[]:;"'<>,.?/^\
                        </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Passwort wiederholen</mat-label>
                    <input matInput formControlName="confirmPassword" data-cy="repeat-password-field"
                           [type]="hide ? 'password' : 'text'" autocomplete="off">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="confirmPassword.hasError('required') && submitted">
                            Bitte wiederholen Sie Ihr Passwort.
                        </mat-error>
                        <mat-error *ngIf="confirmPassword.hasError('mustMatch') && submitted">
                            Ihre Passwörter stimmen nicht überein.
                        </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <button *ngIf="showBackButton" type="button" data-cy="cancel-password-update" class="btn-secondary" (click)="onBack()">{{backButtonText}}</button>
            </div>
            <div class="col-md-7 text-right">
                <button class="btn-primary" data-cy="submit-password-change" (click)="onSubmit()">{{submitButtonText}}</button>
            </div>
        </div>
    </form>
</ng-container>

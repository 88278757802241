import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordRestService } from '../../../core/services/api/password.rest-service';
import { Router } from '@angular/router';
import { Response } from '../../../core/models/response.model';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'password-forgotten',
    templateUrl: './password-forgotten.component.html',
    styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit {
    public resetPasswordForm: FormGroup;
    public idf: FormControl = new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{2,7}$/)
    ]);
    public zipCode: FormControl = new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{5}$/)
    ]);
    public success: boolean = false;
    public response: Response;
    public submitted: boolean = false;
    public submitButtonClicked: boolean = false;
    public showErrorMessage = false;

    constructor(public formBuilder: FormBuilder,
        @Inject(PasswordRestService) public passwordRestService: PasswordRestService,
        public router: Router) {
        this.resetPasswordForm = formBuilder.group({
            idf: this.idf,
            zipCode: this.zipCode,
        });
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

    ngOnInit() {

    }

    submit(): void {
        this.submitButtonClicked = true; // show error class on checkboxes
        if (this.resetPasswordForm.valid) {
            this.submitted = true; // hide form and show spinner
            const request = this.resetPasswordForm.value;
            this.passwordRestService.initPasswordReset(request).subscribe(
                (response: Response) => {
                this.success = true;
                this.response = response;
            },
                err => {
                    // Do stuff whith your error
                    if (err.error && err.error.errorCode) {
                        this.submitted = false;
                        this.showErrorMessage = true;
                    }
                });
        }
    }

    isQaVisible() {
        return environment.qa;
    }

    // we have to reload component to see error on login page ...
    backToLogin() {
        window.location.href = window.location.origin;
    }
}

import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export enum DialogActionTypes {

    INIT_DIALOG = '[DIALOG POPUP] Init',
    LOAD_DIALOG = '[DIALOG POPUP] Load',
    LOAD_DIALOG_COMPLETED = '[DIALOG POPUP] Load Completed',
    LOAD_DIALOG_FAILED = '[DIALOG POPUP] Load Failed',
    INIT_SUBSCRIBE_DIALOG = '[DIALOG POPUP] Subscribe Init',
    SUBSCRIBE_DIALOG_COMPLETED = '[DIALOG POPUP] Subscribe Completed',
    SUBSCRIBE_DIALOG_FAILED = '[DIALOG POPUP] Subscribe Failed'
}


export class DialogInitAction implements Action {
    readonly type = DialogActionTypes.INIT_DIALOG;

    constructor(public payload: any) {
    }
}

export class DialogLoadAction implements Action {
    readonly type = DialogActionTypes.LOAD_DIALOG;

    constructor(public payload: any) {
    }
}

export class DialogLoadCompleteAction implements Action {
    readonly type = DialogActionTypes.LOAD_DIALOG_COMPLETED;

    constructor(public payload: any) {
    }
}

export class DialogLoadFailAction implements Action {
    readonly type = DialogActionTypes.LOAD_DIALOG_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}


export class SubscribeDialogInitAction implements Action {
    readonly type = DialogActionTypes.INIT_SUBSCRIBE_DIALOG;

    constructor(public payload: any) {
    }
}

export class SubscribeDialogCompleteAction implements Action {
    readonly type = DialogActionTypes.SUBSCRIBE_DIALOG_COMPLETED;

    constructor(public payload: any) {
    }
}

export class SubscribeDialogFailAction implements Action {
    readonly type = DialogActionTypes.SUBSCRIBE_DIALOG_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}


export type DialogActions =
    DialogInitAction |
    DialogLoadAction |
    DialogLoadCompleteAction |
    DialogLoadFailAction |
    SubscribeDialogInitAction |
    SubscribeDialogFailAction |
    SubscribeDialogCompleteAction;

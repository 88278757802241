import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {UserService} from '../../../../../core/services/user.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-datev',
  templateUrl: './datev.component.html',
  styleUrls: ['./datev.component.scss']
})
export class DatevComponent implements OnInit, OnDestroy {
  public input = false;
  public loading = false;
  public getIdf: Subscription;
  public selectedIdf;
  public mainIdf;
  public idfs$: Observable<any[]>;

  // userForm = this.fb.group({
  //   idfInput: ['']
  // });
  constructor(@Inject(UserService) public userService: UserService,
              private fb: FormBuilder) {
    // this.idfs$ = this.userService.selectContextualizedIdfsObservable('orders');
    this.input = userService.isPhoenixUser();

    this.getIdf = this.userService.selectedIdf$.subscribe(idf => {
          this.loading = true;

          if (idf) {
            this.mainIdf = idf.idf;
            this.selectedIdf = idf.idf;
            if (sessionStorage.getItem('idf') !== null) {
              this.selectedIdf = sessionStorage.getItem('idf');
            }

            // this.userForm.get('idfInput').setValue(this.selectedIdf);
            this.loading = false;
          }
        });
  }
  // setIdf(event) {
  //   this.selectedIdf = event.value;
  // }
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.getIdf.unsubscribe();
  }
}

export class Branch {
    id: number;
    location: string;
    region: string;

    constructor(obj: any) {
        this.id = obj && obj.id || null;
        this.location = obj && obj.location || null;
        this.region = obj && obj.region || null;
    }
}

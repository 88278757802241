<div class="row" *ngIf="noticeLocation !== 'login'">
    <div class="col-md-12 notice" *ngFor="let notice of notices">
        <mat-card class="mat-card {{notice.type}} mb-xxl" *ngIf="showNotice(notice.filter)">
            <mat-card-content>
                <div class="row" *ngIf="notice.type !== 'xmass'">
                    <div class="col-md-1 text-center my-auto h2 pb-0">
                        <span [className]="(notice.type == 'warn') ? 'far fa-exclamation-circle' : 'far fa-info-circle'"></span>
                    </div>
                    <div class="col-md-10" [innerHTML]="notice.text"></div>
                </div>
                <div class="d-flex align-items-center" *ngIf="notice.type === 'xmass'">
                    <img src="../../assets/img/icons/gift-icon.svg" alt="Geschenk ikon" width="100px">
                    <p [innerHTML]="notice.text"></p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div *ngIf="noticeLocation === 'login'">
    <div *ngFor="let notice of notices">
        <mat-card class="mb-l no-shadow" *ngIf="showNotice(notice.filter)">
            <mat-card-content>
                <div class="row panel justify-content-center">
                    <div class="col-md-1 text-center">
                        <i [className]="(notice.type == 'warn') ? 'fal fa-5x fa-exclamation-circle warn' : 'fal fa-5x fa-info-circle info'"></i>
                    </div>
                    <div class="col-md-11">
                        <div class="{{notice.type}}" [innerHTML]="notice.text"></div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>


import {Observable, Subject} from 'rxjs';
import {Inject, Injectable, OnDestroy} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UrlService implements OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();
    public lastUrl = null;

    constructor() {
    }


    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
import {AccountDataComponent} from "./account-data.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {ChangePasswordDialogComponent} from './change-password/change-password-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {PasswordFormModule} from '../../../shared/password-form/password-form.module';
import {NotificationModule} from '../../../shared/notification/notification.module';
import {TranslateModule} from '@ngx-translate/core';
import {SpinnerModule} from '../../../shared/spinner/spinner.module';
import {ManageAccountDialogComponent} from './manage-account/manage-account-dialog.component';
import {EditDataModule} from '../../../shared/edit-data/edit-data.module';
import {ManageOwnerDialogComponent} from './manage-owner/manage-owner-dialog.component';
import {EditOwnerComponent} from './manage-owner/edit-owner/edit-owner.component';


export const routes = [
    {path: '', component: AccountDataComponent, pathMatch: 'full'}
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        PasswordFormModule,
        NotificationModule,
        TranslateModule,
        SpinnerModule,
        EditDataModule
    ],
    declarations: [
        AccountDataComponent,
        ChangePasswordDialogComponent,
        ManageAccountDialogComponent,
        ManageOwnerDialogComponent,
        EditOwnerComponent
    ],
    entryComponents: [
        ChangePasswordDialogComponent,
        ManageAccountDialogComponent,
        ManageOwnerDialogComponent
    ],
    exports: [
        AccountDataComponent
    ]
})
export class AccountDataModule {
    static routes = routes;
}
import {Injectable, OnDestroy} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {Response} from '../../../models/response.model';


@Injectable({
    providedIn: 'root'
})
export class MessageRestService extends RestServiceAbstract implements OnDestroy {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public initMessages() {
        return this.get(`/usermanagement/messages`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public loadMessages(rq) {

        return this.get(`/usermanagement/messages/list`, {params: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public loadMessage(rq) {
        return this.post(`/usermanagement/messages/readMessage`, {id: rq.payload}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public deleteMessages(rq) {
        return this.delete(`/usermanagement/messages/delete`, {params: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

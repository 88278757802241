import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export enum ReklamationActionTypes {
    GET_REKLAMATION_LIST = '[REKLAMATION] Get Reklamation List',
    GET_REKLAMATION_LIST_COMPLETE = '[REKLAMATION] Get Reklamation List Complete',
    GET_REKLAMATION_LIST_FAIL = '[REKLAMATION] Get Reklamation List failed'
}

export class GetReklamationListAction implements Action {
    readonly type = ReklamationActionTypes.GET_REKLAMATION_LIST;

    constructor(public payload: any) {

    }
}

export class GetReklamationListCompleteAction implements Action {
    readonly type = ReklamationActionTypes.GET_REKLAMATION_LIST_COMPLETE;

    constructor(public payload: any) {
    }
}

export class GetReklamationListFailAction implements Action {
    readonly type = ReklamationActionTypes.GET_REKLAMATION_LIST_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type ReklamationActions =
    GetReklamationListAction |
    GetReklamationListCompleteAction |
    GetReklamationListFailAction;

import {OwnerData} from "./owner-data.model";
import {AccountData} from "./account-data.model";

export class FullAccountInformation {
    owner: OwnerData;
    accounts: AccountData[] = [];

    constructor(obj: any = []) {
        if (obj && obj instanceof Array) {
            for (let acc of obj) {
                if (!this.owner) {
                    this.owner = new OwnerData(acc.owner);
                }
                this.accounts.push(new AccountData(acc))
            }
        }
    }
}
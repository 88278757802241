import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../core/models/user.model';
import { Nav } from '../../core/models/nav.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {MatomoService} from "../../core/services/matomo.service";
import {AuthService} from "../../core/services/api/auth.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();
    public user: User;
    public nav: Nav;
    public activeHeading: string | boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(UserService) private userService: UserService,
        @Inject(AuthService) public authService: AuthService,
        public matomoService: MatomoService
    ) {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.determineHeading();
            }
        });
        this.userService.getNavObservable().subscribe(nav => {
            this.nav = nav;
            this.determineHeading();
        });
        this.user = this.userService.getUser();
    }

    ngOnInit(): void {
    }

    determineHeading() {
        const route = this.route.snapshot;
        if (route.children.length > 0) {
            const changeHeading = this.nav && this.nav.getProperty(route.children[0].routeConfig.path, route.queryParamMap.get('url'));
            if (route.queryParamMap.has('heading')) {
                this.activeHeading = route.queryParamMap.get('heading');
            } else if (route.children[0].routeConfig.path.indexOf('static-content') > -1 && changeHeading) {
                this.activeHeading = changeHeading;
            } else if (route.children[0].routeConfig.path.indexOf('static-content') === -1) {
                this.activeHeading = changeHeading;
            }
        }
    }

    logout() {
        this.authService.logout();
        this.trackingEvent('logout');
        this.router.navigateByUrl('logout');
    }

    trackingEvent(name) {
        this.matomoService.trackEvent('FEATUREBEZOGENE KPI', 'Klick auf Quick Navigation', name);
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    protected readonly sessionStorage = sessionStorage;

    protected readonly TrackEvent = TrackEvent;

    isOwner() {
        if(this.user) {
            return this.userService.isOwner();
        }
        return false;
    }
}

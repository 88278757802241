import {Action} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {ProductRequest} from "../../models/shop/product-request.model";

export enum ProFaxActionTypes {
    LOAD_PROFAX = '[PROFAX] Load Profax',
    LOAD_PROFAX_COMPLETE = '[PROFAX] Load Profax Complete',
    LOAD_PROFAX_FAIL = '[PROFAX] Load Profax FAIL',
    SEARCH_PROFAX = '[PROFAX] Search',
    UPDATE_SCROLLING_POSITION = '[PROFAX] Update Scrolling Position',
    GET_BATCH_FROM_CACHE = '[PROFAX] Push batch in visible list',
    UPDATE_PROFAX_CART = '[PROFAX] Update Cart',
    EMPTY_PROFAX_CART = '[PROFAX] Empty Profax Cart'
}

export class LoadProFaxAction implements Action {
    readonly type = ProFaxActionTypes.LOAD_PROFAX;

    constructor(public payload: ProductRequest) { // == Query
    }
}

export class LoadProFaxCompleteAction implements Action {
    readonly type = ProFaxActionTypes.LOAD_PROFAX_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }
}

export class LoadProFaxFailAction implements Action {
    readonly type = ProFaxActionTypes.LOAD_PROFAX_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class SearchShopAction implements Action {
    readonly type = ProFaxActionTypes.SEARCH_PROFAX;

    constructor(public payload: ProductRequest) {};
}

export class UpdateScrollingPositionAction implements Action {
    readonly type = ProFaxActionTypes.UPDATE_SCROLLING_POSITION;

    constructor(public payload: number) {};
}

export class GetBatchFromCacheAction implements Action {
    readonly type = ProFaxActionTypes.GET_BATCH_FROM_CACHE;

    constructor() {};
}

export class UpdateProfaxCart implements Action {
    readonly type = ProFaxActionTypes.UPDATE_PROFAX_CART;

    constructor(public payload: any) {}
}

export class EmptyProfaxCart implements Action {
    readonly type = ProFaxActionTypes.EMPTY_PROFAX_CART;

    constructor(public payload: string) {}
}


export type ProFaxActions =
    LoadProFaxAction |
    LoadProFaxCompleteAction |
    LoadProFaxFailAction |
    SearchShopAction |
    UpdateScrollingPositionAction |
    GetBatchFromCacheAction |
    UpdateProfaxCart |
    EmptyProfaxCart;
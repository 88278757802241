import {HttpErrorResponse} from '@angular/common/http';
import {SubsequentDeliveriesActions, SubsequentDeliveriesActionsType} from '../actions/subsequent-deliveries.actions';
import {SubsequentDeliveryModel} from '../../models/subsequent-deliveries/subsequent.delivery.model';
import {Product} from '../../models/shop/product.model';
import {ProductRequest} from '../../models/shop/product-request.model';
import {ProductsResponse} from '../../models/shop/products-response.model';

export interface State {
    subsequentDeliveriesResponse: any;
    subsequentDeliveriesRequest: any;
    subsequentDeliveriesUpdateResponse: SubsequentDeliveryModel;
    subsequentDeliveriesDeleteResponse: any;
    subsequentDeliveriesErrorResponse: HttpErrorResponse;
    loading: boolean;
    products: Product[];
    productsLoading: boolean;
    productsRequest: ProductRequest;
    productsResponse: ProductsResponse;
    productsErrorResponse: HttpErrorResponse;
    addSubsequentDeliveryLoading: boolean;
    addSubsequentDeliveryErrorResponse: HttpErrorResponse;
}

export const initState: State = {
    subsequentDeliveriesResponse: null,
    subsequentDeliveriesRequest: null,
    subsequentDeliveriesUpdateResponse: null,
    subsequentDeliveriesDeleteResponse: null,
    subsequentDeliveriesErrorResponse: null,
    loading: false,
    products: [],
    productsLoading: false,
    productsRequest: null,
    productsResponse: null,
    productsErrorResponse: null,
    addSubsequentDeliveryLoading: false,
    addSubsequentDeliveryErrorResponse: null
};

export function reducer(state = initState, action: SubsequentDeliveriesActions){
    switch (action.type) {
        case SubsequentDeliveriesActionsType.INIT_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                loading: true
            };
        case SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesRequest: action.payload,
                loading: true
            };
        case SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES_COMPLETED:
            return {
                ...state,
                subsequentDeliveriesResponse: action.payload,
                loading: false
            };
        case SubsequentDeliveriesActionsType.UPDATE_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesUpdateResponse: action.payload,
                loading: false
            };
        case SubsequentDeliveriesActionsType.DELETE_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesDeleteResponse: action.payload,
                loading: false
            };
        case SubsequentDeliveriesActionsType.FAIL_SUBSEQUENT_DELIVERIES:
            return {
                ...state,
                subsequentDeliveriesErrorResponse: action.payload,
                loading: false
            };
        case SubsequentDeliveriesActionsType.SEARCH_PRODUCT:
            return {
                ...state,
                products: [],
                productsLoading: true
            };
        case SubsequentDeliveriesActionsType.LOAD_PRODUCTS:
            return {
                ...state,
                productsRequest: action.payload,
            };
        case SubsequentDeliveriesActionsType.LOAD_PRODUCTS_COMPLETE:
            return {
                ...state,
                products: action.payload.products.slice(0, 10), // we only show 10 results
                productsResponse: action.payload,
                productsLoading: false
            };
        case SubsequentDeliveriesActionsType.LOAD_PRODUCTS_FAILED:
            return {
                ...state,
                productsErrorResponse: action.payload,
                productsLoading: false
            };
        case SubsequentDeliveriesActionsType.RESET_SEARCH_RESULT:
            return {
                ...state,
                products : [],
                productsResponse : null
            };
        case SubsequentDeliveriesActionsType.ADD_SUBSEQUENT_DELIVERY:
            return {
                ...state,
                addSubsequentDeliveryLoading: true,
                addSubsequentDeliveryErrorResponse: null
            };
        case SubsequentDeliveriesActionsType.ADD_SUBSEQUENT_DELIVERY_COMPLETE:
            return {
                ...state,
                addSubsequentDeliveryLoading: false,
            };
        case SubsequentDeliveriesActionsType.ADD_SUBSEQUENT_DELIVERY_FAILED:
            return {
                ...state,
                addSubsequentDeliveryLoading: false,
                addSubsequentDeliveryErrorResponse: action.payload
            };
        default:
            return state;
    }
}

export const getSubsequentDeliveriesResponse = (state: State) => state.subsequentDeliveriesResponse;
export const getSubsequentDeliveriesRequest = (state: State) => state.subsequentDeliveriesRequest;
export const getSubsequentDeliveriesUpdateResponse = (state: State) => state.subsequentDeliveriesUpdateResponse;
export const getSubsequentDeliveriesDeleteResponse = (state: State) => state.subsequentDeliveriesDeleteResponse;
export const getSubsequentDeliveriesErrorResponse = (state: State) => state.subsequentDeliveriesErrorResponse;
export const getSubsequentDeliveriesLoading = (state: State) => state.loading;
export const getProductsLoading = (state: State) => state.productsLoading;
export const getProductsResponse = (state: State) => state.productsResponse;
export const getProductsRequest = (state: State) => state.productsRequest;
export const getProductsErrorResponse = (state: State) => state.productsErrorResponse;
export const getProducts = (state: State) => state.products;
export const getAddSubsequentDeliveryLoading = (state: State) => state.addSubsequentDeliveryLoading;
export const getAddSubsequentDeliveryErrorResponse = (state: State) => state.addSubsequentDeliveryErrorResponse;


<div (focusin)="activate.emit(product.id)" (focusout)="deactivate.emit(product.id)" class="cart-provider" *ngIf="product">
    <form [formGroup]="addToCartForm">
        <!-- Packungsgröße -->
        <ng-container *ngIf="isMultiplePackages">
            <h4 class="my-2">Packungsgröße</h4>
            <div [ngClass]="packageClass">
                <span class="packBox text-center"
                      [class.selectedPackage]="product.id === pack.pzn"
                      [class.notAvailable]="!pack.available"
                      *ngFor="let pack of sortPackage(product.packageProducts)"
                      (click)="selectPackage(pack)"
                >
                    {{ pack.amountText }}
                </span>
            </div>
        </ng-container>

        <!-- Quantity & Availability -->
        <div class="quantity" [class.active]="active && product.retailPrice !== 0">
            <div class="buttons" [ngStyle]="buttonPositionStyle" *ngIf="isAvailable">
                <button type="button" class="btn-minus" (click)="decreaseQuantity()"><i class="fal fa-minus"></i></button>
                <button type="button" class="btn-plus" (click)="increaseQuantity()"><i class="fal fa-plus"></i></button>
            </div>
            <div class="input" [ngClass]="inputClass">
                <input formControlName="quantityInput" type="number" min="1" max="999" step="1" value="{{ quantity }}"
                       (blur)="updateQuantity($event)" (change)="updateQuantity($event)" data-cy="add-to-cart-quantity-input">
            </div>
            <div class="availability" [ngClass]="availabilityClass">
                {{ availabilityText }}
                <i class="fal {{ availabilityIconClass }} float-right p-1"></i>
            </div>
        </div>

        <!-- Add to Cart Buttons -->
        <button *ngIf="canAddToCart" class="btn-primary add-to-cart-button d-inline-block" type="submit" (click)="addToCart()"
                attr.data-cy="add-to-cart-{{ product.id }}">In den Warenkorb</button>

        <button *ngIf="isSubsequentOrder" class="btn-yellow add-to-cart-button d-inline-block" type="submit" (click)="openSubsequentDeliveryModal(product)"
                attr.data-cy="add-to-cart-{{ product.id }}">Nachlieferung</button>

        <button *ngIf="isNotAvailable" [disabled]="isDisabled" class="btn-primary inactive add-to-cart-button d-inline-block"
                attr.data-cy="add-to-cart-{{ product.id }}">In den Warenkorb</button>
    </form>
</div>

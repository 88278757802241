<h1 mat-dialog-title class="pb-0 survey-title text-center" [innerHTML]="data.title"></h1>
<div mat-dialog-content class="text-center">
    <div *ngFor="let part of parsedBody">
        <div [innerHTML]="part.data" *ngIf="part.type == 'string'" class="mb-m"></div>
        <app-forms [form]="part.data" *ngIf="part.type=='form'" (success)="onSuccess($event)"
                   [rating]="true"></app-forms>
    </div>
</div>
<div mat-dialog-actions class="text-center my-3 " *ngIf="data.button">
    <div class="d-flex justify-content-center align-items-center w-100" *ngIf="sent">
        <button class="btn btn-primary mt-0" mat-button [mat-dialog-close]="true"
                (click)="onSubscribe(data.title)">{{data.button}}</button>
    </div>
</div>
<div mat-dialog-actions class="my-0 " *ngIf="!sent">
    <div class="d-flex justify-content-end align-items-end w-100">
        <a class="text-s close-link" [mat-dialog-close]="true">Schließen</a>
    </div>
</div>

import {Injectable} from '@angular/core';
import {RestServiceAbstract} from "../../abstracts/rest-service.abstract";
import {map, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {RegistrationAttemptRequest} from "../../models/registration/registration-attempt-request.model";
import {Response} from "../../models/response.model";
import {SetCredentialsTokenRequest} from "../../models/registration/set-credentials-token-request.model";
import {ICredentialsService} from "../../interfaces/credentials-service.interface";
import {IChangeDataService} from '../../interfaces/change-data-service.interface';

@Injectable({
    providedIn: 'root'
})
export class RegistrationRestService extends RestServiceAbstract implements ICredentialsService, IChangeDataService {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public initRegistrationAttempt(irar: RegistrationAttemptRequest): Observable<Response> {
        return this.post(`/usermanagement/registration/init`, irar).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public setCredentials(scr: SetCredentialsTokenRequest): Observable<Response> {
        return this.post(`/usermanagement/registration/setCredentials`, scr).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public confirm(token: string): Observable<Response> {
        return this.get(`/usermanagement/registration/confirm`, {params: {token: token}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public changeData(data: any): Observable<Response> {
        return this.post(`/usermanagement/registration/changeData`, data).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public signUp(data: any): Observable<Response> {
        return this.post(`/usermanagement/registration/signup`, data).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }
}

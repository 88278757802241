import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {VideoDataInterface} from '../../../core/interfaces/video-data-interface';


@Component({
  selector: 'video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.css']
})
export class VideoDialogComponent implements OnInit {
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: VideoDataInterface,
  ) {
  }

  ngOnInit() {
  }

}

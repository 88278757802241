<div id="content">
    <div class="panel">
        Copy me if you need a new panel!
    </div>
</div>

<div class="content">
    <div class="panel">
        <h2>Buttons & Icons</h2>
        <a [routerLink]="['/dashboard']">
            <button class="btn-primary" type="button">
                Link Button Primary
            </button>
        </a>
        <a [routerLink]="['/dashboard']">
            <button class="btn-secondary" type="button">
                Link Button Secondary
            </button>
        </a>
        <br/>
        <a>
            <button class="btn-primary inactive" type="button">
                Link Button Primary inactive
            </button>
        </a>
        <a>
            <button class="btn-secondary inactive" type="button">
                Link Button Secondary inactive
            </button>
        </a>
        <br/>
        <button class="btn-primary btn-round" mat-button matTooltip="btn-primary btn-round with icon">
            <i class="far fa-circle"></i>
        </button>

        <button class="btn-secondary btn-round" mat-button matTooltip="btn-secondary btn-round with icon">
            <i class="fal fa-triangle"></i>
        </button>
        <br/>
        <button class="btn-icon" mat-button matTooltip="btn-icon">
            <i class="fal fa-trash"></i>
        </button>
    </div>
</div>

<div class="content">
    <div class="panel">
        <div class="row">
            <div class="col-md-6">
                <cart-provider [product]="productMockAvailable"></cart-provider>
            </div>
            <div class="col-md-6">
                <cart-provider [product]="productMockUnavailable"></cart-provider>
            </div>
        </div>
    </div>
</div>

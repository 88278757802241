import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subject, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';

@Injectable({
  providedIn: 'root',
})
export class ReklamationRestService extends RestServiceAbstract {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  public loadReklamationList(idf: string) {
    return this.get(`/resource/order-entry/${idf}`).pipe(
        takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new Response(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
    );
  }


  public hasReclamations(idf: string) {
    return this.get(`/resource/order-entry/check/${idf}`).pipe(
        takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new Response(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
    );
  }

}

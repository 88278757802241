import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map} from 'rxjs/operators';
import {Response} from '../../models/response.model';

@Injectable({
    providedIn: 'root'
})
export class ChatbotRestService extends RestServiceAbstract {

    getChatbotToken(){
        return this.get('/usermanagement/chatbot').pipe(map(data => {
            return new Response(data);
        }));
    }
}
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as fromReducers from '../store/reducers';
import {select, Store} from '@ngrx/store';
import {RetourenAnnouncementAction, RetourenRequestAction} from '../store/actions/retouren.actions';

@Injectable({
    providedIn: 'root',
})

export class RetourenService implements OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();

    constructor(
        public store: Store<fromReducers.State>) {
    }

    // RETURN REQUEST =======
    loadReturnRequest(rq) {
        this.store.dispatch(new RetourenRequestAction(rq));
    }

    getReturnRequestResponseObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getRetourenResponse));
    }

    getReturnRequestLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getRetourenLoading));
    }

    getReturnRequestErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getRetourenError));
    }

    // RETURN ANNOUNCEMENT =======
    loadReturnAnnouncement(rq): Observable<any> {
        this.store.dispatch(new RetourenAnnouncementAction(rq));
        return this.getReturnAnnouncementResponseObservable();
    }

    getReturnAnnouncementResponseObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getRetourenAnnouncementResponse));
    }

    getReturnAnnouncementLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getRetourenAnnouncementLoading));
    }

    getReturnAnnouncementErrorObservable() {
        return this.store.pipe(select(fromReducers.getRetourenAnnouncementError));
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}


import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs/internal/Observable';
import {WebshopRestService} from '../../services/api/webshop-rest.service';
import * as fromUberActions from '../actions/uber.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class UberEffects {

    constructor(
        private productRestService: WebshopRestService,
        private actions$: Actions
    ) {
    }

    @Effect()
    loadUberByVendorId$: Observable<any> = this.actions$
        .pipe(ofType<fromUberActions.UberLoadAction>(fromUberActions.UberActionTypes.UBER_LOAD))
        .pipe(map(action => action.payload), switchMap((rq) => {
            return this.productRestService.loadUberByVendorId(rq).pipe(
                switchMap(data => {
                    const resp = new ProductsResponse({...data.returnObject});
                    return [new fromUberActions.UberLoadCompleteAction(resp)];
                }),
                catchError((error) => of(new fromUberActions.UberLoadFailAction(error)))
            );
        }));

    @Effect()
    loadUberVendors$: Observable<Action> = this.actions$
        .pipe(ofType<fromUberActions.UberLoadVendorsAction>(fromUberActions.UberActionTypes.UBER_LOAD_VENDORS))
        .pipe(map(action => action), switchMap(() =>
                this.productRestService.loadUberVendors().pipe(
                    map(data => new fromUberActions.UberLoadVendorsCompleteAction(data.returnObject)),
                    catchError((error) => of(new fromUberActions.UberLoadVendorsFailAction(error)))
                )
            )
        );
}

import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductRequest} from '../../models/shop/product-request.model';

export enum UberActionTypes {
    UBER_LOAD = '[UBER] Loading',
    UBER_LOAD_COMPLETE = '[UBER] Loading Complete',
    UBER_LOAD_FAIL = '[UBER] Loading Failed',
    UBER_SEARCH = '[UBER] Search',
    UBER_RESET = '[UBER] reset products',
    UBER_SCROLL_POSITION = '[UBER] Update Scroll Position',
    UBER_BATCH_FROM_CACHE = '[UBER] Push batch in visible list from cache',
    UBER_UPDATE_CART = '[UBER] Update cart',
    UBER_EMPTY_CART = '[UBER] Empty cart',
    UBER_LOAD_VENDORS = '[UBER] Load Vendors',
    UBER_LOAD_VENDORS_COMPLETE = '[UBER] Load Vendors Complete',
    UBER_LOAD_VENDORS_FAIL = '[UBER] Load Vendors Fail',
}

export class UberLoadAction implements Action {
    readonly type = UberActionTypes.UBER_LOAD;

    constructor(public payload: any) {
    }
}

export class UberLoadCompleteAction implements Action {
    readonly type = UberActionTypes.UBER_LOAD_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }

}

export class UberLoadFailAction implements Action {
    readonly type = UberActionTypes.UBER_LOAD_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class UberLoadVendorsAction implements Action {
    readonly type = UberActionTypes.UBER_LOAD_VENDORS;

    constructor() {
    }
}

export class UberLoadVendorsCompleteAction implements Action {
    readonly type = UberActionTypes.UBER_LOAD_VENDORS_COMPLETE;

    constructor(public payload: any[]) {
    }

}

export class UberLoadVendorsFailAction implements Action {
    readonly type = UberActionTypes.UBER_LOAD_VENDORS_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class UberSearchAction implements Action {
    readonly type = UberActionTypes.UBER_SEARCH;

    constructor(public payload: ProductRequest) {
    }
}

export class UberUpdateScrollPositionAction implements Action {
    readonly type = UberActionTypes.UBER_SCROLL_POSITION;

    constructor(public payload: number) {
    }
}

export class UberBatchFromCache implements Action {
    readonly type = UberActionTypes.UBER_BATCH_FROM_CACHE;

    constructor() {
    }
}

export class UberUpdateCart implements Action {
    readonly type = UberActionTypes.UBER_UPDATE_CART;

    constructor(public payload: any) {
    }
}

export class UberEmptyCart implements Action {
    readonly type = UberActionTypes.UBER_EMPTY_CART;

    constructor(public payload: string) {
    }
}

export class UberReset implements Action {
    readonly type = UberActionTypes.UBER_RESET;

    constructor() {
    }
}

export type UberActions =
    UberLoadAction |
    UberLoadCompleteAction |
    UberLoadFailAction |
    UberLoadVendorsAction |
    UberLoadVendorsCompleteAction |
    UberLoadVendorsFailAction |
    UberSearchAction |
    UberUpdateScrollPositionAction |
    UberBatchFromCache |
    UberUpdateCart |
    UberEmptyCart |
    UberReset;

<h2 class="pb-s">
  Wissen
</h2>
<p>
  Zur Unterstützung in Ihrem stressigen Apothekenalltag, finden Sie auf dieser Seite alle relevanten Informationen zur Kooperation, speziell für Sie und Ihr Apothekenteam aufbereitet.
</p>

<h2 class="mt-l">Was, wer, wie, wo? Das Beauftragtenprogramm!</h2>

<div class="text-center mt-l screen-background">
  <video controls class="embed-responsive-item" width="400">
    <source src="https://cspappsacc.blob.core.windows.net/video/Video_Rueckblick+Ausblick.mp4"
            type="video/mp4">
    Ihr Browser unterstützt leider keine eingebetteten Videos.
  </video>
</div>

<h2 class="mt-l pb-s">Snackable Content!</h2>
<p>
  Sehen Sie sich unsere Schulungen und Webinare zu einem für Sie passenden Zeitpunkt an und bereichern Sie Ihren Arbeitsalltag mit wertvollen Inhalten. Nutzen Sie die Gelegenheit, die herunterladbaren Informationsmaterialien mit Ihrem Team zu teilen, damit auch Ihre Kolleginnen und Kollegen von Ihrer Teilnahme am Beauftragtenprogramm profitieren.
</p>

<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 snackable-cards mt-l">
  <div class="col mb-4">
    <div class="card h-100">
      <div class="card-body p-0">
        <a class="linki" (click)="openDialog('pkp1')">Aufzeichnung: Webinar Cross-Selling</a>
      </div>
      <img src="https://cspappsacc.blob.core.windows.net/cms/images/cross-selling-webinar-ss.png"
           class="cursorPointer card-img-bottom"
           alt="Aufzeichnung: Webinar Cross-Selling" (click)="openDialog('pkp1')">
    </div>
  </div>
  <div class="col mb-4">
    <div class="card h-100">
      <div class="card-body p-0">
        <a class="linki" (click)="openDialog('pkp2')">Erklärung und Zusammenfassung des Programms</a>
      </div>
      <img src="https://cspappsacc.blob.core.windows.net/cms/images/erklaerung-ss.png"
           class="cursorPointer card-img-bottom"
           alt="Erklärung und Zusammenfassung des Programms" (click)="openDialog('pkp2')">
    </div>
  </div>
</div>
<div class="row mt-l">
    <div class="col-md-2">
        <a href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_Kommunikation_im_Team.pdf" target="_blank">
            <img src="https://cspappsacc.blob.core.windows.net/cms/images/2024/5_Tipps_Kommunikation_im_Team.JPG" class="img-border" alt="5 Tipps für bessere Kommunikation im Team">
        </a>
    </div>
    <div class="col-md-6 align-items-center d-flex">
        <a href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_Kommunikation_im_Team.pdf" target="_blank">
            5 Tipps für bessere Kommunikation im Team</a>&nbsp; (358 KB)
    </div>
    <div class="col-md align-items-center d-flex">
        <a class="download-button btn btn-primary btn-round"
           href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_Kommunikation_im_Team.pdf" target="_blank">
            <span class="arrow-helper far fa-arrow-down"></span>
        </a>
    </div>
</div>

<div class="row mt-l">
  <div class="col-md-2">
    <a href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_herausfordernde_Kunden.pdf" target="_blank">
      <img src="https://cspappsacc.blob.core.windows.net/cms/images/2024/5_Tipps_herausfordernde_Kunden.jpg" class="img-border">
    </a>
  </div>
  <div class="col-md-6 align-items-center d-flex">
    <a href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_herausfordernde_Kunden.pdf" target="_blank">
      5 Tipps zum Umgang mit (herausfordernden) Kunden</a>&nbsp; (302 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_herausfordernde_Kunden.pdf" target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>

<div class="row mt-l">
  <div class="col-md-2">
    <a href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_Stress-und-Druck-im-Job.pdf" target="_blank">
      <img src="https://cspappsacc.blob.core.windows.net/cms/images/2024/5_Tipps_Stress-und-Druck-im-Job.jpg" class="img-border">
    </a>
  </div>
  <div class="col-md-6 align-items-center d-flex">
    <a href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_Stress-und-Druck-im-Job.pdf" target="_blank">
      5 Tipps gegen Stress & Druck im Job</a>&nbsp; (274 KB)
  </div>
  <div class="col-md align-items-center d-flex">
    <a class="download-button btn btn-primary btn-round"
       href="https://cspappsacc.blob.core.windows.net/cms/files/2024/5_Tipps_Stress-und-Druck-im-Job.pdf" target="_blank">
      <span class="arrow-helper far fa-arrow-down"></span>
    </a>
  </div>
</div>

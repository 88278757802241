<div [ngSwitch]="wizardPos" class="app-wizard" *ngIf="showWizard" [ngClass]="{'fixed-top':wizardPos>1}">
    <div class="public-panel">
        <div class="text-center p-m p-lg-4 d-lg-flex justify-content-center align-items-center" *ngSwitchCase="1">
            <span class="wizard-text d-block d-lg-inline">Benötigen Sie Hilfe beim Login?</span>
            <button class="btn btn-sm mt-0 btn-primary ml-s on-dark-bg" (click)="appWizard(10);mtmEvent('Assistent starten')">Assistent starten</button>
        </div>
        <div class="py-l" *ngSwitchCase="10">
            <div class="text-right pb-l">
                <a (click)="closeWizard()">Schließen <i class="far fa-times"></i></a>
            </div>
            <div class="row no-gutters">
                <div class="col-12 col-md-6">
                    <div>
                        <p class="wizard-text">Ihre eingegebenen Logindaten sind nicht gültig. Bitte versuchen Sie es erneut.</p>
                        <p class="wizard-text">Falls das Problem weiter besteht, gehen Sie zum nächsten Schritt.</p>
                        <button class="btn-primary w-auto on-dark-bg mb-s" (click)="appWizard(11)">Weiter</button>
                    </div>
                </div>
                <div class="col-12 col-md-5 offset-md-1">
                    <div class="embed-responsive embed-responsive-16by9">
                        <video controls class="embed-responsive-item">
                            <source src="https://cms.phoenix-apothekenportal.de/fileadmin/statische-inhalte/videos/04_Login.mp4"
                                    type="video/mp4">
                            Ihr Browser unterstützt leider keine eingebetteten Videos.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-l" *ngSwitchCase="11">
            <div class="d-flex justify-content-between pb-l">
                <a (click)="appWizard(10)" *ngIf="!funk"><i class="far fa-angle-left pl-0"></i> Zurück</a>
                <a (click)="funk = null" *ngIf="funk"><i class="far fa-angle-left pl-0"></i> Zurück</a>
                <a (click)="closeWizard()">Schließen <i class="far fa-times"></i></a>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 px-0 pr-md-3" *ngIf="!funk">
                    <p class="wizard-text">In welcher Funktion möchten Sie das Apothekenportal nutzen?</p>
                    <ul class="list-unstyled usertype">
                        <li class="mb-xs"><a (click)="funk = 'Inhaber'; wizardPos" class="wizard-text"><i
                                class="fas fa-circle"></i>
                            Inhaber</a></li>
                        <li class="mb-xs"><a (click)="funk = 'Mitarbeiter der Hauptapotheke'" class="wizard-text"><i
                                class="fas fa-circle"></i> Mitarbeiter der Hauptapotheke</a></li>
                        <li class="mb-xs"><a (click)="funk = 'Filialapotheke'" class="wizard-text"><i
                                class="fas fa-circle"></i>
                            Filialapotheke</a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-6 px-0 pr-md-3" *ngIf="funk">
                    <span class="wizard-text">Als {{ funk }} </span>
                    <span *ngIf="funk == 'Inhaber'">
                        <span class="wizard-text">verwenden Sie die Funktion "Zugangsdaten vergessen" und geben Sie IDF
                            und
                            PLZ der Hauptapotheke ein.</span>
                        <div>
                            <button class="btn-primary on-dark-bg mb-s" routerLink="/zugangsdaten-vergessen">Zugangsdaten
                            vergessen
                        </button>
                        </div>
                    </span>
                    <span *ngIf="funk != 'Inhaber'">
                        <span class="wizard-text">fordern Sie Zugangsdaten beim Inhaber der Hauptapotheke an.</span>
                        <p class="wizard-text mt-l">Konnte unser Assistent Ihnen hier weiterhelfen?</p>
                        <button (click)="appWizard(20)" class="btn-primary w-25 on-dark-bg">Ja</button>
                        <button (click)="appWizard(25)" class="btn-primary w-25 on-dark-bg">Nein</button>
                    </span>
                </div>
                <div class="col-12 col-md-5 offset-md-1 px-0 pl-md-3" *ngIf="!funk">
                    <div class="embed-responsive embed-responsive-16by9">
                        <video controls class="embed-responsive-item">
                            <source src="https://cms.phoenix-apothekenportal.de/fileadmin/statische-inhalte/videos/03_Fehlermeldung.mp4"
                                    type="video/mp4">
                            Ihr Browser unterstützt leider keine eingebetteten Videos.
                        </video>
                    </div>
                </div>
                <div class="col-12 col-md-5 offset-md-1 px-0 pl-md-3" *ngIf="funk == 'Inhaber'">
                    <div class="embed-responsive embed-responsive-16by9">
                        <video controls class="embed-responsive-item">
                            <source src="https://cms.phoenix-apothekenportal.de/fileadmin/statische-inhalte/videos/05_Zugangsdaten_vergessen.mp4"
                                    type="video/mp4">
                            Ihr Browser unterstützt leider keine eingebetteten Videos.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-l" *ngSwitchCase="20">
            <div class="d-flex justify-content-between pb-l">
                <a (click)="appWizard(11)"><i class="far fa-angle-left pl-0"></i> Zurück</a>
                <a (click)="closeWizard()">Schließen <i class="far fa-times"></i></a>
            </div>
            <p class="wizard-text">Schön, dass wir helfen konnten.</p>
            <button (click)="closeWizard()" class="btn-primary w-auto on-dark-bg">Assistent schließen</button>
        </div>
        <div class="py-l" *ngSwitchCase="25">
            <div class="d-flex justify-content-between pb-l">
                <a (click)="appWizard(11)"><i class="far fa-angle-left pl-0"></i> Zurück</a>
                <a (click)="closeWizard()">Schließen <i class="far fa-times"></i></a>
            </div>
            <div class="col-12 col-md-6 px-0 pr-md-3">
                <p class="wizard-text pl-0">Bitte schildern Sie uns Ihr Anliegen über unser Kontaktformular. Wir setzen uns schnellstmöglich mit Ihnen in Verbindung.</p>
                <button [routerLink]="['/kontakt']" [queryParams]="{showForm:true}" class="btn-primary on-dark-bg w-auto">Kontaktformular öffnen</button>
            </div>
        </div>
    </div>
</div>

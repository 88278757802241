import {Injectable, OnDestroy} from '@angular/core';
import {UserService} from './user.service';
import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {UserRestService} from './api/user.rest-service';
import {AuthService} from './api/auth.service';
import {ProductType} from "../models/shop/product-type.model";


@Injectable({
    providedIn: 'root',
})

export class NavService implements OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();

    constructor(private userService: UserService,
                private userRestService: UserRestService,
                private auth: AuthService) {
    }

    canUseDc(node): boolean {
        let rtn = false;

        if (this.userService.isAdmin()) {
            rtn = true;
        }

        this.userService.getMainIdfObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(idf => {
            if (idf) {
                this.userService.getDistributionCentersObservable(idf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
                    dc => {
                        if (dc) {
                            const arrayOfDc = node.visibleForDc;
                            dc.forEach((vzi) => {
                                vzi.branches.forEach((branch) => {
                                    if (arrayOfDc.includes(branch.id)) {
                                        rtn = true;
                                    }
                                });
                            });
                        }
                    }
                );
            }
        });
        return rtn;
    }

    isVisibleForUser(node): boolean {
        return this.checkUserAndDc(node) && this.checkTemporalAndCanary(node);
    }

    checkUserAndDc(node) {
        let show = false;

        const requiredAuthoritiesFilter = node.requiredAuthorities === null;
        const visibleForDcFilter = node.visibleForDc === null || (node.visibleForDc !== undefined && node.visibleForDc.length === 0);
        const excludeAuthoritiesFilter = node.excludedAuthorities === null;

        // !requiredAuthorities && !visibleForDc && !excludeAuthoritiesFilter
        if (requiredAuthoritiesFilter && excludeAuthoritiesFilter && visibleForDcFilter) {
            show = true;
        }

        // requiredAuthorities && visibleForDc
        if (!requiredAuthoritiesFilter && this.userService.hasRequiredAuthority(node.requiredAuthorities)) {
            show = !visibleForDcFilter && this.canUseDc(node);
        }
        // requiredAuthorities && !visibleForDc
        if (!requiredAuthoritiesFilter && this.userService.hasRequiredAuthority(node.requiredAuthorities) && visibleForDcFilter) {
            show = true;
        }

        // !requiredAuthorities && visibleForDc
        if (requiredAuthoritiesFilter && !visibleForDcFilter) {
            show = this.canUseDc(node);
        }

        if (!excludeAuthoritiesFilter && !this.userService.hasRequiredAuthority(node.excludedAuthorities)) {
            show = true;
        }

        return show;
    }

    checkTemporalAndCanary(node) {

        if (node.feature) {
            if (!this.userService.canUseFeature(node['feature'])) {
                return false;
            }
        }

        if (node.start) {
            if (new Date(node.start) > new Date()) {
                return false;
            }
        }

        if (node.end) {
            if (new Date(node.end) < new Date()) {
                return false;
            }
        }

        return true;
    }

    /**
     * this function is used in route guard canActivate
     * the reason why we cannot use it in our component is because -
     * it is not using store and we are making raw http request. concurrent request will occur in that case.
     * route guard occurs only once while navigation or page refresh.
     */
    checkRights(route: string): Observable<boolean> | boolean {
        const nav = this.userService.getNav();
        const arrayOfDc = nav.getVisibleForDcArray(route);
        const visibleForDcFilterEnabled = arrayOfDc !== undefined && arrayOfDc.length > 0;
        const requiredAuths = nav.getAuthoritiesForRoute(route);
        const requiredAuthEnabled = requiredAuths !== undefined && requiredAuths.length > 0;
        const feature = nav.getFeatureForRoute(route);
        const startDate = nav.getStartDateForRoute(route);
        const endDate = nav.getEndDateForRoute(route);


        return this.userRestService.getMainIdf()
            .pipe(map(data => data.returnObject),
                switchMap((data) =>
                    this.userRestService.getVZs(data)
                        .pipe(map(vzs => vzs.returnObject),
                            switchMap((vzs) =>
                                this.auth.getUserResponseFromGateway()
                                    .pipe(map((authRes) => {
                                            const userAuthEnums = authRes.returnObject.authoritiesEnum;
                                            const userFeatEnums = authRes.returnObject.featuresEnum;
                                            let show = false;
                                            // 1. admin or ksc
                                            if (userAuthEnums.includes('GROUP_ADMIN') || userAuthEnums.includes('GROUP_INTERNAL_USER')) {
                                                return true;
                                            }
                                            // 2. requiredAuthorities and visibleForDc both exist
                                            if (requiredAuthEnabled && visibleForDcFilterEnabled) {
                                                show = !!vzs.some(item => arrayOfDc.includes(item.id)) &&
                                                    !!userAuthEnums.some(item => requiredAuths.includes(item));
                                            }
                                            // 3. only requiredAuthorities exist
                                            if (requiredAuthEnabled && !visibleForDcFilterEnabled) {
                                                show = !!userAuthEnums.some(item => requiredAuths.includes(item));
                                            }
                                            // 4. feature check
                                            if (feature !== '') {
                                                show = userFeatEnums.indexOf(feature) > -1;
                                            }
                                            // 5. start date check
                                            if (startDate !== '') {
                                                if (new Date(startDate) > new Date()) {
                                                    show = false;
                                                }
                                            }
                                            // 6. end date check
                                            if (endDate !== '') {
                                                if (new Date(endDate) < new Date()) {
                                                    show = false;
                                                }
                                            }
                                            return show;
                                        }),
                                        catchError((error) => of(false))
                                    )
                            ),
                            catchError((error) => of(false))
                        )
                ),
                catchError((error) => of(false))
            );
    }

    getRouteForProductType(type: ProductType) {
        return (type === ProductType.NONE || type === ProductType.DokuLight) ? 'shop' : type;
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

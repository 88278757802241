import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductSearchOpenDialogComponent} from './product-search-open-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule
    ],
    declarations: [ProductSearchOpenDialogComponent],
    exports: [ProductSearchOpenDialogComponent]
})
export class ProductSearchOpenDialogModule {
}

import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, throwError} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';

@Injectable({
    providedIn: 'root',
})
export class AccountService extends RestServiceAbstract {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public loadAllAccountdata() {
        return this.get(`/usermanagement/idf/allAccounts`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public getShippingAddress(idf) {
        return this.get(`/usermanagement/idf/${idf}/shipping-address`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

}


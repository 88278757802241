import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  @Input() message: string;
  @Input() html: string;
  @Input() showButton: string;
  @Input() showLinkToContact: boolean;
  @Input() showLinkToHelp: boolean;
  @Input() showLinkToStart: boolean;
  icon = 'fa-check-circle';

  constructor() { }

  ngOnInit() {
  }

}

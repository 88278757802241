<div *ngIf="ads.length"
     class="sidebar-ad"
     data-track-content
     (click)="mtmEvent(ads[0].title)"
     id="ads-banner-{{position}}"
     data-content-name="Skyscraper banner">
  <a *ngIf="ads[0].link"
     href="{{ads[0].link}}"
     [attr.aria-label]="ads[0].title"
     [target]="ads[0].target"
     data-content-target>
    <img src="{{ CDN }}/marketing-management/{{ads[0].image}}"
         data-content-piece
         class="skyscraper-ad" alt="{{ads[0].title}}">
  </a>
  <div *ngIf="!ads[0].link">
    <img src="{{ CDN }}/marketing-management/{{ads[0].image}}"
         data-content-piece
         class="skyscraper-ad" alt="{{ads[0].title}}">
  </div>
</div>

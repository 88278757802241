import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    @Input() message: string;
    @Input() html: string;
    @Input() showButton: string;
    @Input() showLinkToContact: boolean;
    @Input() showLinkToHelp: boolean;
    @Input() showLinkToStart: boolean;
    icon = 'fa-exclamation-circle';

    constructor() {
    }

    ngOnInit(): void {
    }

}

import {Action} from '@ngrx/store';
import {TrackRequestModel} from '../../models/shop/track-request.model';
import {HttpErrorResponse} from '@angular/common/http';
import {TrackIdfRequestModel} from '../../models/shop/track-idf-request.model';

export enum TrackActionsTypes {
    TRACK_START = '[TRACK] Start',
    TRACK_COMPLETE = '[TRACK] Complete',
    TRACK_FAILED = '[TRACK] Failed',
    TRACK_IDF_START = '[TRACK] Start Tracking for IDF',
    TRACK_IDF_COMPLETE = '[TRACK] Complete Tracking for IDF',
    TRACK_IDF_FAILED = '[TRACK] Failed Tracking for IDF',
    DELAYS_LOAD = '[TRACK] Load Delays',
    DELAYS_COMPLETED = '[TRACK] Delays Completed',
    DELAYS_FAILED = '[TRACK] Delays Failed'
}


export class StartTrackAction implements Action {
    readonly type = TrackActionsTypes.TRACK_START;

    constructor(public payload: TrackRequestModel) {
    }
}

export class CompleteTrackAction implements Action {
    readonly type = TrackActionsTypes.TRACK_COMPLETE;

    constructor(public payload: any) {
    }
}

export class FailTrackAction implements Action {
    readonly type = TrackActionsTypes.TRACK_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}


export class StartTrackIdfAction implements Action {
    readonly type = TrackActionsTypes.TRACK_IDF_START;

    constructor(public payload: TrackIdfRequestModel) {
    }
}

export class CompleteTrackIdfAction implements Action {
    readonly type = TrackActionsTypes.TRACK_IDF_COMPLETE;

    constructor(public payload: any) {
    }
}

export class FailTrackIdfAction implements Action {
    readonly type = TrackActionsTypes.TRACK_IDF_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadDelaysAction implements Action {
    readonly type = TrackActionsTypes.DELAYS_LOAD;

    constructor() {
    }
}

export class DelaysCompleteAction implements Action {
    readonly type = TrackActionsTypes.DELAYS_COMPLETED;

    constructor(public payload: any) {
    }
}

export class DelaysFailAction implements Action {
    readonly type = TrackActionsTypes.DELAYS_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type TrackAndTraceActions =
    StartTrackAction |
    FailTrackAction |
    StartTrackIdfAction |
    FailTrackIdfAction |
    CompleteTrackIdfAction |
    CompleteTrackAction |
    LoadDelaysAction |
    DelaysCompleteAction |
    DelaysFailAction;

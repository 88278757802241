import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import * as fromTrackActions from '../actions/track.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Action} from '@ngrx/store';
import {TrackingRestService} from '../../services/api/tracking-rest-service';
import {UserRestService} from '../../services/api/user.rest-service';
import {TrackItem} from '../../models/shop/track-item.model';
import * as fromInvoiceActions from '../actions/invoices.actions';
import {InvoicesResponse} from '../../models/invoices-response.model';


@Injectable()
export class TrackEffects {

    constructor(private actions$: Actions,
                private trackingRestService: TrackingRestService,
                private userRestService: UserRestService) {
    }

    loadTrackItems$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromTrackActions.StartTrackAction>(
                fromTrackActions.TrackActionsTypes.TRACK_START))
            .pipe(map(action => action.payload),
                switchMap((trackRequest) =>
                    this.trackingRestService.requestTracking(trackRequest)
                        .pipe(map(data => data.returnObject),
                            switchMap((data) =>
                                this.userRestService.getVZs(data.customerIdentification.customerNo).pipe(map((vzs) => {
                                        const vz = vzs.returnObject.filter(p => p.id === data.customerIdentification.branchNo);

                                        const trackItems = [];
                                        for (const info of data.tourInformation.tour) {
                                            info.center = vz[0].location;
                                            info.delayInformation = data.tourInformation.delayInformation;
                                            trackItems.push(new TrackItem(info));
                                        }
                                        trackItems.sort((a, b) => {
                                            const departureHourA = Number(a.tourId.slice(0, 2));
                                            const departureHourB = Number(b.tourId.slice(0, 2));
                                            if (departureHourA > 24) {
                                                return 1;
                                            } else if (departureHourB > 24) {
                                                return 1;
                                            } else if (a.deliveredAt === null) {
                                                return -1;
                                            } else if (b.deliveredAt === null) {
                                                return 1;
                                            } else if (new Date(b.deliveredAt) < new Date(a.deliveredAt)) {
                                                return -1;
                                            } else {
                                                return 1;
                                            }
                                        });
                                        return new fromTrackActions.CompleteTrackAction(trackItems);
                                    }),
                                    catchError((error) => of(new fromTrackActions.FailTrackAction(error)))
                                )
                            ),
                            catchError((error) => of(new fromTrackActions.FailTrackAction(error)))
                        ),
                ));
    });

    loadTrackItemsIdf$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromTrackActions.StartTrackIdfAction>(
                fromTrackActions.TrackActionsTypes.TRACK_IDF_START))
            .pipe(map(action => action.payload),
                switchMap((trackRequest) =>
                    this.trackingRestService.trackForIdf(trackRequest)
                        .pipe(map(data => data.returnObject),
                            map((data) =>
                                {
                                    let trackItems = [];
                                    let inDelivery = [];
                                    let delivered = [];
                                    data.map(item => {
                                        if(item.tour.deliveredAt === null){
                                            inDelivery.push(new TrackItem(item));
                                        } else {
                                            delivered.push(new TrackItem(item));
                                        }
                                    });

                                    inDelivery.sort((a,b) => {
                                        const departureHourA = Number(a.tourId.slice(0, 2));
                                        const departureHourB = Number(b.tourId.slice(0, 2));
                                        if (departureHourA > 24) {
                                            return 1;
                                        } else if (departureHourB > 24) {
                                            return 1;
                                        } else {
                                            return departureHourB - departureHourA
                                        }
                                    });

                                    delivered.sort((a,b) =>  {
                                        return Date.parse(b.deliveredAt) - Date.parse(a.deliveredAt)
                                    });

                                    trackItems = [...inDelivery,...delivered];

                                    return new fromTrackActions.CompleteTrackIdfAction(trackItems);
                                }
                            ),
                            catchError((error) => of(new fromTrackActions.FailTrackIdfAction(error)))
                        ),
                ));
    });

    $loadDelays: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromTrackActions.LoadDelaysAction>(fromTrackActions.TrackActionsTypes.DELAYS_LOAD))
            .pipe(switchMap(() =>
                    this.trackingRestService.getDelays().pipe(
                        map(data => new fromTrackActions.DelaysCompleteAction(data.returnObject)),
                        catchError((error) => of(new fromTrackActions.DelaysFailAction(error)))
                    )
                )
            );
    });
}

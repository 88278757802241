import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CovidDialog, LoginComponent} from '../modules/authentication/login/login.component';
import {LogoutComponent} from '../modules/authentication/logout/logout.component';
import {MembershipRequestComponent} from '../modules/authentication/membership-request/membership-request.component';
import {PasswordForgottenComponent} from '../modules/authentication/password-forgotten/password-forgotten.component';
import {ViewPublicComponent} from './view-public.component';
import {ROUTES} from './view-public.routes';
import {MessageModule} from '../modules/shared/message/message.module';
import {SpinnerModule} from '../modules/shared/spinner/spinner.module';
import {NotificationPublicModule} from '../modules/shared/notification-public/notification-public.module';
import {NotificationModule} from '../modules/shared/notification/notification.module';
import {SuccessModule} from '../modules/shared/success/success.module';
import {FooterModule} from '../view/footer/footer.module';
import {AccountDataModule} from '../modules/account/components/account-data/account-data.module';
import {VideoDialogComponent} from '../modules/public/video-dialog/video-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {HomeNotifyComponent} from '../modules/public/home-notify/home-notify.component';
import {SliderComponent} from '../modules/public/slider/slider.component';
import {WizardComponent} from '../modules/public/wizard/wizard.component';
import {DisabledDialogComponent} from '../view/dashboard/tile/tile.component';
import {ErrorModule} from '../modules/shared/error/error.module';
import {IntegrationsModule} from '../modules/account/components/integrations/integrations.module';
import {ViewModule} from "../view/view.module";
import { HeaderComponent } from './header/header.component';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    ROUTES,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    NotificationPublicModule,
    MessageModule,
    SpinnerModule,
    NotificationModule,
    SuccessModule,
    FooterModule,
    AccountDataModule,
    MatDialogModule,
    TranslateModule,
    MatTooltipModule,
    ErrorModule,
    IntegrationsModule,
    ViewModule,
    MatButtonModule
  ],
    declarations: [
        ViewPublicComponent,
        LoginComponent,
        LogoutComponent,
        PasswordForgottenComponent,
        MembershipRequestComponent,
        VideoDialogComponent,
        CovidDialog,
        DisabledDialogComponent,
        HomeNotifyComponent,
        SliderComponent,
        WizardComponent,
        HeaderComponent
    ],
    exports: [
        ViewPublicComponent,
    ],
    entryComponents: [
        VideoDialogComponent,
        CovidDialog,
        DisabledDialogComponent,
        HomeNotifyComponent,
        SliderComponent
    ]
})
export class ViewPublicModule {
}



import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';
import {UberActions, UberActionTypes} from '../actions/uber.actions';
import {CartActions, CartActionTypes} from '../actions/cart.actions';

export interface State {
    uberResponse: ProductsResponse;
    uberRequest: ProductRequest;
    uberErrorResponse: HttpErrorResponse;
    uberLoading: boolean;
    uberProducts: Product[];
    uberProductCache: Product[];
    uberScrollPosition: number;
    uberCart: any;
    uberVendors: any[];
    uberVendorsLoading: boolean;
    uberVendorsErrorResponse: HttpErrorResponse;
}

export const initialState: State = {
    uberResponse: null,
    uberRequest: null,
    uberErrorResponse: null,
    uberLoading: false,
    uberProducts: [],
    uberProductCache: [],
    uberScrollPosition: 0,
    uberCart: {},
    uberVendors: [],
    uberVendorsLoading: false,
    uberVendorsErrorResponse: null
};

export function reducerCart(state = initialState, action: CartActions): State {
    switch (action.type) {
        case CartActionTypes.ADD_CART_ITEM_ACTION:
            if (action.payload.length === 0) {
                return state;
            }

            return {
                ...state,
                uberCart: Object.keys(state.uberCart)
                    .filter(id => id !== action.payload[0].product.id + action.payload[0].product.campaignId)
                    .reduce((obj, id) => {
                        obj[id] = state.uberCart[id];
                        return obj;
                    }, {})
            };
        default:
            return state;

    }
}

export function reducer(state = initialState, action: UberActions) {
    switch (action.type) {
        case UberActionTypes.UBER_LOAD:
            return {
                ...state,
                uberRequest: action.payload,
                uberLoading: true
            };
        case UberActionTypes.UBER_LOAD_COMPLETE:
            return {
                ...state,
                uberProducts: [...action.payload.products],
                uberResponse: action.payload,
                uberLoading: false
            };
        case UberActionTypes.UBER_LOAD_FAIL:
            return {
                ...state,
                uberErrorResponse: action.payload,
                uberLoading: false
            };
        case UberActionTypes.UBER_RESET:
            return {
                ...state,
                uberProducts: [],
                uberResponse: null
            };
        case UberActionTypes.UBER_LOAD_VENDORS:
            return {
                ...state,
                uberVendorsErrorResponse: null,
                uberVendorsLoading: true
            };
        case UberActionTypes.UBER_LOAD_VENDORS_COMPLETE:
            return {
                ...state,
                uberVendors: action.payload,
                uberVendorsLoading: false,
            };
        case UberActionTypes.UBER_LOAD_VENDORS_FAIL:
            return {
                ...state,
                uberVendorsErrorResponse: action.payload,
                uberVendorsLoading: false
            };
        case UberActionTypes.UBER_SEARCH:
            return {
                ...state,
                uberProducts: [],
                uberProductCache: [],
                uberLoading: true
            };
        case UberActionTypes.UBER_SCROLL_POSITION:
            return {
                ...state,
                uberScrollPosition: action.payload
            };
        case UberActionTypes.UBER_BATCH_FROM_CACHE:
            return {
                ...state,
                uberProducts: [...state.uberProducts],
                uberProductCache: state.uberProductCache.slice(800)
            };
        case UberActionTypes.UBER_UPDATE_CART:
            return {
                ...state,
                uberCart: {...state.uberCart, ...{[action.payload.id]: action.payload.cartItem}}
            };
        case UberActionTypes.UBER_EMPTY_CART:
            const newUberCart = Object.keys(state.uberCart)
                .filter(pfid => pfid.indexOf(action.payload) < 0)
                .reduce((newCart, pfid) => {
                    newCart[pfid] = state.uberCart[pfid];
                    return newCart;
                }, {});

            return {
                ...state,
                uberCart: newUberCart
            };
        default:
            return state;
    }
}

export const getUberLoading = (state: State) => state.uberLoading;
export const getUberRequest = (state: State) => state.uberRequest;
export const getUberResponse = (state: State) => state.uberResponse;
export const getUberErrorResponse = (state: State) => state.uberErrorResponse;
export const getUberProducts = (state: State) => state.uberProducts;
export const getUberCart = (state: State) => state.uberCart;
export const getUberScrollPosition = (state: State) => state.uberScrollPosition;
export const getUberProductCache = (state: State) => state.uberProductCache;
export const getUberVendorsLoading = (state: State) => state.uberVendorsLoading;
export const getUberVendorsErrorResponse = (state: State) => state.uberVendorsErrorResponse;
export const getUberVendors = (state: State) => state.uberVendors;

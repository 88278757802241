import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TreuhandService} from '../../../../../../core/services/integrations/treuhand.service';

@Component({
    selector: 'treuhand-copy-modal',
    templateUrl: './treuhand-copy-modal.component.html',
    styleUrls: ['./treuhand-copy-modal.component.scss']
})
export class TreuhandCopyModalComponent implements OnInit, OnDestroy {
    public sourceIdf: string;
    public ngUnsubscribe$ = new Subject<any>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<TreuhandCopyModalComponent>,
                public treuhandService: TreuhandService) {
        if (data.idfs.length === 1) {
            this.sourceIdf = this.data.idfs[0].idf;
        }
    }

    ngOnInit(): void {
    }

    onChangeIdf(idf) {
        this.sourceIdf = idf;
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onCopyClick(): void {
        if (this.sourceIdf !== undefined && this.data.targetIdf) {
            const data = {
                sourceIdf: this.sourceIdf,
                targetIdf: this.data.targetIdf
            };
            this.treuhandService.copyConfiguration(data);
            this.treuhandService.copyConfigurationLoadingObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((loading) => {
                if (!loading) {
                    this.treuhandService.loadTreuhandConfig(this.data.targetIdf);
                    this.dialogRef.close();
                }
            });
        }
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-public-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public activeHeading: string | boolean;
  private ngUnsubscribe$ = new Subject<any>();


  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.determineHeading();
      }
    });
  }


  ngOnInit(): void {
  }

  determineHeading() {
    const route = this.route.snapshot;
    if (route.children.length > 0) {
      if (route.queryParamMap.has('heading')) {
        this.activeHeading = route.queryParamMap.get('heading');
      }
    }
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }


}

<div class="d-flex justify-content-center align-items-center flex-column p-3" *ngIf="data.idfs">
    <h1 mat-dialog-title class="pb-3">Konfiguration</h1>
    <div mat-dialog-content class="text-center">
        Wählen Sie die IDF aus, deren Konfiguration Sie kopieren möchten.
    </div>
    <form class="w-100 overflow-hidden mt-3">
        <mat-form-field appearance="outline">
            <mat-label>IDF-Nummer</mat-label>
            <mat-select
                    [(value)]="sourceIdf"
                    (selectionChange)="onChangeIdf($event.source.value)">
                <mat-option *ngIf="data.idfs.length === 0">
                    <i class="fal fa-spinner-third fa-spin"></i> IDFs werden geladen.
                </mat-option>
                <mat-option *ngFor="let idf of data.idfs" [value]="idf.idf">{{ idf.idf }}
                    ({{idf.name}})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <div mat-dialog-actions>
        <button mat-raised-button cdkFocusInitial (click)="onCancelClick()" type="button" class="btn btn-secondary">
            <span>ABBRECHEN</span>
        </button>
        <button mat-raised-button (click)="onCopyClick()" cdkFocusInitial type="button" class="btn btn-primary">
            <span>ÜbernehmeN</span>
        </button>
    </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';


@Component({
    selector: 'message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
    @Input('icon') public icon: string;
    @Input('message') public message: string;
    @Input('html') public html: string;
    @Input('id') public id: string;
    @Input() public showButton: string;
    @Input() public showLinkToStart: boolean;
    @Input() public showLinkToContact: boolean;
    @Input() public showLinkToHelp: boolean;

    constructor(
        public translate: TranslateService,
        private router: Router,
    ) {
    }

    ngOnInit() {
    }

    backToSelf() {
        this.router.navigate([this.router.url]);
    }
}

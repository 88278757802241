import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {UserService} from '../../../core/services/user.service';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-digital-month-action',
  templateUrl: './digital-month-action.component.html',
  styleUrls: ['./digital-month-action.component.scss']
})
export class DigitalMonthActionComponent implements OnInit {
  ngUnsubscribe$ = new Subject<any>();
  @Input() content = ''
  public discountLevel = 9;
  @Input()month = '';

  constructor(private userService: UserService) {

  }

  ngOnInit(): void {
    this.userService.selectedIdf$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(idf => {
      if(idf) {
        this.discountLevel = idf.discountLevel;
      }
    })
  }

  getMonth() {
    const date = new Date(this.month +'-01');
    return date.toLocaleDateString('de-de', { month: 'long' }) +' '+ date.getFullYear();
  }

  getDiscountLevel() {
    return (this.discountLevel === 0) ? 'UVP' : ('RS' + this.discountLevel);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }
}

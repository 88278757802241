import {ProductRequest} from './product-request.model';

export class SearchProductRequest extends ProductRequest {

    search: string;

    constructor(obj: any = {}) {
        super(obj);
        this.search = obj && obj.queryString || '';
    }

}
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DialogDataItem} from '../../../../../core/interfaces/dialog-data-item.interface';

@Component({
    selector: 'add-to-cart-confirm-dialog',
    templateUrl: './add-to-cart-confirm-dialog.component.html',
    styleUrls: ['./add-to-cart-confirm-dialog.component.scss']
})
export class AddToCartConfirmDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataItem,
                public dialogRef: MatDialogRef<AddToCartConfirmDialogComponent>) {
    }

    ngOnInit() {
    }

    cart() {
        this.dialogRef.close({data: 'cart'});
    }

    cash() {
        this.dialogRef.close({data: 'cash'});
    }

    add() {
        this.dialogRef.close({data: 'add'});
    }

    close() {
        this.dialogRef.close({data: 'close'});
    }
}

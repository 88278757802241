import { Injectable } from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromReducers from '../store/reducers';
import {LoadMessageAction, LoadMessagesAction} from '../store/actions/message.actions';
import {Observable} from 'rxjs';
import {MessageResponse} from '../models/message/message-response.model';
import {MessageRestService} from './api/message/message-rest.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

    constructor(public store: Store<fromReducers.State>,
                private messageRestService: MessageRestService) {}

    loadMessages(rq): Observable<MessageResponse> {
        this.store.dispatch(new LoadMessagesAction(rq));
        return this.getMessagesObservable();
    }

    deleteMessages(rq): Observable<any> {
        return this.messageRestService.deleteMessages(rq);
    }

    loadMessage(id): Observable<MessageResponse> {
        this.store.dispatch(new LoadMessageAction(id));
        return this.getMessagesObservable();
    }

    getMessagesObservable(): Observable<MessageResponse> {
       return this.store.pipe(select(fromReducers.getMessages));
    }

    getMessagesLoadingObservable(): Observable<boolean> {
       return this.store.pipe(select(fromReducers.getMessagesLoading));
    }

    getMessageLoadingObservable(): Observable<boolean> {
       return this.store.pipe(select(fromReducers.getMessageLoading));
    }

    getMessagesErrorObservable() {
        return this.store.pipe(select(fromReducers.getMessagesError));
    }

}

import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Subject, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {SubsequentDeliveriesRequest} from '../../models/subsequent-deliveries/subsequent.deliveries.request';
import {SubsequentDeliveriesUpdate} from '../../models/subsequent-deliveries/subsequent.deliveries.update';
import {SubsequentDeliveryAddRequestModel} from '../../models/subsequent-deliveries/subsequent.delivery.add.request.model';


@Injectable({
    providedIn: 'root'
})
export class SubsequentDeliveriesRestService extends RestServiceAbstract implements OnDestroy {
    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public loadSubsequentDeliveries(subsequentDeliveriesRequest: SubsequentDeliveriesRequest) {
        return this.get('/resource/subsequent-delivery/' + subsequentDeliveriesRequest.getIdf()).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }


    public updateSubsequentDelivery(subsequentDeliveryUpdate: SubsequentDeliveriesUpdate) {
        return this.put('/resource/subsequent-delivery',  subsequentDeliveryUpdate).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public addNewSubsequentDelivery(request: SubsequentDeliveryAddRequestModel) {
        return this.post(`/resource/subsequent-delivery`, request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public subsequentDeliveryAvailability(request: SubsequentDeliveryAddRequestModel) {
        return this.post(`/resource/subsequent-delivery/availability`, request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsComponent} from './cms.component';
import {RouterModule} from '@angular/router';
import {FormsComponent} from './forms/forms.component';
import {ContentComponent} from './content/content.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {SuccessModule} from '../success/success.module';
import {NotificationModule} from '../notification/notification.module';
import {MonatsaktionComponent} from './monatsaktion/monatsaktion.component';
import {PhoenixStoreComponent} from '../phoenix-store/phoenix-store.component';
import {LindaLeistungenComponent} from './linda-leistungen/linda-leistungen.component';
import { LindaWissenComponent } from './linda-wissen/linda-wissen.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SafehtmlModule} from '../safehtml/safehtml.module';
import {DigitalMonthActionComponent} from "../digital-month-action/digital-month-action.component";
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';


export const routes = [
  {
    path: '', component: CmsComponent, pathMatch: 'full'
  },
];

@NgModule({
  declarations: [
    FormsComponent,
    ContentComponent,
    CmsComponent,
    MonatsaktionComponent,
    PhoenixStoreComponent,
    LindaLeistungenComponent,
    LindaWissenComponent,
    DigitalMonthActionComponent
  ],
  exports: [
    FormsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    SuccessModule,
    NotificationModule,
    MatTooltipModule,
    SafehtmlModule,
      MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
  ]
})
export class CmsModule {
}

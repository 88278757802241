import {Injectable} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {Observable, Subject, throwError} from 'rxjs';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FluShopRestService extends RestServiceAbstract {

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public getActivePreorderCategories(): Observable<Response> {
        return this.get(`/resource/preorders`).pipe(
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public getPreorderSuppliersByUrl(categoryUrl: string): Observable<Response> {
        return this.get(`/resource/preorders/${categoryUrl}`).pipe(
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public getPreordersForIdf(period: any, idf: any): Observable<Response> {
        return this.get(`/resource/preorders/${period}/${idf}`).pipe(
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public saveOrdersForPeriod(period: any, idf: any, data: any): Observable<Response> {
        return this.post(`/resource/preorders/${period}/${idf}`, data).pipe(
            map((response: Object) => {
                return new Response(response);
            })
        );
    }

    public getArchivesForIdf(idf: any): Observable<any> {
        return this.get(`/resource/preorders/archive/${idf}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getArchivePreOrderById(idf: any, id: any): Observable<any> {
        return this.get(`/resource/preorders/archive/${idf}/${id}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

}

<h2 mat-dialog-title>Willkommen im PHOENIX Apothekenportal!</h2>
<div mat-dialog-content>
  Ihr nächster Schritt:

  <p>
    Mitbenutzer anlegen / Zugang für Ihre Filialen
  </p>
  <p>
    Damit Ihre Mitarbeiter in Ihren Apotheken ebenfalls einen Zugang zum Apothekenportal erhalten, legen Sie
    Mitbenutzer an. So können Sie bei Bedarf Ihren Filialen gezielt Zugriffe erteilen, z.B. auf Belege.
  </p>
  <p>
    Eine Anleitung finden Sie hier:<br>
    <a href="#/open/static-content?url=%2Ffileadmin/statische-inhalte/PDF/Mitbenutzerverwaltung-Apothekenportal.pdf" target="_blank">Mitbenutzerverwaltung</a>
  </p>
</div>
<div mat-dialog-actions>
  <button class="btn-secondary" mat-button [mat-dialog-close]="false">
    <span>NEIN, DANKE</span>
  </button>

  <button class="btn-primary float-right" mat-button [mat-dialog-close]="false">
    <span routerLink="/mitbenutzerverwaltung">MITBENUTZER ANLEGEN</span>
  </button>
</div>

<div class="message panel-body">
    <div class="row mb-xl">
        <div class="col-md-2 phx-cta my-auto text-center"><i class="fal {{icon}} fa-6x"></i></div>
        <div class="col-md-8 my-auto">
            <h1 *ngIf="id" class="mb-s">{{id | translate}}</h1>
            <h1 *ngIf="!id && message" class="mb-l">{{message}}</h1>
            <div *ngIf="!id && html" [innerHTML]="html"></div>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div *ngIf="showButton">
                <button class="btn btn-secondary mb-5" type="submit" (click)="backToSelf()">
                    {{showButton}}
                </button>
            </div>
            <div *ngIf="showLinkToStart">
                <a class="message-link" href="#/login">Zurück zur Startseite <i
                        class="far fa-angle-right"></i></a>
            </div>
            <div *ngIf="showLinkToContact">
                <a class="message-link" routerLink="/kontakt">Zum Kontaktformular <i
                        class="far fa-angle-right"></i></a>
            </div>
            <div *ngIf="showLinkToHelp">
                <a class="message-link" [queryParams]="{url: '/hilfe', heading: 'Häufig gestellte Fragen'}"
                   [routerLink]="'/open/static-content'">Zur Hilfeseite <i
                        class="far fa-angle-right"></i></a>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>

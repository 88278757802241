<h1 mat-dialog-title>Sendungsprotokoll</h1>

<p>Hier können Sie prüfen, welche Belege erfolgreich übertragen worden sind bzw. ob es Fehler gab.</p>
<table class="table table-hover">
    <thead>
    <tr>
        <th scope="col">Status</th>
        <th scope="col">Datum</th>
        <th scope="col">Kommentar</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let log of data.logs">
        <td><i [ngClass]="log.status === 'SUCCESS' ? 'fas fa-check-circle text-success' : 'fas fa-exclamation-circle text-danger'"></i></td>
        <td>{{ log.date | date: 'short' }} Uhr</td>
        <td *ngIf="log.status === 'SUCCESS'">{{ getType(log.type) }} {{ log.document }} wurde erfolgreich übertragen.</td>
        <td *ngIf="log.status === 'FAILURE'">{{ getType(log.type) }} {{ log.document }} wurde nicht übertragen.</td>
    </tr>
    </tbody>
</table>

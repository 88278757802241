import {Paragraph73Actions, Paragraph73ActionTypes} from '../actions/paragraph73.actions';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {Product} from '../../models/shop/product.model';
import {ProductRequest} from '../../models/shop/product-request.model';


export interface State {
    paragraph73Request: ProductRequest;
    paragraph73Response: ProductsResponse;
    paragraph73ErrorResponse: HttpErrorResponse;
    paragraph73Loading: boolean;
    paragraph73Products: Product[];
    paragraph73ProductCache: Product[];
    paragraph73ScrollPosition: number;
}

export const initialState: State = {
    paragraph73Request: null,
    paragraph73Response: null,
    paragraph73ErrorResponse: null,
    paragraph73Loading: false,
    paragraph73Products: [],
    paragraph73ProductCache: [],
    paragraph73ScrollPosition: 0
};

export function reducer(state = initialState, action: Paragraph73Actions): State {
    switch (action.type) {
        case Paragraph73ActionTypes.LOAD_PARAGRAPH73:
            return {
                ...state,
                paragraph73Request: action.payload,
            };
        case Paragraph73ActionTypes.LOAD_PARAGRAPH73_COMPLETE:
            return {
                ...state,
                paragraph73ProductCache: [...state.paragraph73ProductCache, ...action.payload.products],
                paragraph73Response: action.payload,
                paragraph73Loading: false
            };
        case Paragraph73ActionTypes.LOAD_PARAGRAPH73_FAIL:
            return {
                ...state,
                paragraph73ErrorResponse: action.payload,
                paragraph73Loading: false
            };
        case Paragraph73ActionTypes.SEARCH_PARAGRAPH73:
            return {
                ...state,
                paragraph73Products: [],
                paragraph73ProductCache: [],
                paragraph73Loading: true
            };
        case Paragraph73ActionTypes.UPDATE_SCROLLING_POSITION:
            return {
                ...state,
                paragraph73ScrollPosition: action.payload
            };
        case Paragraph73ActionTypes.GET_BATCH_FROM_CACHE:
            return {
                ...state,
                paragraph73Products: [...state.paragraph73Products, ...state.paragraph73ProductCache.slice(0, 20)],
                paragraph73ProductCache: state.paragraph73ProductCache.slice(20)
            };
        default:
            return state;
    }
}

export const getParagraph73Loading = (state: State) => state.paragraph73Loading;
export const getParagraph73Response = (state: State) => state.paragraph73Response;
export const getParagraph73Request = (state: State) => state.paragraph73Request;
export const getParagraph73ErrorResponse = (state: State) => state.paragraph73ErrorResponse;
export const getParagraph73ProductCache = (state: State) => state.paragraph73ProductCache;
export const getParagraph73Products = (state: State) => state.paragraph73Products;
export const getParagraph73ScrollPosition = (state: State) => state.paragraph73ScrollPosition;

import {Injectable} from '@angular/core';
import {RestServiceAbstract} from "../../abstracts/rest-service.abstract";
import {map, takeUntil} from "rxjs/operators";
   import {Observable, Subject} from "rxjs";
import {RegistrationAttemptRequest} from "../../models/registration/registration-attempt-request.model";
import {Response} from "../../models/response.model";
import {SetCredentialsTokenRequest} from "../../models/registration/set-credentials-token-request.model";
import {ICredentialsService} from "../../interfaces/credentials-service.interface";
import {SetCredentialsOldPasswordRequest} from '../../models/registration/set-credentials-old-password-request.model';

@Injectable({
    providedIn: 'root'
})
export class NewPasswordRestService extends RestServiceAbstract implements ICredentialsService{

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public setCredentials(scr: SetCredentialsOldPasswordRequest): Observable<Response> {
        return this.post(`/usermanagement/user/setCredentials`, scr).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public confirm(token: string): Observable<Response> {
        return null;
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }
}

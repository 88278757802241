import {NgModule} from '@angular/core';
import {ErrorComponent} from './error.component';
import {MessageModule} from '../message/message.module';

@NgModule({
    imports: [
        MessageModule
    ],
    declarations: [ErrorComponent],
    exports: [ErrorComponent]
})
export class ErrorModule {

}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Action} from '@ngrx/store';
import * as fromRetourenActions from '../actions/retouren.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {RetourenRestService} from '../../services/api/retouren.rest-service';

@Injectable()
export class RetourenEffects {

    constructor(private actions$: Actions,
                private retourenRestService: RetourenRestService) {
    }

    returnRequest$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromRetourenActions.RetourenRequestAction>(
                fromRetourenActions.RetourenActionTypes.RETOUREN_REQUEST))
            .pipe(map(action => action.payload),
                switchMap((request) =>
                    this.retourenRestService.returnRequest(request)
                        .pipe(
                            map((data) => new fromRetourenActions.RetourenRequestCompleteAction(data.returnObject)),
                            catchError((error) => of(new fromRetourenActions.RetourenRequestFailAction(error)))
                        )
                )
            );
    });

    returnAnnouncement$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromRetourenActions.RetourenAnnouncementAction>(
                fromRetourenActions.RetourenActionTypes.RETOUREN_ANNOUNCEMENT))
            .pipe(map(action => action.payload),
                switchMap((request) =>
                    this.retourenRestService.returnAnnouncement(request)
                        .pipe(
                            map((data) => new fromRetourenActions.RetourenAnnouncementCompleteAction(data.returnObject)),
                            catchError((error) => of(new fromRetourenActions.RetourenAnnouncementFailAction(error)))
                        )
                )
            );
    });

}

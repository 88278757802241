import {FormGroup, ValidationErrors} from '@angular/forms';

export function PasswordValidator(controlName: string) {
    return (formGroup: FormGroup) => {
        let errors: ValidationErrors = null;
        const control = formGroup.controls[controlName];
        let password = [];

        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSpecialCharacter = /[~!@#\$%&*_\-+=`|(){}[\]:;"'<>,.?\/\^\\]/.test(control.value);

        password.push(/\d/.test(control.value));
        password.push(/[A-Z]/.test(control.value));
        password.push(/[a-z]/.test(control.value));
        password.push(/[~!@#\$%&*_\-+=`|(){}[\]:;"'<>,.?\/\^\\]/.test(control.value));
        if (password.filter(e => e === true).length < 3) {
            errors = {...errors, ...{ weak: true }};
            if(!hasNumber)
                errors = {...errors, ...{ noNumber: true }};
            if(!hasUpper)
                errors = {...errors, ...{ noUpper: true }};
            if(!hasLower)
                errors = {...errors, ...{ noLower: true }};
            if(!hasSpecialCharacter)
                errors = {...errors, ...{ noSpecialCharacter: true }};
        }

        if(!/.{8}/.test(control.value)){
            errors = {...errors, ...{ minlength: true }};
        }
        control.setErrors(errors);
    }
}

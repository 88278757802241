import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductSearchOpenDialogModel} from './product-search-open-dialog.model';

@Component({
    selector: 'product-search-open-dialog',
    templateUrl: './product-search-open-dialog.component.html',
    styleUrls: ['./product-search-open-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductSearchOpenDialogComponent implements OnInit {

    imageUrl: string;
    title: string;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<ProductSearchOpenDialogModel>,
        @Inject(MAT_DIALOG_DATA) public data: ProductSearchOpenDialogModel) {
        this.imageUrl = data.imageUrl;
        this.title = data.title;
        this.message = data.message;
    }

    ngOnInit() {
    }

  onNoClick(): void {
        this.dialogRef.close();
    }
}

<h2 mat-dialog-title>
    {{title}}
</h2>
<div mat-dialog-content>
    <p [innerHTML]="message"></p>
</div>
<div mat-dialog-actions>
    <button type="button" mat-button (click)="onNoClick()" class="btn btn-secondary"><span>Nein</span><i class="material-icons">close</i></button>
    <button type="button" class="btn btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial><span>OK</span><i class="material-icons">check</i></button>
</div>

import { DokuLightActions, DokuLightActionTypes } from '../actions/doku-light.actions';
import { ProductsResponse } from '../../models/shop/products-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Product } from '../../models/shop/product.model';
import { ProductSearchRequest } from '../../models/shop/product-search-request.model';


export interface State {
    dokuLightRequest: ProductSearchRequest;
    dokuLightResponse: ProductsResponse;
    dokuLightErrorResponse: HttpErrorResponse;
    dokuLightLoading: boolean;
    dokuLightProducts: Product[];
    dokuLightProductCache: Product[];
    dokuLightScrollPosition: number;
}

export const initialState: State = {
    dokuLightRequest: null,
    dokuLightResponse: null,
    dokuLightErrorResponse: null,
    dokuLightLoading: false,
    dokuLightProducts: [],
    dokuLightProductCache: [],
    dokuLightScrollPosition: 0
};

export function reducer(state = initialState, action: DokuLightActions): State {
    switch (action.type) {
        case DokuLightActionTypes.LOAD_DOKU_LIGHT:
            return {
                ...state,
                dokuLightRequest: action.payload,
            };
        case DokuLightActionTypes.LOAD_DOKU_LIGHT_COMPLETE:
            return {
                ...state,
                dokuLightProductCache: [...state.dokuLightProductCache, ...action.payload.products],
                dokuLightResponse: action.payload,
                dokuLightLoading: false
            };
        case DokuLightActionTypes.LOAD_DOKU_LIGHT_FAIL:
            return {
                ...state,
                dokuLightErrorResponse: action.payload,
                dokuLightLoading: false
            };
        case DokuLightActionTypes.SEARCH_DOKU_LIGHT:
            return {
                ...state,
                dokuLightProducts: [],
                dokuLightProductCache: [],
                dokuLightLoading: true
            };
        case DokuLightActionTypes.UPDATE_SCROLLING_POSITION:
            return {
                ...state,
                dokuLightScrollPosition: action.payload
            };
        case DokuLightActionTypes.GET_BATCH_FROM_CACHE:
            return {
                ...state,
                dokuLightProducts: [...state.dokuLightProducts, ...state.dokuLightProductCache.slice(0, 20)],
                dokuLightProductCache: state.dokuLightProductCache.slice(20)
            };
        default:
            return state;
    }
}

export const getDokuLightLoading = (state: State) => state.dokuLightLoading;
export const getDokuLightResponse = (state: State) => state.dokuLightResponse;
export const getDokuLightRequest = (state: State) => state.dokuLightRequest;
export const getDokuLightErrorResponse = (state: State) => state.dokuLightErrorResponse;
export const getDokuLightProductCache = (state: State) => state.dokuLightProductCache;
export const getDokuLightProducts = (state: State) => state.dokuLightProducts;
export const getDokuLightScrollPosition = (state: State) => state.dokuLightScrollPosition;

<ng-container *ngIf="data.type === 'pkp1'">
  <h1 mat-dialog-title>Aufzeichnung: Webinar Cross-Selling</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://www.phoenix-online.de/fileadmin/media/newsletter/Cross_Selling_neu.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'pkp2'">
  <h1 mat-dialog-title>Erklärung und Zusammenfassung des Programms</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://www.phoenix-online.de/fileadmin/media/newsletter/Beauftragtenprogramm.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'hgf'">
  <h1 mat-dialog-title>Das neue PHOENIX Apothekenportal einfach erklärt</h1>
  <div mat-dialog-content>
    <iframe src="https://player.vimeo.com/video/365725973" width="640" height="360" frameborder="0"
            allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>


<ng-container *ngIf="data.type === 'lernwelt1'">
  <h1 mat-dialog-title>Was ist PAYBACK</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://cspappsacc.blob.core.windows.net/video/1 Was ist PAYBACK.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'lernwelt2'">
  <h1 mat-dialog-title>So werden Punkte gesammelt</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://cspappsacc.blob.core.windows.net/video/2 So werden Punkte gesammelt.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'lernwelt3'">
  <h1 mat-dialog-title>Punkte vervielfachen und einlösen</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://cspappsacc.blob.core.windows.net/video/3 Punkte vervielfachen und einlösen.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'lernwelt4'">
  <h1 mat-dialog-title>Anmeldung für Neukunden und Wichtigkeit des Datenschutzes</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://cspappsacc.blob.core.windows.net/video/4 Anmeldung für Neukunden und Wichtigkeit des Datenschutzes.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'lernwelt5'">
  <h1 mat-dialog-title>Wie Sie von PAYBACK profitieren können</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://cspappsacc.blob.core.windows.net/video/5 Wie Sie von PAYBACK profitieren können.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'lernwelt6'">
  <h1 mat-dialog-title>Kunden auf PAYBACK ansprechen</h1>
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://cspappsacc.blob.core.windows.net/video/6 Kunden auf PAYBACK ansprechen.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

<ng-container *ngIf="data.type === 'comm'">
<!--  <h1 mat-dialog-title>Der PAYBACK Joker 2024 – in Kürze erklärt</h1>-->
  <div mat-dialog-content>
    <video controls class="embed-responsive-item" width="640">
      <source src="https://cms.phoenix-apothekenportal.de/fileadmin/statische-inhalte/videos/2024-12_apothekenportal_benachrichtigungen_abonnieren.mp4"
              type="video/mp4">
      Ihr Browser unterstützt leider keine eingebetteten Videos.
    </video>
  </div>
  <div mat-dialog-actions>
    <button class="btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
  </div>
</ng-container>

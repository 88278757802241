import {Injectable} from '@angular/core';
import {RestServiceAbstract} from "../../abstracts/rest-service.abstract";
import {map, takeUntil} from "rxjs/operators";
   import {Observable, Subject} from "rxjs";
import {RegistrationAttemptRequest} from "../../models/registration/registration-attempt-request.model";
import {Response} from "../../models/response.model";
import {SetCredentialsTokenRequest} from "../../models/registration/set-credentials-token-request.model";
import {ICredentialsService} from "../../interfaces/credentials-service.interface";
import {IChangeDataService} from '../../interfaces/change-data-service.interface';
import {OwnerData} from '../../models/account/owner-data.model';

@Injectable({
    providedIn: 'root'
})
export class ManageAccountDataRestService extends RestServiceAbstract implements IChangeDataService {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();



    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }

    changeData(scr: any): Observable<Response> {
        return this.post(`/usermanagement/idf/changeData`, scr).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    changeOwner(owner: OwnerData): Observable<Response> {
        return this.post(`/usermanagement/idf/changeOwner`, owner).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        )
    }
}

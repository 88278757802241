import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs/internal/Observable";
import * as fromProFaxActions from '../actions/pro-fax.actions';
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {InvoicesRestService} from "../../services/api/invoices.rest-service";
import {WebshopRestService} from "../../services/api/webshop-rest.service";
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductType} from '../../models/shop/product-type.model';

@Injectable()
export class ProFaxEffects {
    /**
     * In case of SearchInvoicesAction we're going to call the InvoicesService and then
     * we'll post a LoadInvoicesCompleteAction to the reducer or in case of failure
     * LoadInvoicesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadProFax$: Observable<any> = this.actions$
        .pipe(ofType<fromProFaxActions.LoadProFaxAction>(fromProFaxActions.ProFaxActionTypes.LOAD_PROFAX))
        .pipe(map(action => action.payload), switchMap((productRequest) =>
            this.productRestService.loadProFax().pipe(
                switchMap(data => {
                    const prodResp = new ProductsResponse({...data.returnObject});
                    if (productRequest.page === 1) {
                        return [new fromProFaxActions.LoadProFaxCompleteAction(prodResp), new fromProFaxActions.GetBatchFromCacheAction()];
                    } else {
                        return [new fromProFaxActions.LoadProFaxCompleteAction(prodResp)]
                    }
                }),
                catchError((error) => of(new fromProFaxActions.LoadProFaxFailAction(error)))
            )
        ));

    @Effect()
    searchProFax$: Observable<any> = this.actions$
        .pipe(ofType<fromProFaxActions.SearchShopAction>(fromProFaxActions.ProFaxActionTypes.SEARCH_PROFAX))
        .pipe(map(action => action.payload), switchMap((productRequest) => [new fromProFaxActions.LoadProFaxAction(productRequest)]));

    /**
     * @param {InvoicesRestService} productRestService
     * @param {Actions} actions$
     */
    constructor(
        private productRestService: WebshopRestService,
        private actions$: Actions
    ) {
    }
}

import { EventEmitter, Injectable, Output} from '@angular/core';
import {Subject, BehaviorSubject, ReplaySubject, Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatbotService {
    public act = new BehaviorSubject<any>('mini');
    receivedRowSource = new Subject<any>();
    public size = this.act.asObservable();
    @Output() public resize = new EventEmitter<string>();

    constructor() {
        this.act.next('mini');
    }

    getStretch(){
        return this.size;
    }

    stretch(value: string) {
        this.act.next(value);
    }
}
<!-- PROCESSING //-->
<ng-container *ngIf="submitted && !success">
    <div class="row panel-header" *ngIf="submitted && !success">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <h1><span *ngIf="isQaVisible()" class="qaText">QA - Umgebung </span>Sie haben Ihre Zugangsdaten vergessen?
            </h1>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row panel-body">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div class="text-center py-l">
                <i class="fal fa-spinner-third fa-spin"></i>&nbsp;Ihre Anfrage wird übermittelt.
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</ng-container>
<!-- FORM //-->
<ng-container *ngIf="!submitted && !showErrorMessage">
    <div class="row panel-header">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <h1><span *ngIf="isQaVisible()" class="qaText">QA - Umgebung </span>Sie haben Ihre Zugangsdaten vergessen?
            </h1>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row panel-body mb-xxxl">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div id="accordion" class="accordion-style-1">
                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <button class="btn btn-link btn-collapsible" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="true" aria-controls="collapseTwo" type="button">
                            <h4>Sie sind IDF-Inhaber?</h4>
                        </button>
                    </div>
                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                            <p>Bitte geben Sie Ihre IDF-Nummer und die Postleitzahl Ihrer Apotheke ein.</p>
                            <div>
                                <form [formGroup]="resetPasswordForm" onsubmit="return false"
                                    [class.submitted]="submitButtonClicked" id="mtm-passwvergform">
                                    <div class="row">
                                        <div class="col-md-6 pl-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>IDF-Nummer *</mat-label>
                                                <input matInput autocomplete="off" name="idf"
                                                    placeholder="IDF-Nummer, z.B. 0123456" value=""
                                                    formControlName="idf">
                                                <mat-error *ngIf="idf.hasError('required') && submitButtonClicked">
                                                    Bitte geben Sie Ihre IDF ein.
                                                </mat-error>
                                                <mat-error *ngIf="idf.hasError('pattern')">Geben
                                                    Sie eine 7-stellige
                                                    Zahlenfolge ein
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>PLZ *</mat-label>
                                                <input matInput autocomplete="off" name="zipCode" value=""
                                                    formControlName="zipCode">
                                                <mat-error *ngIf="zipCode.hasError('required') && submitButtonClicked">
                                                    Bitte geben Sie Ihre Postleitzahl ein.
                                                </mat-error>
                                                <mat-error *ngIf="zipCode.hasError('pattern')">
                                                    Bitte geben Sie eine gültige Postleitzahl ein.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 text-right order-2">
                                            <button class="btn btn-primary" type="submit"
                                                    (click)="submit(); $event.stopPropagation()">Senden
                                            </button>
                                        </div>
                                        <div class="col-md-6 text-left pl-0 order-1">
                                                <button class="btn btn-secondary" (click)="backToLogin()">Zurück zum Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <button class="btn btn-link btn-collapsible collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <h4>Sie sind Mitbenutzer?</h4>
                        </button>
                    </div>
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <p>Bitte wenden Sie sich an den IDF-Inhaber Ihrer Apotheke. Dieser kann im Apothekenportal
                                unter "Meine persönlichen Einstellungen" → "Mitbenutzer" Ihr Passwort neu vergeben.</p>
                            <a routerLink="/login">
                                <button class="btn btn-secondary">Zurück zum Login</button>
                            </a>
                        </div>
                    </div>
                </div>
                <!--<div class="card">
                    <div class="card-header" id="headingThree">
                        <button class="btn btn-link btn-collapsible collapsed" type="button" data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">
                            <h4>Sie sind Mitarbeiter bei PHOENIX?</h4>
                        </button>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div class="card-body">

                        </div>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</ng-container>
<!-- SUCCESS //-->
<success *ngIf="submitted && success"
             message="Zugangsdaten erfolgreich angefordert"
             html="Eine E-Mail wurde an folgende E-Mail-Adresse gesendet:<h4 class='my-m'> {{response.returnObject}}
                  </h4>Sollten Sie Ihre E-Mail-Adresse geändert oder vergessen haben, nutzen Sie bitte unser
                    <a href='#/kontakt' class='link'>Kontaktformular</a>.">
</success>
<!--ERROR-->
<error *ngIf="showErrorMessage"
       message="Ihre Anfrage konnte nicht entgegengenommen werden."
       showButton="Zurück"
       html="<h3>Das liegt möglicherweise an:</h3>
               <ul>
               <li>Einem Schreibfehler bei Eingabe der Haupt-IDF oder Postleitzahl</li>
               <li>Sie haben versucht, die Zugangsdaten für eine Filiale zurückzusetzen.
               Dies ist nicht möglich. Bitte wenden Sie sich an den/die Inhaber/in.</li>
                ">
</error>

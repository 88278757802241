import {SpecialOffersActions, SpecialOffersActionTypes} from '../actions/special-offers.actions';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';


export interface State {
    specialOffersRequest: ProductRequest;
    specialOffersResponse: ProductsResponse;
    specialOffersErrorResponse: HttpErrorResponse;
    specialOffersLoading: boolean,
    specialOffersProducts: Product[];
    specialOffersProductCache: Product[];
    specialOffersScrollPosition: number;
}

export const initialState: State = {
    specialOffersRequest: null,
    specialOffersResponse: null,
    specialOffersErrorResponse: null,
    specialOffersLoading: false,
    specialOffersProducts: [],
    specialOffersProductCache: [],
    specialOffersScrollPosition: 0
};

export function reducer(state = initialState, action: SpecialOffersActions): State {
    switch (action.type) {
        case SpecialOffersActionTypes.LOAD_PIN:
            return {
                ...state,
                specialOffersRequest: action.payload,
            };
        case SpecialOffersActionTypes.LOAD_PIN_COMPLETE:
            return {
                ...state,
                specialOffersProductCache: [...state.specialOffersProductCache, ...action.payload.products],
                specialOffersResponse: action.payload,
                specialOffersLoading: false
            };
        case SpecialOffersActionTypes.LOAD_PIN_FAIL:
            return {
                ...state,
                specialOffersErrorResponse: action.payload,
                specialOffersLoading: false
            };
        case SpecialOffersActionTypes.SEARCH_PIN:
            return {
                ...state,
                specialOffersProducts: [],
                specialOffersProductCache: [],
                specialOffersLoading: true
            };
        case SpecialOffersActionTypes.UPDATE_SCROLLING_POSITION:
            return {
                ...state,
                specialOffersScrollPosition: action.payload
            };
        case SpecialOffersActionTypes.GET_BATCH_FROM_CACHE:
            return {
                ...state,
                specialOffersProducts: [...state.specialOffersProducts, ...state.specialOffersProductCache.slice(0, 20)],
                specialOffersProductCache: state.specialOffersProductCache.slice(20)
            };
        default:
            return state;
    }
}

export const getSpecialOffersLoading = (state: State) => state.specialOffersLoading;
export const getSpecialOffersRequest = (state: State) => state.specialOffersRequest;
export const getSpecialOffersResponse = (state: State) => state.specialOffersResponse;
export const getSpecialOffersErrorResponse = (state: State) => state.specialOffersErrorResponse;
export const getSpecialOffersProductCache = (state: State) => state.specialOffersProductCache;
export const getSpecialOffersProducts = (state: State) => state.specialOffersProducts;
export const getSpecialOffersScrollPosition = (state: State) => state.specialOffersScrollPosition;
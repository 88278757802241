export class TrackRequestModel {
    branchNo: number;
    idf: any;
    requestDate: string;
    detailsDesired: boolean;
    tourId: number;

    constructor(branch, idf, date, details, tourID) {
        this.idf = idf || null;
        this.requestDate = date || null;
        this.branchNo = branch || null;
        this.detailsDesired = details || false;
        this.tourId = tourID || null;
    }
}
import {Action} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {ProductRequest} from "../../models/shop/product-request.model";

export enum ExtraPrismaActionTypes {
    LOAD_EXTRAPRISMA = '[EXTRAPRISMA] Load EXTRAPRISMA',
    LOAD_EXTRAPRISMA_COMPLETE = '[EXTRAPRISMA] Load EXTRAPRISMA Complete',
    LOAD_EXTRAPRISMA_FAIL = '[EXTRAPRISMA] Load EXTRAPRISMA FAIL',
    SEARCH_EXTRAPRISMA = '[EXTRAPRISMA] Search',
    UPDATE_SCROLLING_POSITION = '[EXTRAPRISMA] Update Scrolling Position',
    GET_BATCH_FROM_CACHE = '[EXTRAPRISMA] Push batch in visible list'
}

export class LoadExtraPrismaAction implements Action {
    readonly type = ExtraPrismaActionTypes.LOAD_EXTRAPRISMA;

    constructor(public payload: ProductRequest) { // == Query
    }
}

export class LoadExtraPrismaCompleteAction implements Action {
    readonly type = ExtraPrismaActionTypes.LOAD_EXTRAPRISMA_COMPLETE;

    constructor(public payload: ProductsResponse) {
    }
}

export class LoadExtraPrismaFailAction implements Action {
    readonly type = ExtraPrismaActionTypes.LOAD_EXTRAPRISMA_FAIL;

    constructor(public payload: HttpErrorResponse) {};
}

export class SearchShopAction implements Action {
    readonly type = ExtraPrismaActionTypes.SEARCH_EXTRAPRISMA;

    constructor(public payload: ProductRequest) {};
}

export class UpdateScrollingPositionAction implements Action {
    readonly type = ExtraPrismaActionTypes.UPDATE_SCROLLING_POSITION;

    constructor(public payload: number) {};
}

export class GetBatchFromCacheAction implements Action {
    readonly type = ExtraPrismaActionTypes.GET_BATCH_FROM_CACHE;

    constructor() {};
}



export type ExtraPrismaActions =
    LoadExtraPrismaAction |
    LoadExtraPrismaCompleteAction |
    LoadExtraPrismaFailAction |
    SearchShopAction |
    UpdateScrollingPositionAction |
    GetBatchFromCacheAction;
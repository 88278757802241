import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {ResponseSnakeModel} from '../../../models/response-snake.model';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PagesRestService extends RestServiceAbstract {

    getPage(page: any) {
       return this.get('/cms/pages/' + page).pipe(map(data => {
            return new ResponseSnakeModel(data);
        }));
    }
}

import {CartActions, CartActionTypes} from '../actions/cart.actions';
import {CartItem} from '../../models/shop/cart-item.model';
import {Totals} from '../../models/totals.model';
import {ProductType} from '../../models/shop/product-type.model';


export interface State {
    cartItems: CartItem[];
    latestItems: CartItem[];
    productTypes: ProductType[];
    totals: Totals;
}

export const initialState: State = {
    cartItems: [],
    latestItems: [],
    productTypes: [],
    totals: new Totals()
};

export function reducer(state = initialState, action: CartActions): State {
    switch (action.type) {
        case CartActionTypes.ADD_CART_ITEM_ACTION:
            let addCartItems = [];
            let addQuantitiyTotal = 0;
            let addPriceTotal = 0;
            state.cartItems.forEach((item) => {
                if (item.product && action.payload.map(p => p.product.id).indexOf(item.product.id) < 0) {
                    addCartItems.push(item);
                    addQuantitiyTotal += +item.quantity;
                    addPriceTotal += (<number>item.quantity * <number>item.price);
                }
            });

            action.payload.forEach((item) => {
                addQuantitiyTotal += +item.quantity;
                addPriceTotal += (<number>item.quantity * <number>item.price);
                addCartItems.push(item);
            });

            const types = state.productTypes;
            const evalType = <ProductType>(action.payload[0].product.type.toString() + action.payload[0].product.subType.toString());
            if (state.productTypes.indexOf(evalType) === -1) {
                types.push(<ProductType>(action.payload[0].product.type + action.payload[0].product.subType));
            }

            return {
                ...state,
                cartItems: addCartItems,
                productTypes: types,
                latestItems: action.payload,
                totals: new Totals({quantityTotal: addQuantitiyTotal, total: addPriceTotal})
            };
        case CartActionTypes.UPDATE_CART_ITEM_ACTION:
            let updateCartItems = [];
            let updateQuantitiyTotal = 0;
            let updatePriceTotal = 0;
            state.cartItems.forEach((item) => {
                if (item.product && action.payload.quantity > 0 && item.product.id === action.payload.product.id) {
                    item.quantity = +action.payload.quantity;
                    updateCartItems.push(item);
                    updateQuantitiyTotal += +item.quantity;
                    updatePriceTotal += (<number>item.quantity * <number>item.price);
                }
                if (item.product && item.product.id != action.payload.product.id) {
                    updateCartItems.push(item);
                    updateQuantitiyTotal += +item.quantity;
                    updatePriceTotal += (<number>item.quantity * <number>item.price);
                }
            });
            return {
                ...state,
                cartItems: updateCartItems,
                totals: new Totals({quantityTotal: updateQuantitiyTotal, total: updatePriceTotal}),

            };
        case CartActionTypes.UPDATE_CART_ITEMS_ACTION:
            return {
                ...state,
                cartItems: action.payload
            };
        case CartActionTypes.REMOVE_CART_ITEM_ACTION:
            let newCartItems = [];
            let removeQuantitiyTotal = 0;
            let removePriceTotal = 0;
            let removeTypes = [];
            state.cartItems.forEach((item) => {
                if (item.product.id !== action.payload) {
                    newCartItems.push(item);
                    removeQuantitiyTotal += +item.quantity;
                    removePriceTotal += (<number>item.quantity * <number>item.price);
                }
                if (state.productTypes.indexOf(item.product.type) === -1) {
                    removeTypes.push(item.product.type);
                }
            });
            return {
                ...state,
                cartItems: newCartItems,
                productTypes: removeTypes,
                totals: new Totals({quantityTotal: removeQuantitiyTotal, total: removePriceTotal})
            };
        case CartActionTypes.CLEAR_CART_ACTION:
            let clearCartItems = [];
            return {
                ...state,
                cartItems: clearCartItems,
                totals: new Totals()
            };
        default:
            return state;
    }
}

export const getCartItems = (state: State) => state.cartItems;
export const getLatestCartItems = (state: State) => state.latestItems;
export const getTotals = (state: State) => state.totals;
export const getProductTypes = (state: State) => state.productTypes;

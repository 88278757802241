import {ProductDetails} from './product-details.model';
import {ProductType} from './product-type.model';
import {ProfaxType} from './profax-type.model';
import {DeliveryInformation} from './delivery-information.model';
import {ProductAvailability} from './product-availability.model';
import {AvailabilityStatus} from './product-availability-status.model';
import {ProductContingentType} from './product-contingent-type.model';

export class Product {

    description: string;
    image: string;
    manufacturer: string;
    name: string;
    content: string;

    id: string;
    saleStatus: string;
    details: ProductDetails;

    packageProducts: any[];
    price: any;
    retailPrice: number;
    amountOfPrices: number;
    minQuantity: number;
    thumb: string;
    type: ProductType;
    subType: ProfaxType;

    country: string;
    minimalOrder: string;
    delivery: string;
    deliveryNotice: string;
    vat: number;
    deliveryInformation: DeliveryInformation;

    campaignId: string;
    advantage: string;
    available: boolean;
    canCallAvailability: boolean;
    shipping: number;
    promotion: any;
    availability: ProductAvailability;
    availabilityStatus: AvailabilityStatus; // Add availabilityStatus

    constructor(obj: any = {}) {
        this.description = obj && obj.description || null;
        this.image = obj && obj.image || null;
        this.manufacturer = obj && obj.manufacturer || null;
        this.name = obj && obj.name || null;
        this.id = obj && obj.id || null;
        this.saleStatus = obj && obj.saleStatus || null;
        this.content = obj && obj.content || null;

        this.description = obj && obj.description || null;
        this.packageProducts = obj && obj.packageProducts || [];
        this.price = obj && obj.price || [{cents: 0, minQuantity: 1}];
        this.amountOfPrices = 0;

        this.country = obj && obj.country || null;
        this.minimalOrder = obj && obj.minimalOrder || null;
        this.delivery = obj && obj.delivery || null;
        this.deliveryNotice = obj && obj.deliveryNotice || null;
        this.vat = obj && obj.vat || 19;
        this.shipping = obj && obj.shipping || null;
        this.deliveryInformation = obj && obj.deliveryInformation || null;
        this.promotion = obj && obj.promotion || null;
        this.availability = obj && obj.availability || null;

        /*
         * need to determine how many prices there are in order to display only the last one
         * i can't cancel out all other prices because they are required for calculation
         * could array this.price but would have to adjust logic which works well so i won't
         */
        let min = null;
        let minkey = null;
        for (const key in this.price) {
            const pr = this.price[key].cents / 100;
            if (this.price[key].minQuantity === 0) {
                this.retailPrice = pr;
                minkey = key;
                continue;
            }

            if (this.price[key].minQuantity === 1) {
                min = pr;
            }

            this.price[key].cents = pr;
            this.amountOfPrices++;
        }
        if (minkey != null) { this.price.splice(minkey, 1); }

        if (!this.retailPrice) {
          this.retailPrice = min;
        }

        this.thumb = obj && obj.thumb || null;
        if (obj && obj.type && obj.type.indexOf('profax') > -1) {
            this.type = ProductType.Profax;
            this.subType = obj.type.replace('profax', '');
        } else if (obj && obj.type) {
            this.type = obj.type;
            this.subType = ProfaxType.NONE;
        } else {
            this.type = ProductType.NONE;
            this.subType = ProfaxType.NONE;
        }
        this.details = obj && obj.details || null;
        this.campaignId = obj && obj.campaignId || null;
        this.advantage = obj && obj.advantage || 'N/A';
        this.available = obj && obj.available || false;
        if (obj && obj.hasOwnProperty('canCallAvailability')) {
            this.canCallAvailability = obj.canCallAvailability;
        } else {
            this.canCallAvailability = (this.subType != ProfaxType.Email);
        }

        // Set the availabilityStatus based on the logic
        this.availabilityStatus = this.determineAvailabilityStatus();
    }

    getMindestbestellmenge() {
        if (this.deliveryNotice && this.deliveryNotice.indexOf('Mindestbestellmenge') >= 0) {
            return this.deliveryNotice.split(' ')[1];
        }
        return null;
    }

    determineAvailabilityStatus(): AvailabilityStatus {
        const contingentType = this.availability?.contingents[0]?.type;

        if (this.promotion) {
            return AvailabilityStatus.ENABLE;
        }

        switch (contingentType) {
            case ProductContingentType.Normal:
            case ProductContingentType.Combination:
                return AvailabilityStatus.ENABLE;

            case ProductContingentType.SubsequentDelivery:
                return AvailabilityStatus.SUBSEQUENT_ORDER;

            case ProductContingentType.Disposition:
            case ProductContingentType.NotDeliverable:
                return AvailabilityStatus.DISABLE;

            default:
                return AvailabilityStatus.DISABLE;
        }
    }

}

/*
    "pzn": "14046738",
    "image": "https:\/\/pics.phoenix-online.de\/UJF2NkWIeynJdHygCTWdymg5OQAR8Tcl-DzrQYgosdejn7-eQ6KPlJk7cG1a95kt.jpg",
    "name": "BD ULTRA FINE PRO4MM PENNA",
    "aek": 2478,
    "avk": 0,
    "lieferform": "KAN",
    "hersteller": "BECTON DICKINSON GMBH",
    "menge": "105",
    "einheit": "ST",
    "preis": {"1": "1452"}
*/

import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormsRestService} from '../../../../core/services/api/cms/forms.rest-service';
import {Router} from '@angular/router';
import {FormBuilder, Validators, FormGroupDirective} from '@angular/forms';
import {FormsModel} from '../../../../core/models/cms/forms.model';
import {Answer, FormAnswers} from '../../../../core/models/cms/form-answers.model';
import {MatomoService} from '../../../../core/services/matomo.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit, OnDestroy {

    @Input() form;
    @Input() public;
    @Input() rating = false;
    public loading = true;
    public dynamicForm;
    public fieldsList: FormsModel;
    public successText = false;
    public errorText = false;
    private submitted = false;
    public ngUnsubscribe$ = new Subject<any>();
    public selected = 0;
    @Output() success = new EventEmitter<any>();
    @ViewChild('dynamicFormDirective') dynamicFormDirective: FormGroupDirective;

    constructor(private formsRestService: FormsRestService,
                private formBuilder: FormBuilder,
                private router: Router,
                private datePipe: DatePipe,
                private matomoService: MatomoService) {
    }

    ngOnInit(): void {
        this.formsRestService.getForm(this.form).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(f => {
            this.fieldsList = new FormsModel(f.return_object);
            this.dynamicForm = this.formBuilder.group({});
            this.addFields(this.fieldsList.fields_list);
            this.loading = false;
        });
    }

    submit() {
        this.submitted = true;
        if (!this.dynamicForm.valid) {
            return;
        }

        const formValue = this.dynamicForm.value;
        let answrs = [];
        let fields = this.fieldsList.fields_list.sort((a, b) => (a.sort - b.sort));
        for (const field of fields) {
            const quest = field.id;
            let answer = formValue['field' + field.id];
            if (field.label.toLowerCase() === 'rating') {
                answer = formValue['rating'];
            }
            if(field.type === 'DATE') {
                const date = new Date(formValue['field' + field.id])
                answer = this.datePipe.transform(date, 'fullDate', 'GMT+2', 'de-DE');
            }
            answrs.push(new Answer(quest, answer));
        }
        const request = new FormAnswers({form: this.fieldsList.id, answers: answrs});
        this.formsRestService.submitForm(request, this.public).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {

            if (res.return_object === true) {
                this.matomoService.trackEvent('Klick auf', this.fieldsList.submit_label, this.fieldsList.name);

                if (this.fieldsList.redirect_to !== null) {
                    this.router.navigateByUrl(this.fieldsList.redirect_to);
                }
                if (this.fieldsList.success_text !== null) {
                    this.successText = true;
                }
                this.dynamicFormDirective.resetForm();
                this.dynamicForm.reset();
                this.success.next(true);
            } else {
                this.errorText = true;
                this.success.next(false);
            }
        });
    }

    addFields(fields) {
        let formFields = [];
        fields.sort((a, b) => (a.sort - b.sort));
        for (const field of fields) {
            let control;
            if (field.required) {
                control = this.formBuilder.control('', Validators.required);
            } else {
                control = this.formBuilder.control('');
            }
            if (field.label.toLowerCase() === 'rating') {
                this.dynamicForm.addControl('rating', control);
            }
            this.dynamicForm.addControl('field' + field.id, control);
        }
    }

    getFormName(name) {
        return name.replace(' ', '-').toLowerCase();
    }

    getFormError(fieldName) {
        return this.submitted && this.dynamicForm.hasError('required', fieldName);
    }

    setRating(rating) {
        this.selected = rating;
        this.dynamicForm.patchValue({rating: rating});
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IntegrationsComponent} from './integrations.component';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {DatevFormComponent} from './datev/datev-form/datev-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {NotificationModule} from '../../../shared/notification/notification.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DatevLogsDialog} from './datev/datev-form/datev-form.component';
import {DatevComponent} from './datev/datev.component';
import {TreuhandComponent} from './treuhand/treuhand.component';
import {SpinnerModule} from '../../../shared/spinner/spinner.module';
import { TreuhandCopyModalComponent } from './treuhand/treuhand-copy-modal/treuhand-copy-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';

export const routes = [
    {
        path: '',
        children: [
            {path: '', component: IntegrationsComponent, pathMatch: 'full'},
            {path: 'datev', component: DatevComponent, pathMatch: 'full'},
            {path: 'treuhand', component: TreuhandComponent, pathMatch: 'full'},
        ]
    }
];

@NgModule({
    declarations: [
        IntegrationsComponent,
        DatevFormComponent,
        DatevLogsDialog,
        DatevComponent,
        TreuhandComponent,
        TreuhandCopyModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        NotificationModule,
        MatSlideToggleModule,
        FormsModule,
        CommonModule,
        SpinnerModule,
        CommonModule,
        MatDialogModule,
        TranslateModule,
        MatButtonModule,
    ]
})
export class IntegrationsModule {
    static routes = routes;
}

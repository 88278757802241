import { SubsequentDeliveryUpdateModel } from './subsequent.delivery.update.model';

export class SubsequentDeliveriesUpdate {
    branchnumber: string;
    customernumber: string;
    subsequentdeliveries = [];

    constructor(obj: any = {}) {
        this.branchnumber = obj.branchnumber;
        this.customernumber = obj.customernumber;
        this.subsequentdeliveries.push(new SubsequentDeliveryUpdateModel(obj));
    }
}

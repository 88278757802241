import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map} from 'rxjs/operators';
import {Response} from '../../models/response.model';

@Injectable({
  providedIn: 'root'
})

export class CommunicationRestService extends RestServiceAbstract {
  getConfigs(idf: string) {
    return this.get(`/usermanagement/communication/config/${idf}`).pipe(map(data => {
      return new Response(data);
    }));
  }

  saveConfig(idf: any, data: Object) {
    return this.post(`/usermanagement/communication/config/${idf}`, data).pipe(map(response => {
      return new Response(response);
    }));
  }

  disable(idf: string, type: string) {
    return this.put(`/usermanagement/communication/config/disable/${idf}/${type}`, {}).pipe(map(response => {
      return new Response(response);
    }));
  }
}

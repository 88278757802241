import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {ResponseSnakeModel} from '../../../models/response-snake.model';
import {Injectable} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FormsRestService extends RestServiceAbstract {

    getForm(form: any) {
        return this.get('/cms/forms/' + form).pipe(map(data => {
            return new ResponseSnakeModel(data);
        }));
    }

    submitForm(request: any, isPublic: boolean) {
        if (isPublic) {
            return this.post('/cms/forms/open', request).pipe(map(data => {
                return new ResponseSnakeModel(data);
            }));
        } else {
            return this.post('/cms/forms', request).pipe(map(data => {
                return new ResponseSnakeModel(data);
            }));
        }
    }
}
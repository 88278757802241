import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import * as fromReducers from './core/store/reducers';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(@Inject(TranslateService) public translate: TranslateService,
                public store: Store<fromReducers.State>) {
        translate.addLangs(['de']);
        translate.setDefaultLang('de');
    }

    ngOnInit() {
        for (let i = 0; i < 1000; i++) {
            clearInterval(i);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import {Product} from "../core/models/shop/product.model";

@Component({
  selector: 'copy-paste-elements',
  templateUrl: './copy-paste-elements.component.html',
  styleUrls: ['./copy-paste-elements.component.scss']
})
export class CopyPasteElementsComponent implements OnInit {
  public productMockAvailable: Product;
  public productMockUnavailable: Product;

  constructor() { }

  ngOnInit() {
    this.initProductMock();
  }

  initProductMock(){
    this.productMockAvailable = new Product({
        id: 1,
      pzn: 11583861,
      name: 'RAVICTI 1,1 g/ml 25ml + 7x5 ml Applikationsspr.',
      manufacturer: 'Swedish Orphan Biovitrum GmbH',
      content: ' 1x25 Milliliter',
      shortDescription: 'Darreichung: Flüssigkeit zum Einnehmen',
      description: '',
      price: [{minQuantity: 1, cents: 0}],
      aek: 55555,
      minQuantity: 1,
      thumb: 'https://images.medpex.de/medias/87457/iWTSrUb5D9s37DeVnFIVka-30.jpg',
      type: null,
      available: true,
    });
    this.productMockUnavailable = new Product({
        id: 1,
      pzn: 11583861,
      name: 'RAVICTI 1,1 g/ml 25ml + 7x5 ml Applikationsspr.',
      manufacturer: 'Swedish Orphan Biovitrum GmbH',
      content: ' 1x25 Milliliter',
      shortDescription: 'Darreichung: Flüssigkeit zum Einnehmen',
      description: '',
      price: [{minQuantity: 1, cents: 161}],
      aek: 55555,
      minQuantity: 1,
      thumb: 'https://images.medpex.de/medias/87457/iWTSrUb5D9s37DeVnFIVka-30.jpg',
      type: null,
      available: false
    });
  }

}

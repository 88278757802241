import {Inject, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as fromReducers from "../../store/reducers";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {WebshopRestService} from "../api/webshop-rest.service";
import {ProductServiceAbstract} from "./product-service.abstract";
import {ProductRequest} from "../../models/shop/product-request.model";
import {Product} from '../../models/shop/product.model';
import {
    UpdateScrollingPositionAction,
    SearchShopAction,
    GetBatchFromCacheAction,
    LoadProFaxAction,
    UpdateProfaxCart, EmptyProfaxCart
} from '../../store/actions/pro-fax.actions';

@Injectable()
export class ProFaxService extends ProductServiceAbstract {
    constructor(public store: Store<fromReducers.State>,
                @Inject(WebshopRestService) public webshopRestService: WebshopRestService) {
        super(store, webshopRestService);
    }

    private _proFaxCart$: Observable<any>;
    get proFaxCart$(): Observable<any> {
        return this._proFaxCart$;
    }
    set proFaxCart$(value: Observable<any>) {
        this._proFaxCart$ = value;
    }

    updateScrollingPosition(pos: number): void {
        this.store.dispatch(new UpdateScrollingPositionAction(pos));
    }

    searchProducts(request: ProductRequest = new ProductRequest({})): void {
        this.store.dispatch(new SearchShopAction(request));
    }

    displayNewBatch(request: ProductRequest = new ProductRequest({})): void {

    }

    /**
     * GETTERS
     */
    getProductsLoadingObservable(): Observable<boolean> {
        this.productsLoading = this.store.pipe(select(fromReducers.getProFaxLoading));
        return this.productsLoading;
    }

    getProductsResponseObservable(): Observable<ProductsResponse> {
        this.productsResponse$ = this.store.pipe(select(fromReducers.getProFaxResponse));
        //this.checkAvailability();
        return this.productsResponse$;
    }

    getProductsRequestObservable(): Observable<ProductRequest> {
        this.productsRequest$ = this.store.pipe(select(fromReducers.getProFaxRequest));
        //this.checkAvailability();
        return this.productsRequest$;
    }

    getProductsObservable(): Observable<Product[]> {
        this.products$ = this.store.pipe(select(fromReducers.getProFaxProducts));
        return this.products$;
    }

    getScrollPositionObservable(): Observable<number> {
        this.scrollPosition$ = this.store.pipe(select(fromReducers.getProFaxScrollPosition));
        return this.scrollPosition$;
    }

    getProductCacheObservable(): Observable<Product[]> {
        this.productCache$ = this.store.pipe(select(fromReducers.getProFaxProductCache));
        return this.productCache$;
    }

    getProFaxCartObservable(): Observable<any> {
        this.proFaxCart$ = this.store.pipe(select(fromReducers.getProFaxCart));
        return this.proFaxCart$;
    }

    updateProfaxCart(amount: number, product: Product): void {
        const updateProfaxCartAction: UpdateProfaxCart = new UpdateProfaxCart(
            {
                id: product.id + product.campaignId,
                cartItem: {amount, product}
            });
        this.store.dispatch(updateProfaxCartAction);
    }

    emptyProfaxCart(campaignId: string): void {
        const emptyProfaxCartAction: EmptyProfaxCart = new EmptyProfaxCart(campaignId);
        this.store.dispatch(emptyProfaxCartAction);
    }
}
<div class="error-container">
    <div class="error-pane">
        <h1>{{error?.status ? error.status : '404'}}</h1>
        <h3>{{error?.error?.error ? error.error.error : 'Seite nicht gefunden'}}</h3>
        <h5 *ngIf="error?.message">
            {{  error?.message }}
        </h5>
        <hr/>
        <h2>
            {{ error?.error?.message ? (('MESSAGE.ERROR.' + error?.error?.message) | translate) : 'Leider konnten wir die von Ihnen gesuchte Seite nicht finden.' }}
        </h2>
        <p class="text-center" *ngIf="error?.status && error?.error?.error">
            Tragen sie zur Verbesserung des Apothekenportals bei: <a
                href="mailto:{{ADMIN_MAIL}}?subject=PHX Portal Fehler | {{ error.status +' '+ error.error.error }} &body={{ errorJsonForMailBody }}">Klicken
            Sie hier, um
            diesen Fehler zu melden.</a>
        </p>
        <a onclick="history.back()" class="float-left">
            <button class="btn-secondary mr-xs">Zurück</button>
        </a>
        <a href="/" class="float-right">
            <button class="btn-primary">zur Startseite</button>
        </a>
    </div>
</div>

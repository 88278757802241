import {HttpErrorResponse} from '@angular/common/http';
import {RetourenActions, RetourenActionTypes} from '../actions/retouren.actions';

export interface State {
    retourenResponse: any;
    retourenLoading: boolean;
    retourenErrorResponse: HttpErrorResponse;
    retourenAnnouncementResponse: any;
    retourenAnnouncementLoading: boolean;
    retourenAnnouncementErrorResponse: HttpErrorResponse;
}

export const initialState: State = {
    retourenResponse: null,
    retourenLoading: false,
    retourenErrorResponse: null,
    retourenAnnouncementResponse: null,
    retourenAnnouncementLoading: false,
    retourenAnnouncementErrorResponse: null
};

export function reducer(state = initialState, action: RetourenActions): State {
    switch (action.type) {
        case RetourenActionTypes.RETOUREN_REQUEST:
            return {
                ...state,
                retourenLoading: true,
                retourenErrorResponse: null,
                retourenResponse: null
            };
        case RetourenActionTypes.RETOUREN_REQUEST_COMPLETE:
            return {
                ...state,
                retourenLoading: false,
                retourenResponse: action.payload
            };
        case RetourenActionTypes.RETOUREN_REQUEST_FAIL:
            return {
                ...state,
                retourenLoading: false,
                retourenErrorResponse: action.payload
            };
        case RetourenActionTypes.RETOUREN_ANNOUNCEMENT:
            return {
                ...state,
                retourenAnnouncementLoading: true,
                retourenAnnouncementResponse: null
            };
        case RetourenActionTypes.RETOUREN_ANNOUNCEMENT_COMPLETE:
            return {
                ...state,
                retourenAnnouncementLoading: false,
                retourenAnnouncementResponse: action.payload
            };
        case RetourenActionTypes.RETOUREN_ANNOUNCEMENT_FAIL:
            return {
                ...state,
                retourenAnnouncementLoading: false,
                retourenAnnouncementErrorResponse: action.payload
            };
        default:
            return state;
    }
}

export const getRetourenLoading = (state: State) => state.retourenLoading;
export const getRetourenResponse = (state: State) => state.retourenResponse;
export const getRetourenError = (state: State) => state.retourenErrorResponse;
export const getRetourenAnnouncementLoading = (state: State) => state.retourenAnnouncementLoading;
export const getRetourenAnnouncementResponse = (state: State) => state.retourenAnnouncementResponse;
export const getRetourenAnnouncementError = (state: State) => state.retourenAnnouncementErrorResponse;

import {SubUserActions, SubUserActionTypes} from '../actions/sub-user.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {SubUserModelRepository} from '../../../modules/account/components/sub-user-management/repository/sub-user-model.repository';
import {AuthorizationNodeModel} from '../../../modules/account/components/sub-user-management/model/authorization-node.model';


export interface State {
    subUsers: SubUserModelRepository[];
    subUsersError: HttpErrorResponse;
    subUsersLoading: boolean;
    authTree: AuthorizationNodeModel[];
    authTreeError: HttpErrorResponse;
    authTreeLoading: boolean;
}

export const initialState: State = {
    subUsers: [],
    subUsersError: null,
    subUsersLoading: false,
    authTree: null,
    authTreeError: null,
    authTreeLoading: true
};

export function reducer(state = initialState, action: SubUserActions): State {
    switch (action.type) {
        case SubUserActionTypes.LoadSubUsers:
            return {
                ...state,
                subUsersLoading: true
            };
        case SubUserActionTypes.LoadSubUsersComplete:
            return {
                ...state,
                subUsers: action.payload.users,
                subUsersLoading: false
            };
        case SubUserActionTypes.LoadSubUsersFailed:
            return {
                ...state,
                subUsersError: action.payload,
                subUsersLoading: false
            };
        case SubUserActionTypes.InitAuthTree:
            return {
                ...state,
                authTreeLoading: true
            };
        case SubUserActionTypes.InitAuthTreeComplete:
            const authTree = [new AuthorizationNodeModel({ label: 'SUBUSER.ALL_RIGHTS' })];
            authTree[0].children = action.payload.map(node => new AuthorizationNodeModel(node));
            return {
                ...state,
                authTree: authTree,
                authTreeLoading: false
            };
        case SubUserActionTypes.InitAuthTreeFailed:
            return {
                ...state,
                subUsersError: action.payload,
                authTreeLoading: false
            };
        case SubUserActionTypes.AddSubUser:
            const addSubUsers = state.subUsers;
            addSubUsers.push(action.payload);
            return {
                ...state,
                subUsers: addSubUsers
            };
        case SubUserActionTypes.UpdateSubUser:
            const updateSubUsers = [];
            state.subUsers.forEach((subUser) => {
                if (subUser.id === action.payload.id) {
                    updateSubUsers.push(action.payload);
                } else {
                    updateSubUsers.push(subUser);
                }
            });
            return {
                ...state,
                subUsers: updateSubUsers
            };
        case SubUserActionTypes.RemoveSubUser:
            const removeSubUsers = [];
            state.subUsers.forEach((subUser) => {
                if (subUser.id !== action.payload) {
                    removeSubUsers.push(subUser);
                }
            });
            return {
                ...state,
                subUsers: removeSubUsers
            };
        default:
            return state;
    }
}

export const getSubUsers = (state: State) => state.subUsers;
export const getSubUsersLoading = (state: State) => state.subUsersLoading;
export const getSubUsersError = (state: State) => state.subUsersError;
export const getInitAuthTree = (state: State) => state.authTree;
export const getAuthTreeLoading = (state: State) => state.authTreeLoading;

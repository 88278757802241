import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'public',
    templateUrl: './view-public.component.html',
    styleUrls: ['./view-public.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewPublicComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}

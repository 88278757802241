import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {NavService} from '../nav.service';
import {Observable} from 'rxjs';
import {UserService} from '../user.service';

/**
 * this service can be used with any route canActivate i.e retouren.module.ts
 */

@Injectable()
export class RouteGuardService implements CanActivate {

    constructor(public navService: NavService,
                private userService: UserService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.router.navigated) {
            return this.navService.checkRights(state.url);
        } else {
            const nav = this.userService.getNav();
            const node = nav.getNodeByRoute(state.url);
            if (node) {
                if (this.navService.isVisibleForUser(node)) {
                    return true;
                } else {
                    this.router.navigate(['/error']);
                    return false;
                }
            } else {
                this.router.navigate(['/error']);
                return false;
            }
        }
    }
}

/**
 * represent the basic user model
 */

export class User {
    title: string;
    name: string;
    lastName: string;
    authorities: number[];
    authoritiesEnum: string[];
    username: string;
    salutation: string;
    featuresEnum: string[];
    canary: boolean;

    constructor(obj: any) {
        this.title = obj && obj.title || '';
        this.name = obj && obj.name || null;
        this.lastName = obj && obj.lastName || null;
        this.salutation = obj && obj.userSalutation || null;
        this.username = obj && obj.username || null;
        this.authorities = obj && obj.authorities || [];
        this.authoritiesEnum = obj && obj.authoritiesEnum || [];
        this.featuresEnum = obj && obj.featuresEnum || [];
        this.canary = obj && obj.canary || false;
    }
}

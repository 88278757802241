<div>
  <div class="d-inline-block nav-item temp-nav-icon position-relative" *ngIf="canOrders()">
        <span [routerLink]="['/warenkorb']"
              routerLinkActive="navLinkActive"
              routerLinkActiveOptions="{exact:true}"
              class="nav-link py-0"
              role="button"
              (click)="trackingEvent('Zum Warenkorb')">
            <i class="fal fa-shopping-cart"
               matBadge="{{(totals$ | async).quantityTotal}}"
               matBadgeSize="medium"
               [matBadgeHidden]="(totals$ | async).quantityTotal == 0" attr.data-cy="quick-nav-go-to-cart"
               matTooltip="Zum Warenkorb">
            </i>
        </span>
    <add-to-cart-notifier [cartItems]="(cartItems$ | async)" [latestItems]="(latestItems$ | async)"></add-to-cart-notifier>
  </div>
  <div *ngIf="isOwner()" class="d-inline-block dropdown nav-item temp-nav-icon">
    <span  class="nav-link dropdown-toggle py-0" role="button" data-toggle="dropdown" aria-expanded="false"><i class="fal fa-cog"></i></span>
    <div class="dropdown-menu">
      <a [routerLink]="['/mitbenutzerverwaltung']" class="dropdown-item py-xxs" (click)="trackClick('NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.LINK')">
        <span>{{ 'NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.LINK' | translate }}</span>
      </a>
      <a [routerLink]="['/schnittstellen']" class="dropdown-item py-xxs" (click)="trackClick('NAVIGATION.PREFERENCES.INTEGRATIONS.LINK')">
        <span>{{ 'NAVIGATION.PREFERENCES.INTEGRATIONS.LINK' | translate }}</span>
      </a>
      <a [routerLink]="['/benachrichtigungen']" class="dropdown-item py-xxs" (click)="trackClick('NAVIGATION.PREFERENCES.COMMUNICATIONS.LINK')">
        <span>{{ 'NAVIGATION.PREFERENCES.COMMUNICATIONS.LINK' | translate }}</span>
      </a>
    </div>
  </div>
</div>


import {HttpErrorResponse} from '@angular/common/http';
import {MessageActions, MessageActionTypes} from '../actions/message.actions';
import {MessageResponse} from '../../models/message/message-response.model';


export interface State {
    messagesRequest: MessageResponse;
    messagesResponse: MessageResponse;
    messagesErrorResponse: HttpErrorResponse;
    loading: boolean;
    loadingMessage: boolean;
}

export const initialState: State = {
    messagesRequest: null,
    messagesResponse: null,
    messagesErrorResponse: null,
    loading: false,
    loadingMessage: false,
};

export function reducer(state = initialState, action: MessageActions): State {
    switch (action.type) {
        case MessageActionTypes.INIT_MESSAGES:
            return {
                ...state,
                loading: true
            };
        case MessageActionTypes.INIT_MESSAGES_COMPLETE:
            return {
                ...state,
                messagesResponse: action.payload,
                loading: false
            };
        case MessageActionTypes.LOAD_MESSAGES:
            return {
                ...state,
                messagesRequest: action.payload,
                loading: true
            };
        case MessageActionTypes.LOAD_MESSAGES_COMPLETE:
            return {
                ...state,
                messagesResponse: action.payload,
                loading: false
            };
        case MessageActionTypes.LOAD_MESSAGE:
            return {
                ...state,
                loadingMessage: true
            };
        case MessageActionTypes.LOAD_MESSAGE_COMPLETE:
            // TODO: merge content into response
            const newResults = [];
            for (const message of state.messagesResponse.results) {
                if (message.id === action.payload.results[0].id) {
                    message.content = action.payload.results[0].content;
                }
                newResults.push(message);
            }
            const newMessagesResponse = {...action.payload, ...{results: newResults}};
            return {
                ...state,
                messagesResponse: {...state.messagesResponse, ...newMessagesResponse},
                loadingMessage: false
            };
        case MessageActionTypes.MESSAGE_FAILED:
            return {
                ...state,
                messagesErrorResponse: action.payload,
                loadingMessage: false
            };
        default:
            return state;
    }
}

export const getMessagesRequest = (state: State) => state.messagesRequest;
export const getMessagesResponse = (state: State) => state.messagesResponse;
export const getMessagesErrorResponse = (state: State) => state.messagesErrorResponse;
export const getMessagesLoading = (state: State) => state.loading;
export const getMessageLoading = (state: State) => state.loadingMessage;

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as fromReklamationActions from '../actions/reklamation.actions';
import {ReklamationRestService} from '../../services/api/reklamation.rest-service';

@Injectable()
export class ReklamationEffects {

    constructor(private actions$: Actions,
                private reklamationRestService: ReklamationRestService) {

    }

    getReklamations$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromReklamationActions.GetReklamationListAction>(
                fromReklamationActions.ReklamationActionTypes.GET_REKLAMATION_LIST))
            .pipe(map(action => action.payload),
                switchMap((request) =>
                    this.reklamationRestService.loadReklamationList(request)
                        .pipe(
                            map((data) => new fromReklamationActions.GetReklamationListCompleteAction(data.returnObject)),
                            catchError((error) => of(new fromReklamationActions.GetReklamationListFailAction(error)))
                        )
                )
            );
    });
}

export class TrackIdfRequestModel {
    idf: any;
    requestDate: string;
    detailsDesired: boolean;
    tourId: number;

    constructor(idf, date, details, tourID) {
        this.idf = idf || null;
        this.requestDate = date || null;
        this.detailsDesired = details || false;
        this.tourId = tourID || null;
    }
}
import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {DialogPopupInteface} from '../../../core/interfaces/dialog-popup.interface';
import {MatomoService} from '../../../core/services/matomo.service';
import {DialogRestService} from '../../../core/services/api/dialog.rest-service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject<any>();
  public subscribed = false;
  public parsedBody = [];
  public sent = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogPopupInteface,
              private dialogRef: MatDialogRef<FeedbackComponent>,
              private matomoService: MatomoService,
              private dialogRestService: DialogRestService) {
    this.parseBody(data.body);
  }

  ngOnInit(): void {
  }

  onButtonClick() {

  }

  private parseBody(body: string) {
    this.parsedBody = [];
    if (body !== undefined) {
      const matches = body.match(/\[form=([0-9]*)\]/g);
      if (matches) {
        for (const match of matches) {
          const split = body.split(match);
          this.parsedBody.push({data: split[0], type: 'string'});
          this.parsedBody.push({data: match.replace(/\D/g, '').trim(), type: 'form'});
          if (split.length > 1) {
            this.parsedBody.push({data: split[1], type: 'string'});
          }
        }
      } else {
        this.parsedBody.push({data: body, type: 'string'});
      }
    }
  }

  onSuccess($event: any) {
    this.sent = $event;
  }

  onSubscribe(name) {
    this.dialogRestService.subscribeToDialogOffer(this.data.id).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      if (res.return_object !== null && res.error_code === null) {
        this.subscribed = true;
      }
    });
    this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup Link', name, 1);
  }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

export class ProductRequest {
    page: number;
    search: string;
    shopOnly: boolean;
    size: number;

    constructor(obj: any = {}) {
        this.page = obj && (obj.pageIndex + 1) || 1;
        this.search = obj && obj.search || '';
        this.shopOnly = obj && obj.shopOnly || true;
        this.size = obj && obj.size || 20;
    }

}

import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as fromReducers from '../../store/reducers';
import {select, Store} from '@ngrx/store';
import {
    CopyTreuhandConfigAction,
    GetTreuhandConfigAction,
    SetTreuhandConfigAction,
    GetTreuhandConfigIdfsAction
} from '../../store/actions/integrations/treuhand.actions';

@Injectable({
    providedIn: 'root',
})

export class TreuhandService implements OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();

    constructor(
        public store: Store<fromReducers.State>) {
    }

    loadTreuhandConfig(idf: string) {
        this.store.dispatch(new GetTreuhandConfigAction(idf));
    }

    getTreuhandConfigObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getTreuhandConfig));
    }

    getTreuhandConfigLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getTreuhandLoading));
    }

    updateConfiguration(request) {
        this.store.dispatch(new SetTreuhandConfigAction(request));
    }

    updateConfigurationLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.setTreuhandLoading));
    }

    updateConfigurationErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.setTreuhandError));
    }

    copyConfiguration(request) {
        this.store.dispatch(new CopyTreuhandConfigAction(request));
    }

    copyConfigurationLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.copyTreuhandLoading));
    }

    copyConfigurationErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.copyTreuhandError));
    }

    getTreuhandConfiguredIdfs(idf: string) {
        this.store.dispatch(new GetTreuhandConfigIdfsAction(idf));
    }

    getTreuhandConfigIdfsObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getTreuhandConfigIdfs));
    }

    getTreuhandConfigIdfsLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getTreuhandConfigIdfsLoading));
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

import {Injectable} from '@angular/core';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable';
import {formatDate} from "@angular/common";

@Injectable({
    providedIn: 'root',
})

export class JsonToPdfService {
    downloadPdf(rows: any[], cols: any[], fileName: string, title: string, desc?: string) {
        const doc = new jsPDF();
        // title
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text(title, 14, 10);
        // description
        if (desc) {
            doc.setFontSize(10);
            doc.setTextColor(100);
            doc.text(desc, 14, 17);
        }
        autoTable(doc, {
            head: cols,
            headStyles: {fillColor: [0, 151, 116]},
            bodyStyles: {fontSize: 8},
            body: rows,
            startY: desc ? 22 : 17,
            showHead: 'firstPage'
        });

        doc.save(`${fileName}.pdf`);
    }

    downloadPreOrderPdf(data) {
        const baseFontSize = 10;
        const doc = new jsPDF({compress: true});
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const noOfRows = data.rows.length;
        const footerY = noOfRows * 18 + (noOfRows <= 4 ? 150 : 100);
        // logo
        const img = new Image();
        img.src = 'assets/img/logo.png';
        doc.addImage(img, 'png', pageWidth - 40, 10, 30, 24);
        // Phoenix headquarter address
        doc.setFontSize(baseFontSize * 0.8);
        doc.setTextColor(100);
        doc.text('PHOENIX Pharmahandel GmbH & Co KG · 68199 Mannheim', 14, 50);
        // Pharmacy address
        if (data.address) {
            doc.setFontSize(10);
            doc.setTextColor(100);
            doc.text(data.address, 14, 60);
        }
        // print date
        doc.setFontSize(baseFontSize);
        doc.setTextColor(100);
        if (data.hasOwnProperty('dateFormat')) {
            doc.text('Datum\n' + formatDate(Date.now(), data.dateFormat, 'de-DE'), pageWidth - 60, 80);

        } else {
            // this block is used by preorder archive as we are not passing any dateFormat
            doc.text(formatDate(data.preOrderInitiatedAt, 'mediumDate', 'de-DE'), pageWidth - 60, 80);
        }
        // title
        doc.setFontSize(baseFontSize * 2);
        doc.setTextColor(40);
        doc.text(data.title, 14, 95);
        // description
        doc.setFontSize(baseFontSize);
        doc.setTextColor(100);
        doc.text(data.desc, 14, 105);
        // table
        autoTable(doc, {
            head: data.cols,
            headStyles: {fillColor: [0, 151, 116]},
            bodyStyles: {fontSize: baseFontSize * 0.8},
            body: data.rows,
            startY: 120,
            showHead: 'firstPage'
        });
        // table desc (Only used in Nachlieferung)
        if (data.tableDesc) {
            doc.setFontSize(baseFontSize * 0.8);
            doc.setTextColor(100);
            doc.text(data.tableDesc, 14, (doc as any).lastAutoTable.finalY + 10);
        }
        // footer Title
        doc.setTextColor(40);
        doc.setFontSize(baseFontSize * 1.5);
        doc.text(data.footerTitle, pageWidth / 2, footerY, {align: 'center'});
        // footer description
        doc.setFontSize(baseFontSize);
        doc.setTextColor(100);
        doc.text(data.footerDesc, 14, footerY + 10, {maxWidth: pageWidth - 28});
        doc.save(`${data.fileName}.pdf`);
    }

    getDate() {
        const d = new Date();
        const year = `${d.getFullYear()}`.substring(2),
            month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1),
            date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
        return `Datum\n${date}.${month}.${year}`;
    }
}

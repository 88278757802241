import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from "@angular/forms";
import {ICredentialsService} from "../../../core/interfaces/credentials-service.interface";
import {LogService} from "../../../core/services/utility/log.service";
import {MustMatch} from "../../../core/validators/must-match.validator";
import {PasswordValidator} from "../../../core/validators/password.validator";
import {SetCredentialsTokenRequest} from "../../../core/models/registration/set-credentials-token-request.model";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {SetCredentialsOldPasswordRequest} from '../../../core/models/registration/set-credentials-old-password-request.model';

@Component({
    selector: 'password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent implements OnInit {

    @Input() showBackButton: boolean = false;
    @Input() submitButtonText: string = 'Speichern';
    @Input() backButtonText: string = 'zurück';

    @Input() token: string;
    @Input() requiresPassword: boolean = false;
    @Input() oldPasswordPreset: string;
    @Input() credentialsService: ICredentialsService;

    @Input() forwardToContactFormOnFail: boolean = true;

    @Output() back: EventEmitter<any> = new EventEmitter<any>();
    @Output() success: EventEmitter<any> = new EventEmitter<any>();
    @Output() fail: EventEmitter<string> = new EventEmitter<string>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

    public error: string = null;
    public passwordForm: FormGroup;
    public newPassword: FormControl = new FormControl('', [
        Validators.required,
        Validators.pattern(/.{8}/),
    ]);
    public confirmPassword: FormControl = new FormControl('', [Validators.required]);
    public oldPassword: FormControl = new FormControl('', [Validators.required]);
    public submitted: boolean;
    public hide: boolean = true;

    constructor(public formBuilder: FormBuilder,
                @Inject(LogService) public log: LogService,
                public router: Router) {
        this.passwordForm = this.formBuilder.group({
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword,
        }, {
            validator: [
                MustMatch('newPassword', 'confirmPassword'),
                PasswordValidator('newPassword')
            ]
        });

    }

    ngOnInit() {
        if (this.requiresPassword) {
            this.passwordForm.addControl('oldPassword', this.oldPassword);
            if (this.oldPasswordPreset) {
                this.oldPassword.setValue(this.oldPasswordPreset);
            }
        }
    }

    onSubmit() {
        this.submitted = true;
        this.log.info(this.passwordForm);
        if (this.passwordForm.invalid) {
            return;
        }
        // stop here if form is invalid
        this.loading.next(true);
        let setCredentialsRequest;
        if (this.requiresPassword) {
            setCredentialsRequest = new SetCredentialsOldPasswordRequest({...this.passwordForm.value});
        } else {
            setCredentialsRequest = new SetCredentialsTokenRequest({...this.passwordForm.value, ...{token: this.token}});
        }
        this.credentialsService.setCredentials(setCredentialsRequest).subscribe(
            data => {
                this.loading.next(false);
                this.success.next({password: this.confirmPassword.value, response: data.returnObject});
            },
            err => {
                this.loading.next(false);
                if (this.forwardToContactFormOnFail) {
                    if (err != null && err.error.errorCode) {
                        this.fail.next(err.error.errorCode);
                        this.router.navigate(['kontakt'], {queryParams: {error: JSON.stringify(err)}});
                    }
                } else {
                    if (err != null && err.error.errorCode) {
                        this.fail.next(err.error.errorCode);
                    } else {
                        this.fail.next(null);
                    }
                }
            });

    }

    onBack() {
        this.back.next();
    }
}

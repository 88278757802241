<ng-container *ngIf="!data['isAdmin']">
  <h2 class="pb-s">Wählen Sie Ihre Apotheke aus</h2>
  <p>Folgende Konten stehen für Ihr Profil zur Verfügung:</p>
  <mat-radio-group class="vertical-radio-group">
    <mat-radio-button
        class="idf-select-button"
        *ngFor="let idf of data['idfs']"
        [value]="idf.idf"
        [checked]="data['currentIdf'].idf === idf.idf"
        (change)="selectedIdf = idf"
    >
      <i class="far fa-store-alt phx-corporate pl-0"></i>
      <p class="mb-xs">{{idf.name}}</p>
      <table class="idf-select-table">
        <tr class="head">
          <td class="pr-s"><strong>IDF</strong></td>
          <td><strong>Adresse</strong></td>
        </tr>
        <tr class="body">
          <td class="pr-s">{{idf.idf}}</td>
          <td>{{idf.street}},&nbsp;{{idf.zipCode}}&nbsp;{{idf.city}}</td>
        </tr>
      </table>
    </mat-radio-button>
  </mat-radio-group>
</ng-container>

<ng-container *ngIf="data['isAdmin']">
  <form>
    <mat-form-field appearance="outline">
      <mat-label>IDF-Nummer</mat-label>
      <input name="idf" matInput (change)="setIdf($event.currentTarget.value);">
      <mat-error>Error</mat-error>
    </mat-form-field>
    <button type="submit" class="btn btn-primary">Anwenden</button>
  </form>

</ng-container>
<div class="d-flex justify-content-end" *ngIf="!data['isAdmin']">
  <button (click)="closeDialog()" class="btn btn-primary">Anwenden</button>
</div>

import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from "../modules/authentication/login/login.component";
import {LogoutComponent} from "../modules/authentication/logout/logout.component";
import {PasswordForgottenComponent} from "../modules/authentication/password-forgotten/password-forgotten.component";
import {MembershipRequestComponent} from "../modules/authentication/membership-request/membership-request.component";
import {ContactComponent} from "../modules/shared/contact/contact.component";
import {ViewPublicComponent} from "./view-public.component";

const routes: Routes = [
    {
        path: '', component: ViewPublicComponent, children: [
            {path: '', redirectTo: 'login', pathMatch: 'full'},
            {path: 'login', component: LoginComponent},
            {path: 'login/:action', component: LoginComponent},
            {path: 'logout', component: LogoutComponent},
            {path: 'open/static-content', loadChildren: () => import('../modules/static-content/static-content.module').then(m => m.StaticContentModule)},
            {
                path: 'registrierung',
                loadChildren: () => import('./../modules/authentication/registration/registration.module').then(m => m.RegistrationModule)
            },
            {
                path: 'kontakt',
                loadChildren: () => import('./../modules/shared/contact/contact.module').then(m => m.ContactModule)
            },
            {
                path: 'zugangsdaten-vergessen', component: PasswordForgottenComponent
            },
            {
                path: 'neues-passwort-setzen',
                loadChildren: () => import('./../modules/authentication/password-reset/password-reset.module').then(m => m.PasswordResetModule)
            },
            {
                path: 'konto-antrag', component: MembershipRequestComponent
            },
            {
                path: 'aktivierung',
                loadChildren: () => import('./../modules/authentication/account-activation/account-activation.module').then(m => m.AccountActivationModule)
            },
            {
                path: 'open/page/:url',
                loadChildren: () => import('../modules/shared/cms/cms.module').then(m => m.CmsModule),
            }
        ]
    }
];
export const ROUTES = RouterModule.forChild(routes);

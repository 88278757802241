import {Inject, Injectable} from '@angular/core';
import {Product} from '../../models/shop/product.model';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as fromReducers from "../../store/reducers";
import {ProductsResponse} from "../../models/shop/products-response.model";
import {WebshopRestService} from "../api/webshop-rest.service";
import {ProductServiceAbstract} from "./product-service.abstract";
import {ProductRequest} from "../../models/shop/product-request.model";
import {
    GetBatchFromCacheAction,
    LoadTopPrismaAction,
    SearchShopAction,
    UpdateScrollingPositionAction
} from '../../store/actions/top-prisma.actions';

@Injectable()
export class TopPrismaService extends ProductServiceAbstract {
    constructor(public store: Store<fromReducers.State>, @Inject(WebshopRestService) public webshopRestService: WebshopRestService) {
        super(store, webshopRestService);
    }

    updateScrollingPosition(pos: number): void {
        this.store.dispatch(new UpdateScrollingPositionAction(pos));
    }

    searchProducts(request: ProductRequest = new ProductRequest({})): void {
        // this.store.dispatch(new SearchShopAction(request));
    }

    displayNewBatch(request: ProductRequest = new ProductRequest({})): void {
        let end: boolean = false;
        this.store.pipe(select(fromReducers.getTopPrismaResponse))
            .subscribe(response => end = response.end).unsubscribe();

        // determine how much cache is left
        let cacheSize: number = 0;
        this.store.pipe(select(fromReducers.getTopPrismaProductCache))
            .subscribe(cache => cacheSize = cache.length).unsubscribe();

        if (!end && cacheSize < 2 * 20) {
            this.store.dispatch(new LoadTopPrismaAction(request));
        }

        // get batch from cache
        this.store.dispatch(new GetBatchFromCacheAction());
    }


    /**
     * GETTERS
     */
    getProductsLoadingObservable(): Observable<boolean> {
        this.productsLoading = this.store.pipe(select(fromReducers.getTopPrismaLoading));
        return this.productsLoading;
    }

    getProductsResponseObservable(): Observable<ProductsResponse> {
        this.productsResponse$ = this.store.pipe(select(fromReducers.getTopPrismaResponse));
        //this.checkAvailability();
        return this.productsResponse$;
    }

    getProductsRequestObservable(): Observable<ProductRequest> {
        this.productsRequest$ = this.store.pipe(select(fromReducers.getTopPrismaRequest));
        //this.checkAvailability();
        return this.productsRequest$;
    }

    getProductsObservable(): Observable<Product[]> {
        this.products$ = this.store.pipe(select(fromReducers.getTopPrismaProducts));
        return this.products$;
    }

    getScrollPositionObservable(): Observable<number> {
        this.scrollPosition$ = this.store.pipe(select(fromReducers.getTopPrismaScrollPosition));
        return this.scrollPosition$;
    }

    getProductCacheObservable(): Observable<Product[]> {
        this.productCache$ = this.store.pipe(select(fromReducers.getTopPrismaProductCache));
        return this.productCache$;
    }
}
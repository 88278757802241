import {Component,Inject, Input, OnInit, OnDestroy} from '@angular/core';
import {UserService} from '../../../core/services/user.service';
import {ScrollingService} from '../../../core/services/scrolling.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {NavType} from '../../../core/models/nav-item.model';
import {IdfSelectionDialogComponent} from '../idf-selection-dialog/idf-selection-dialog.component';
import {MatomoService} from '../../../core/services/matomo.service';

@Component({
  selector: 'app-phoenix-store',
  templateUrl: './phoenix-store.component.html',
  styleUrls: ['./phoenix-store.component.scss']
})
export class PhoenixStoreComponent implements OnInit, OnDestroy {
  public NavType: typeof NavType = NavType;

  public ngUnsubscribe$ = new Subject<any>();
  public lindaIdfs = [];
  public tokens = [];
  @Input() public type = null;

  constructor(public userService: UserService,
              public dialog: MatDialog,
              public matomoService: MatomoService,
              @Inject('DA_STORE_URL') public DA_STORE_URL: string,
              @Inject('DA_STORE_REFERRER') public DA_STORE_REFERRER: string) {
  }

  ngOnInit(): void {
    // if (!this.userService.isAdmin()) {
    //   const idf$ = this.userService.selectContextualizedIdfsObservable('all');
    //   idf$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(accounts => {
    //     if (accounts !== undefined && accounts !== null) {
    //       for (const account of accounts) {
    //         if (account.linda.indexOf('010') > -1 || account.linda.indexOf('110') > -1 || account.linda.indexOf('111') > -1 || account.linda.indexOf('011') > -1) {
    //           this.lindaIdfs.push(account.idf);
    //         }
    //       }
    //     }
    //   });
    //
    // }

    const tokens$ = this.userService.getAuthTokensObservable();
    tokens$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(tokens => {
      for (const token in tokens) {
        const item = {key: '', value: ''};
        item.key = token;
        item.value = tokens[token];
        this.tokens.push(item);
      }
    });
  }

  openIdfSelectionDialog(navType, imageFileName: string, heading: string, message: string, tokens) {
    const idfSelectionDialogConfig = new MatDialogConfig();
    const authTokens = tokens;
    const authTokensLoading = this.userService.getAuthTokensLoadingObservable();
    const userName = this.userService.getUser().username;

    idfSelectionDialogConfig.width = '50vw';
    idfSelectionDialogConfig.data = {
      navType: navType,
      userName: userName,
      imageFileName: imageFileName,
      heading: heading,
      message: message,
      authTokens: authTokens,
      authTokensLoading: authTokensLoading
    };

    const dialogRef = this.dialog.open(IdfSelectionDialogComponent, idfSelectionDialogConfig);
  }

  trackClick(name: string) {
    this.matomoService.trackEvent('Mein ' + this.type.toUpperCase(), 'Klick auf Button', name);
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs/internal/Observable";
import * as fromInvoiceActions from '../actions/invoices.actions';
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {Action} from "@ngrx/store";
import {InvoicesRestService} from "../../services/api/invoices.rest-service";
import {InvoicesResponse} from '../../models/invoices-response.model';

@Injectable()
export class InvoicesEffects {
    /**
     * In case of SearchInvoicesAction we're going to call the InvoicesService and then
     * we'll post a LoadInvoicesCompleteAction to the reducer or in case of failure
     * LoadInvoicesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    searchInvoices$: Observable<any> = this.actions$
        .pipe(ofType<fromInvoiceActions.SearchInvoicesAction>(fromInvoiceActions.InvoicesActionTypes.SEARCH_INVOICES))
        .pipe(map(action => action.payload), switchMap(invoicesRequest =>
            this.invoicesRestService.searchDocuments(invoicesRequest).pipe(
                map(data => new fromInvoiceActions.SearchInvoicesCompleteAction(new InvoicesResponse(data.returnObject))),
                catchError((error) => of(new fromInvoiceActions.SearchInvoicesFailAction(error)))
            )
        ));

    /**
     * On LoadUserDocTypesAction request idfs from gateway and store them via LoadUserDocTypesCompleteAction
     * or catch the error via LoadUserDocTypesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadDocTypes$: Observable<Action> = this.actions$
        .pipe(ofType<fromInvoiceActions.LoadDocTypesAction>(fromInvoiceActions.InvoicesActionTypes.LOAD_DOC_TYPES))
        .pipe(map(action => action.payload), switchMap(request =>
                this.invoicesRestService.getDocumentTypes(request).pipe(
                    map(data => new fromInvoiceActions.LoadDocTypesCompleteAction(data.returnObject, request)),
                    catchError((error) => of(new fromInvoiceActions.LoadDocTypesFailAction(error)))
                )
            )
        );

    /**
     * On LoadUserDocTypesAction request idfs from gateway and store them via LoadUserDocTypesCompleteAction
     * or catch the error via LoadUserDocTypesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadAllDocTypes$: Observable<Action> = this.actions$
        .pipe(ofType<fromInvoiceActions.LoadAllDocTypesAction>(fromInvoiceActions.InvoicesActionTypes.LOAD_ALL_DOC_TYPES))
        .pipe(map(action => action), switchMap(() =>
                this.invoicesRestService.getAllDocumentTypes().pipe(
                    map(data => new fromInvoiceActions.LoadAllDocTypesCompleteAction(data.returnObject)),
                    catchError((error) => of(new fromInvoiceActions.LoadAllDocTypesFailAction(error)))
                )
            )
        );

    /**
     * @param {InvoicesRestService} invoicesRestService
     * @param {Actions} actions$
     */
    constructor(
        private invoicesRestService: InvoicesRestService,
        private actions$: Actions
    ) {
    }
}

export class AccountInfo {
    email: string;
    fax: string;
    idf: string;
    name: string;
    salutation: any;
    title: any;
    firstName: any;
    lastName: any;
    phone: string;
    street: string;
    zipCode: string;
    city: string;

    constructor(obj: any = {}) {
        this.email = obj && obj.email || null;
        this.fax = obj && obj.fax || null;
        this.name = obj && obj.name || null;
        if(obj && obj.owner){
            this.salutation = obj.owner.salutation;
            this.title = obj.owner.title;
            this.firstName = obj.owner.firstName;
            this.lastName = obj.owner.lastName;
        } else {
            this.title = null;
            this.salutation = null;
            this.firstName = null;
            this.lastName = null;
        }
        this.idf = obj && obj.idf || null;
        this.phone = obj && obj.phone || null;
        this.street = obj && obj.street || null;
        this.zipCode = obj && obj.zipCode || null;
        this.city = obj && obj.city || null;
    }
}
import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../core/services/user.service';

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
    public canary;
    public canUseFeature;
    constructor(public userService: UserService) {
        this.canary = this.userService.getUser().canary;
        this.canUseFeature = this.userService.canUseFeature('TREUHAND');
    }

    ngOnInit(): void {
    }

}

<div class="row panel-header" *ngIf="!success">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <h1><span *ngIf="isQaVisible()" class="qaText">QA - Umgebung </span>Werden Sie PHOENIX Kunde!</h1>
        <p class="pt-2">
            Wir freuen uns sehr, dass Sie an einer Geschäftsbeziehung mit PHOENIX interessiert sind. Bitte geben Sie
            Ihre
            persönlichen Angaben und die Angaben zu Ihrer Apotheke an. Gerne vereinbaren wir einen persönlichen Termin.
        </p>
        <p>
            Ihre PHOENIX Pharmahandel
        </p>
    </div>
    <div class="col-md-2"></div>
</div>

<div class="row panel-body" *ngIf="loading">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <spinner message="Ihr Antrag wird übermittelt."></spinner>
    </div>
    <div class="col-md-2"></div>
</div>

<message *ngIf="success" message="Ihr Antrag wurde erfolgreich übermittelt. Wir werden uns in Kürze bei Ihnen melden.">
</message>
<notification *ngIf="error" type="error" [message]="error"></notification>

<div class="row panel-body mb-xxxl" *ngIf="!loading && !success">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <form [formGroup]="userForm" [class.submitted]="submitted">
            <div class="row">
                <div class="col-md-6">
                    <h4>Persönliche Angaben</h4>
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Anrede</mat-label>
                            <mat-select [formControlName]="'salutation'">
                                <mat-option *ngFor="let salutation of ['Herr', 'Frau']" [value]="salutation">
                                    {{salutation}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="salutation.hasError('required') && submitted">Bitte wählen Sie eine Anrede
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Titel - optional</mat-label>
                            <input matInput autocomplete="off" name="title" formControlName="title">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Vorname</mat-label>
                            <input matInput autocomplete="off" name="firstName" formControlName="firstName">
                            <mat-error *ngIf="firstName.hasError('required') && submitted">Bitte geben Sie Ihren
                                Vornamen ein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Nachname</mat-label>
                            <input matInput autocomplete="off" name="lastName" formControlName="lastName">
                            <mat-error *ngIf="lastName.hasError('required') && submitted">Bitte geben Sie Ihren
                                Nachnamen ein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6">
                    <h4>Angaben zur Apotheke</h4>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Name der Apotheke</mat-label>
                            <input matInput autocomplete="off" name="apotheke" formControlName="name">
                            <mat-error *ngIf="name.hasError('required') && submitted">Bitte geben Sie den Namen Ihrer
                                Apotheke ein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>IDF-Nummer</mat-label>
                            <input matInput autocomplete="off" name="idf" formControlName="idf">
                            <mat-error *ngIf="idf.hasError('required') && submitted">Bitte geben Sie Ihre IDF-Nummer
                                ein.</mat-error>
                            <mat-error *ngIf="idf.hasError('pattern') && submitted">Geben Sie eine 7-stellige
                                Zahlenfolge ein.</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Straße und Hausnummer</mat-label>
                            <input matInput autocomplete="off" name="street" formControlName="street">
                            <mat-error *ngIf="street.hasError('required') && submitted">Bitte geben Sie Straße und
                                Hausnummer Ihrer
                                Apotheke ein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>PLZ</mat-label>
                            <input matInput autocomplete="off" name="zipCode" formControlName="zipCode">
                            <mat-error *ngIf="zipCode.hasError('required') && submitted">Bitte geben Sie Postleitzahl
                                ihrer Apotheke
                                ein.
                            </mat-error>
                            <mat-error *ngIf="zipCode.hasError('pattern') && submitted">Ihre Postleitzahl muss 5 Ziffern
                                lang sein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Ort</mat-label>
                            <input matInput autocomplete="off" name="city" formControlName="city">
                            <mat-error *ngIf="city.hasError('required') && submitted">Bitte geben Sie den Ort ihrer
                                Apotheke ein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>E-Mail</mat-label>
                            <input matInput autocomplete="off" name="email" formControlName="email" type="email">
                            <mat-error *ngIf="email.hasError('required') && submitted">Bitte geben Sie Ihre
                                E-Mail-Adresse ein.
                            </mat-error>
                            <mat-error *ngIf="email.hasError('pattern') && submitted">Bitte geben Sie eine gültige
                                E-Mail-Adresse ein.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Telefonnummer</mat-label>
                            <input matInput autocomplete="off" name="phone" formControlName="phone">
                            <mat-error *ngIf="phone.hasError('required') && submitted">Bitte geben Sie die Telefonnummer
                                Ihrer
                                Apotheke ein.
                            </mat-error>
                            <mat-error *ngIf="phone.hasError('pattern') && submitted">Bitte geben Sie eine gültige
                                Telefonnummer ein.</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Faxnummer - optional</mat-label>
                            <input matInput autocomplete="off" name="fax" formControlName="fax">
                            <mat-error *ngIf="fax.hasError('pattern') && submitted">Bitte geben Sie eine gültige
                                Faxnummer ein.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-left">
                    <a routerLink="/login">
                        <button class="btn btn-secondary">Zurück zum Login</button>
                    </a>
                </div>
                <div class="col-md-6 text-right">
                    <button class="btn btn-primary" (click)="submit()">Anfrage senden</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-2"></div>
</div>

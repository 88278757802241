<mat-dialog-content>
<div>Corona in Deutschland: Unsere gemeinsame Verantwortung</div><br/>
        <h2>Kundenanschreiben</h2>

        <p>Sehr geehrte Frau Apothekerin, sehr geehrter Herr Apotheker,
            die weltweite Ausbreitung des Coronavirus stellt momentan alle Gesellschaften und in unserem Fall insbesondere die Gesundheitssysteme vor extreme Herausforderungen. Der pharmazeutische Großhandel und die Apotheken vor Ort haben hier gemeinsam den Auftrag, Deutschland weiterhin flächendeckend mit Medikamenten zu versorgen.
        </p>
        <strong>Wie reagieren wir ab sofort auf diese Situation?</strong>
        <p>
            Genau wie Sie werden auch wir durch die aktuellen hoheitlichen Entscheidungen gefordert. Eine gewisse Anzahl unserer Mitarbeiter/innen wird aufgrund dieser Einschränkungen zuhause bleiben müssen, um dort ihre Kinder zu betreuen und Betroffene zu versorgen. Dies hat massive Auswirkungen auf unsere Kapazitäten und das gewohnte Servicelevel. Die Krisensituation bedingt, dass alle Beteiligten zur Sicherstellung der flächendeckenden Arzneimittelversorgung sehr strukturiert und effizient zusammenarbeiten. Wir bei PHOENIX haben daher ab sofort eine Vielzahl von zwingenden Maßnahmen ergriffen, um die Auswirkungen so gering wie möglich zu halten.
        </p>

        <ul>
            <li><b>Sicherstellung Belieferung:</b> Wir konzentrieren ab sofort alle verfügbaren Mitarbeiter auf den Warenfluss. Das bedeutet konkret, dass sowohl unsere Gebietsverkaufsleiter/innen als auch Verwaltungsmitarbeiter/innen nun zur Verringerung von Personalengpässen im Lager eingesetzt werden. Dies führt leider zu einer reduzierten Erreichbarkeit unserer Mitarbeiter.
            </li>
            <li><b>Bestellprozess:</b> Zur weiteren Senkung der Transportvolumina werden wir ab sofort die Anzahl der Wannen reduzieren. Dies geschieht im ersten Schritt durch eine stärkere Zusammenführung der Aufträge. Hierfür werden wir die Anzahl der Ihnen zur Verfügung stehenden MSV3-Bestellmöglichkeiten ab Mittwoch dem 18.03.2020 auf eine Auftragskennung und eine Auftragsart (NO) reduzieren. Bitte berücksichtigen Sie dies in Ihren Betriebsabläufen. Ggf. müssen wir ebenfalls Ihre MSV3 Bestellfenster anpassen, um Ihre Bestellungen mit geringerem Personalstand bearbeiten zu können. Bitte aktualisieren Sie dazu regelmäßig Ihre MSV3-Vertragsdaten in Ihrem Warenwirtschaftssystem.
            </li>
            <li><b>Kleinstbestellungen:</b> Gleichzeitig möchten wir Sie auffordern, von Kleinstbestellungen abzusehen. Damit helfen Sie, die gesamte Prozesskette bis hin zu Ihren Abläufen in der Apotheke zu entlasten. Bei einem Monatsbezug unter             25.000 Euro berechnen wir ab sofort eine Servicepauschale von 250 Euro.
            </li>
            <li><b>Verbund:</b> Wir erkennen bereits seit einigen Tagen eine stark angestiegene Nachfrage verbunden mit einer Tendenz zur Bevorratung in der Apotheke. Durch die hohe Nachfrage im gesamten Bundesgebiet erschwert sich der Service des Warenaustausches zwischen den Vertriebszentren. Aus diesem Grund sind wir gezwungen, diesen Service temporär einzustellen.
            </li>
            <li><b>Sortiment:</b> Durch die Fokussierung auf die notwendige Medikamentenversorgung wird es in Randsortimenten, bei Servicedispositionen, bei Zuteilungen von Warenpaketen und eventuell bei Überweisern zu Verzögerungen in der Abwicklung kommen.
            </li>
            <li><b>Lieferverzögerungen:</b> Bedingt durch die gestiegene Nachfrage und die Vielzahl der bereits genannten Herausforderungen ist in den nächsten Tagen/ Wochen mit Lieferverzögerungen trotz aller bereits eingeleiteter Maßnahmen zu rechnen. Wir setzen alles daran, Ihnen Ihre Ware so schnell wie möglich zukommen zu lassen, können Sie aber vorübergehend nicht mehr im Detail über jede Verspätung informieren.
            </li>
            <li><b>Kundenservicecenter:</b> Bitte reduzieren Sie aufgrund der eingeschränkten Erreichbarkeit Ihre Anrufe auf ein Minimum und konzentrieren Sie sich dabei auf die wichtigsten Themen.
            </li>
            <li><b>Retouren:</b> Bis auf Weiteres können wir mit Ausnahme von Mängelrügen, die innerhalb von zwei Tagen an uns zurückgesendet werden, keine Retouren annehmen und erstatten (insbesondere auch keine BTM- und Kühlretouren). Mängelrügen bitte ab sofort dem Fahrer persönlich übergeben. Bitte informieren Sie Ihre Mitarbeiter, um Missverständnisse zu vermeiden.
            </li>
            <li><b>Leergut:</b> Zusätzlich bitten wir Sie unsere Transportbehälter schnellstens zurückzugeben – unser Bestand im Kreislauf ist nicht darauf ausgelegt, eine Bevorratung bei unseren Kunden mit abzudecken.
            </li>
        </ul>

        <p>Wir haben die Hoffnung mit unseren Maßnahmen und vor allem mit Ihrer Unterstützung gemeinsam die flächendeckende Versorgung mit Arzneimitteln für die Bevölkerung weiterhin sicherzustellen. Allerdings müssen wir die weitere Entwicklung dieser Krise beobachten und schnellstens auf sich verändernde Situationen gemeinsam reagieren. Wenn weitere Schritte notwendig sind, informieren wir Sie rechtzeitig.
        </p>
        <p>
            Vielen Dank für Ihr Verständnis und bleiben Sie, Ihre Familie und Ihre Mitarbeiter gesund!
        </p>

        <table border="0">
            <tr>
                <td width="220"><img style="width: 100px; height: 80px;" src="assets/img/phx-mf.png"><br/>Marcus Freitag<br/><span style="font-size: 12px; line-height: 12px;">Vorsitzender der <br/>Geschäftsleitung Deutschland</span></td>
                <td width="300"><img style="width: 300px; height: 80px;" src="assets/img/phx-v.jpg"><br/>Frank Große-Natrop<br/><span style="font-size: 12px; line-height: 12px;">Vorsitzender der <br/>Geschäftsleitung Deutschland</span></td>
            </tr>
        </table>
        <br/>
        <!-- Florian von Stürler<br/> -->

    <div style="text-align: center" ><button class="btn-primary" (click)="onNoClick()" tabindex="-1">Weiter</button></div>
        <br/>
        <br/>
</mat-dialog-content>

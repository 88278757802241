import {NgModule} from '@angular/core';
import {CartProviderComponent} from './cart-provider.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {AddToCartConfirmDialogComponent} from '../add-to-cart-confirm-dialog/add-to-cart-confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import {CartProviderP73Component} from './cart-provider-p73/cart-provider-p73.component';
import {CartProviderProfaxComponent} from './cart-provider-profax/cart-provider-profax.component';
import {CartProviderPaybackComponent} from './cart-provider-payback/cart-provider-payback.component';
import {CartProviderPromoComponent} from './cart-provider-promo/cart-provider-promo.component';
import {CartProviderUberComponent} from './cart-provider-uber/cart-provider-uber.component';
import {SafehtmlModule} from '../../../../shared/safehtml/safehtml.module';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatDialogModule,
        SafehtmlModule,
        CommonModule
    ],
    declarations: [
        CartProviderComponent,
        CartProviderP73Component,
        CartProviderPaybackComponent,
        CartProviderProfaxComponent,
        CartProviderPromoComponent,
        CartProviderUberComponent,
        AddToCartConfirmDialogComponent
    ],
    exports: [
        CartProviderComponent,
        CartProviderP73Component,
        CartProviderPaybackComponent,
        CartProviderProfaxComponent,
        CartProviderPromoComponent,
        CartProviderUberComponent,
        AddToCartConfirmDialogComponent
    ],
    entryComponents: [AddToCartConfirmDialogComponent]
})
export class CartProviderModule {}

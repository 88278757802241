import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/api/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * @param {Injector} inj
     * @param router
     */
    constructor(private inj: Injector,
                public router: Router,
                @Inject('API_URL') private API_URL,
                @Inject('CMS_URL') private CMS_URL) {
    }

    /**
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (window.location.href.indexOf('www') >= 0) {
            window.location.href = window.location.href.replace('www.','');
            return;
        }
        const auth = this.inj.get(AuthService);
        const token = auth.getToken();
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Headers', 'origin, content-type, accept, x-wsse, set-cookie, x-sessid');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Access-Control-Expose-Headers', '*');
        headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        headers = headers.append('Authorization', 'Bearer ' + token);
        if (token && (req.url.indexOf(this.API_URL) >= 0 || req.url.indexOf(this.CMS_URL) >= 0)) {
            const cloned = req.clone({
                headers: headers
            });

            return next.handle(cloned)
                .pipe(tap((event: HttpEvent<any>) => {
                    /*if (event instanceof HttpResponse) {
                      // do stuff with response if you want
                    }
                  }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                      if (err.status === 401) {
                        this.log.info('Unauthorized. Forward to login');
                        // Redirect to the login route
                        this.router.navigate(['login']);
                      }
                    }*/
                }));

        } else {
            return next.handle(req);
        }

    }
}

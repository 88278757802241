import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';

@Injectable({
    providedIn: 'root'
})
export class LindaRestService extends RestServiceAbstract{
    private ngUnsubscribe$ = new Subject<any>();

    public getRedirect(idf) {
        return this.post('/usermanagement/auth/linda?idf=' + idf, {})
            .pipe(takeUntil(this.ngUnsubscribe$),
            map(ret => {
            return new Response(ret);
        }));
    }

    public getGVLSignin(){
        return this.post('/usermanagement/auth/linda/contract-sign',{}).pipe(takeUntil(this.ngUnsubscribe$),
            map(ret => {
                return new Response(ret);
            }));
    }
}

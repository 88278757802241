import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export enum MessageActionTypes {
    INIT_MESSAGES = '[Messages] Init messages',
    INIT_MESSAGES_COMPLETE = '[Messages] Init messages complete',
    LOAD_MESSAGES = '[Messages] Load messages',
    LOAD_MESSAGES_COMPLETE = '[Messages] Load messages complete',
    LOAD_MESSAGE = '[Message] Load message',
    LOAD_MESSAGE_COMPLETE = '[Message] Load message complete',
    MESSAGE_FAILED = '[Message] operation failed',
}

export class InitMessagesAction implements Action {
    readonly type = MessageActionTypes.INIT_MESSAGES;

    constructor() { // == Query
    }
}
export class InitMessagesCompleteAction implements Action {
    readonly type = MessageActionTypes.INIT_MESSAGES_COMPLETE;

    constructor(public payload: any) {
    }
}


export class LoadMessagesAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGES;

    constructor(public payload: any) { // == Query
    }
}
export class LoadMessagesCompleteAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGES_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadMessageAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGE;

    constructor(public payload: any) { // == Query
    }
}
export class LoadMessageCompleteAction implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGE_COMPLETE;

    constructor(public payload: any) {
    }
}

export class ErrorAction implements Action {
    readonly type = MessageActionTypes.MESSAGE_FAILED;

    constructor(public payload: HttpErrorResponse) {
    }
}



export type MessageActions =
    InitMessagesAction |
    InitMessagesCompleteAction |
    LoadMessagesAction |
    LoadMessagesCompleteAction |
    LoadMessageAction |
    LoadMessageCompleteAction |
    ErrorAction;

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'notification-public',
  templateUrl: './notification-public.component.html',
  styleUrls: ['./notification-public.component.scss']
})
export class NotificationPublicComponent implements OnInit {
    @Input('message') message: string;
    @Input('html') html: string;
    @Input('type') type: string;

    constructor() {
    }

    ngOnInit() {
    }
}

<div id="content">
    <div class="widget">
        <div *ngIf="config && !loading" id="downloads">
            <div class="">
                <div *ngFor="let conf of config" class="mb-xxl">
                    <div *ngIf="hasAuthority(conf)">

                        <h2 *ngIf="conf.heading" class="pb-s">{{ conf.heading | translate }}</h2>
                        <p *ngIf="conf.text">{{ conf.text | translate }}</p>
                        <div class="download-item" *ngFor="let download of conf.links; let i = index;">
                            <div class="download-button d-flex align-items-baseline">
                                <button attr.data-cy="download-{{i+1}}"
                                        (click)="downloadFile(getType(conf));matomoEvent(download.analytics)"
                                        class="btn-primary btn">
                                    {{ download.text | translate }}
                                </button>
                                <span class="d-flex justify-content-between"> (Stand: <spinner
                                        *ngIf="dateLoading"></spinner>
                                    {{ getType(conf) === 'OTHER' ? prismaDatum : basisDatum }})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--        <div class="col-md-4">-->
            <!--            <form [formGroup]="userForm">-->
            <!--                <mat-form-field *ngIf="!input" appearance="outline">-->
            <!--                    <mat-label>IDF-Nummer</mat-label>-->
            <!--                    <mat-select formControlName="idfInput" (selectionChange)="setIdf($event)">-->
            <!--                        <mat-option *ngIf="(idfs$ | async)?.length === 0">-->
            <!--                            <i class="fal fa-spinner-third fa-spin"></i> IDFs werden geladen.-->
            <!--                        </mat-option>-->
            <!--                        <mat-option *ngFor="let idf of (idfs$ | async)" [value]="idf.idf">{{ idf.idf }}-->
            <!--                            ({{idf.name}})-->
            <!--                        </mat-option>-->
            <!--                    </mat-select>-->
            <!--                </mat-form-field>-->
            <!--                <mat-form-field *ngIf="input" appearance="outline">-->
            <!--                    <mat-label>IDF-Nummer</mat-label>-->
            <!--                    <input (change) = "setIdf($event.currentTarget)" [value]="selectedIdf" matInput formControlName="idfInput">-->
            <!--                </mat-form-field>-->
            <!--            </form>-->
            <!--        </div>-->


        </div>

        <div *ngIf="loading" class="loading-indicator my-1 p-s">
            <i class="fal fa-spinner-third fa-spin"></i>
            <span>Das Dokument wird gerade erstellt, bitte haben Sie einen Augenblick Geduld.</span>
        </div>
    </div>
</div>
import {map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Subject, Observable} from 'rxjs';
import {Response} from '../../models/response.model';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class UserRestService extends RestServiceAbstract implements OnDestroy {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    /**
     * Required by search form to request selectable branch numbers
     *
     * @returns {Observable<any>}
     */
    public getVZs(idf: string) {
        return this.get(`/usermanagement/distributionCenter?idf=${idf}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public getStaticNav(cmsUrl) {
        const params = new HttpParams()
            .set('partial', 'navigation-main')
            .set('format', 'json');
        return this.getFromCms(``, {observe: 'response', params, withCredentials: true}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((data: any) => {
                console.log(data);
                return data.body;
            })
        );
    }

    /**
     * Required by search form to request selectable IDFs
     *
     * @returns {Observable<any>}
     */
    // FIXME If we go for different contexts, then we basically kill us here with switchmap
    public getIDFs(context: string = 'all') {
        const obj = [];
        obj[context] = true;
        const params = (context === 'all' ? null : obj);
        return this.get(`/usermanagement/idf/details`, {params: params}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public getAllIDFs() {
        return this.get(`/usermanagement/idf/details`, {params: null}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public getFullIDFs() {
        return this.get(`/usermanagement/idf/full/details`, {params: null}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }


    public getDetailsForIdf(idf) {
        return this.get('/usermanagement/idf/' + idf + '/idfDetails').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    public getMainIdf() {
        return this.get('/usermanagement/idf/main').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getShippingAdresses() {
        return this.get(`/usermanagement/idf/shippingAddresses`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    // PXPPO-1813 - Call the backend token generator w/o arguments - session is suffiecient
    getAuthToken() {
        return this.get(`/usermanagement/idf/authToken`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    // PXPPO-1885 - Generate tokens for sub IDFs, too
    getAuthTokens() {
        return this.get('/usermanagement/idf/authTokens').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getMainDistributionCentersAdress() {
        return this.get(`/usermanagement/distributionCenter/main`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getAllDistributionCentersAdress() {
        return this.get(`/usermanagement/distributionCenter/all`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    // deprecated
    //
    // getPaybackPoints(idf) {
    //     return this.get(`/resource/payback/freepoints`, {params: {idf: idf}}).pipe(
    //         takeUntil(this.ngUnsubscribe$),
    //         map(data => {
    //             return new Response(data);
    //         })
    //     );
    // }

    getPaybackCurrentPoints(idf) {
        return this.get(`/resource/payback/my-points`, {params: {idf: idf}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    downloadPaybackAd(req) {
        return this.get(`/resource/payback/ad`,
            {params: req, responseType: 'blob', observe: 'response'}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map((data: any) => {
                return {
                    data: data.body,
                    filename: data.headers.get('Content-Disposition')
                };
            })
        );
    }

    getPaybackKpis(idf) {
        return this.get(`/resource/payback/kpi`, {params: {idf: idf}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    getMainVz(idf) {
        return this.get(`/usermanagement/distributionCenter/main/` + idf).
        pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properlly.
        this.ngUnsubscribe$.complete();
    }
}

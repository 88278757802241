import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {LindaRestService} from '../../../../core/services/api/linda.rest-service';
import {MatomoService} from '../../../../core/services/matomo.service';
import {Subject} from 'rxjs';
import {UserService} from '../../../../core/services/user.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ScrollingService} from '../../../../core/services/scrolling.service';
import {IdfSelectionDialogComponent} from '../../idf-selection-dialog/idf-selection-dialog.component';
import {NavType} from '../../../../core/models/nav-item.model';


@Component({
  selector: 'app-linda-leistungen',
  templateUrl: './linda-leistungen.component.html',
  styleUrls: ['./linda-leistungen.component.scss']
})
export class LindaLeistungenComponent implements OnInit, OnDestroy {
  public ngUnsubscribe$ = new Subject<any>();
  public lindaIdfs = [];
  public tokens = [];
  public NavType: typeof NavType = NavType;



  constructor(
      private lindaRestService: LindaRestService,
      private matomoService: MatomoService,
      public userService: UserService,
      public dialog: MatDialog,
      @Inject('DA_STORE_URL') public DA_STORE_URL: string,
      @Inject('DA_STORE_REFERRER') public DA_STORE_REFERRER: string,
      @Inject('CDN') public CDN,
      private scrollingService: ScrollingService,
  ) {
    if (!this.userService.isAdmin()) {
      const idf$ = this.userService.selectContextualizedIdfsObservable('all');
      idf$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(accounts => {
        if (accounts !== undefined && accounts !== null) {
          for (const account of accounts) {
            if (account.linda.indexOf('110') > -1 || account.linda.indexOf('111') > -1
                || account.linda.indexOf('310') > -1 || account.linda.indexOf('301') > -1 || account.linda.indexOf('311') > -1) {
              this.lindaIdfs.push(account.idf);
            }
          }
        }
      });

    }
    const tokens$ = this.userService.getAuthTokensObservable();
    tokens$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(tokens => {
      for (const token in tokens) {
        const item = {key: '', value: ''};
        item.key = token;
        item.value = tokens[token];
        this.tokens.push(item);
      }
    });


    window.scrollTo({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit(): void {
  }

  goToLinda(idf) {
    this.lindaRestService.getRedirect(idf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(ret => {
      if (ret.errorCode === null) {
        this.trackClick('Jetzt entdecken');
        window.location = ret.returnObject.url + ret.returnObject.token;
      }
    });
  }

  trackClick(name: string) {
    this.matomoService.trackEvent('LINDA', 'Klick auf Button', name);
  }

  openIdfSelectionDialog(navType, imageFileName: string, heading: string, message: string, tokens) {
    const idfSelectionDialogConfig = new MatDialogConfig();
    const authTokens = tokens;
    const authTokensLoading = this.userService.getAuthTokensLoadingObservable();
    const userName = this.userService.getUser().username;

    idfSelectionDialogConfig.width = '50vw';
    idfSelectionDialogConfig.data = {
      navType: navType,
      userName: userName,
      imageFileName: imageFileName,
      heading: heading,
      message: message,
      authTokens: authTokens,
      authTokensLoading: authTokensLoading
    };

    const dialogRef = this.dialog.open(IdfSelectionDialogComponent, idfSelectionDialogConfig);
  }


  allowed(authTokens) {
    const allowed = [];
    for (const idf of authTokens) {
      if (this.lindaIdfs.indexOf(idf.key) > -1) {
        allowed.push(idf);
      }
    }
    return allowed;
  }
  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}

import {ExtraPrismaActions, ExtraPrismaActionTypes} from '../actions/extra-prisma.actions';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';


export interface State {
    extraPrismaRequest: ProductRequest;
    extraPrismaResponse: ProductsResponse;
    extraPrismaErrorResponse: HttpErrorResponse;
    extraPrismaLoading: boolean;
    extraPrismaProducts: Product[];
    extraPrismaProductCache: Product[];
    extraPrismaScrollPosition: number;
}

export const initialState: State = {
    extraPrismaRequest: null,
    extraPrismaResponse: null,
    extraPrismaErrorResponse: null,
    extraPrismaLoading: false,
    extraPrismaProducts: [],
    extraPrismaProductCache: [],
    extraPrismaScrollPosition: 0
};

export function reducer(state = initialState, action: ExtraPrismaActions): State {
    switch (action.type) {
        case ExtraPrismaActionTypes.LOAD_EXTRAPRISMA:
            return {
                ...state,
                extraPrismaRequest: action.payload,
            };
        case ExtraPrismaActionTypes.LOAD_EXTRAPRISMA_COMPLETE:
            return {
                ...state,
                extraPrismaProductCache: [...state.extraPrismaProductCache, ...action.payload.products],
                extraPrismaResponse: action.payload,
                extraPrismaLoading: false
            };
        case ExtraPrismaActionTypes.LOAD_EXTRAPRISMA_FAIL:
            return {
                ...state,
                extraPrismaErrorResponse: action.payload,
                extraPrismaLoading: false
            };
        case ExtraPrismaActionTypes.SEARCH_EXTRAPRISMA:
            return {
                ...state,
                extraPrismaProducts: [],
                extraPrismaProductCache: [],
                extraPrismaLoading: true
            };
        case ExtraPrismaActionTypes.UPDATE_SCROLLING_POSITION:
            return {
                ...state,
                extraPrismaScrollPosition: action.payload
            };
        case ExtraPrismaActionTypes.GET_BATCH_FROM_CACHE:
            return {
                ...state,
                extraPrismaProducts: [...state.extraPrismaProducts, ...state.extraPrismaProductCache.slice(0, 20)],
                extraPrismaProductCache: state.extraPrismaProductCache.slice(20)
            };
        default:
            return state;
    }
}

export const getExtraPrismaLoading = (state: State) => state.extraPrismaLoading;
export const getExtraPrismaResponse = (state: State) => state.extraPrismaResponse;
export const getExtraPrismaRequest = (state: State) => state.extraPrismaRequest;
export const getExtraPrismaErrorResponse = (state: State) => state.extraPrismaErrorResponse;
export const getExtraPrismaProductCache = (state: State) => state.extraPrismaProductCache;
export const getExtraPrismaProducts = (state: State) => state.extraPrismaProducts;
export const getExtraPrismaScrollPosition = (state: State) => state.extraPrismaScrollPosition;

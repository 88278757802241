import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ChangePasswordDialogData} from '../../account/components/account-data/change-password/change-password-dialog.component';

export interface AlertDialogData {
  message: string;
  ok: string;
  heading: string;
}

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData) { }

  ngOnInit() {
  }

}

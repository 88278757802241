<h2 mat-dialog-title>Legen Sie ein neues Passwort fest</h2>
<div mat-dialog-content>
    <spinner *ngIf="loading"></spinner>
    <password-form [class]="loading ? 'invisible' : ''"
                   [credentialsService]="newPasswordRestService"
                   [requiresPassword]="true"
                   [oldPasswordPreset]="data?.oldPassword"
                   [showBackButton]="data?.showBackButton"
                   [forwardToContactFormOnFail]="false"
                   (loading)="onLoad($event)"
                   (success)="onSuccess()"
                   (fail)="onFail($event)"
                   (back)="onClose()"
                   backButtonText="Abbrechen"></password-form>
</div>

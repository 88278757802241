import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {RetourenRequestModel} from '../../models/retouren/retouren-request.model';

export enum RetourenActionTypes {
    RETOUREN_REQUEST = '[RETOUREN] Retouren request',
    RETOUREN_REQUEST_COMPLETE = '[RETOUREN] Retouren request complete',
    RETOUREN_REQUEST_FAIL = '[RETOUREN] Retouren request failed',
    RETOUREN_ANNOUNCEMENT = '[RETOUREN] Retouren announcement',
    RETOUREN_ANNOUNCEMENT_COMPLETE = '[RETOUREN] Retouren announcement complete',
    RETOUREN_ANNOUNCEMENT_FAIL = '[RETOUREN] Retouren announcement failed'
}

export class RetourenRequestAction implements Action {
    readonly type = RetourenActionTypes.RETOUREN_REQUEST;

    constructor(public payload: RetourenRequestModel) {
    }
}

export class RetourenRequestCompleteAction implements Action {
    readonly type = RetourenActionTypes.RETOUREN_REQUEST_COMPLETE;

    constructor(public payload: any) {
    }
}

export class RetourenRequestFailAction implements Action {
    readonly type = RetourenActionTypes.RETOUREN_REQUEST_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class RetourenAnnouncementAction implements Action {
    readonly type = RetourenActionTypes.RETOUREN_ANNOUNCEMENT;

    constructor(public payload: any) {
    }
}

export class RetourenAnnouncementCompleteAction implements Action {
    readonly type = RetourenActionTypes.RETOUREN_ANNOUNCEMENT_COMPLETE;

    constructor(public payload: any) {
    }
}

export class RetourenAnnouncementFailAction implements Action {
    readonly type = RetourenActionTypes.RETOUREN_ANNOUNCEMENT_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export type RetourenActions =
    RetourenRequestAction |
    RetourenRequestCompleteAction |
    RetourenRequestFailAction |
    RetourenAnnouncementAction |
    RetourenAnnouncementCompleteAction |
    RetourenAnnouncementFailAction;

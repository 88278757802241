import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountInfo} from '../../../core/models/registration/registration-confirm-response.model';
import {LogService} from '../../../core/services/utility/log.service';
import {IChangeDataService} from '../../../core/interfaces/change-data-service.interface';

@Component({
    selector: 'edit-data',
    templateUrl: './edit-data.component.html',
    styleUrls: ['./edit-data.component.scss']
})
export class EditDataComponent implements OnInit {
    @Input() data: any;
    @Input() token: any;
    @Input() restService: IChangeDataService;
    @Input() backButtonText: string = 'zurück';
    @Input() showHeadlines: boolean = true;
    @Input() small: boolean = false;

    @Output() correction: EventEmitter<any> = new EventEmitter<any>();
    @Output() application: EventEmitter<any> = new EventEmitter<any>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() fail: EventEmitter<string> = new EventEmitter<string>();

    public userForm: FormGroup;
    public salutation: FormControl = new FormControl('', Validators.required);
    public title: FormControl = new FormControl('');
    public firstName: FormControl = new FormControl('', Validators.required);
    public lastName: FormControl = new FormControl('', Validators.required);
    public name: FormControl = new FormControl('', Validators.required);
    public idf: FormControl = new FormControl('',
        [Validators.required, Validators.pattern(/^\d{2,7}$/)]);
    public street: FormControl = new FormControl('', Validators.required);
    public zipCode: FormControl = new FormControl('',
        [Validators.required, Validators.pattern(/^\d{5}$/)]);
    public city: FormControl = new FormControl('', Validators.required);
    public email: FormControl = new FormControl('',
        [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]);
    public phone: FormControl = new FormControl('',
        [Validators.required, Validators.pattern(/^(\+[1-9]{2})?[\d -]+$/)]);
    public fax: FormControl = new FormControl('',
        [Validators.pattern(/^(\+[1-9]{2})?[\d -]+$/)]);
    public submitted = false;

    constructor(@Inject(LogService) public log: LogService) {
        this.userForm = new FormGroup({
            salutation: this.salutation,
            title: this.title,
            firstName: this.firstName,
            lastName: this.lastName,
            name: this.name,
            idf: this.idf,
            street: this.street,
            zipCode: this.zipCode,
            city: this.city,
            email: this.email,
            phone: this.phone,
            fax: this.fax,
        });
    }

    ngOnInit() {
        if (!this.data) {
            this.data = new AccountInfo();
        }
        this.userForm.setValue(this.data);
    }

    correct() {
        this.correction.next();
    }

    apply() {
        this.submitted = true;
        if (!this.userForm.valid) {
            return;
        }
        this.loading.next(true);
        const data = {...this.userForm.value, ...{token: this.token}};
        this.restService.changeData(data).subscribe(
            result => {
                this.loading.next(false);
                this.log.info(result);
            },
            err => {
                this.loading.next(false);
                this.log.error(err);
                if (err.error && err.error.errorCode) {
                    this.fail.next(err.error.errorCode);
                } else {
                    this.fail.next(null);
                }
            });
        this.application.next();
    }
}

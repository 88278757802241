import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RangeSelectComponent} from './range-select.component';
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatRippleModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
    imports: [
        CommonModule,
        MatSelectModule,
        MatButtonModule,
        MatRippleModule,
        MatButtonToggleModule
    ],
    declarations: [RangeSelectComponent],
    exports: [RangeSelectComponent]
})
export class RangeSelectModule {
}

<!-- spin if user is loading//-->
<div class="page-center" *ngIf="(userLoading$ | async)">
  <i class="fal fa-spinner-third fa-spin"></i>&nbsp;Einen kurzen Moment bitte. Ihre Benutzerdaten werden geladen.
</div>

<!-- load content if user loading has finished and user is authenticated //-->
<div id="app" *ngIf="!(userLoading$ | async) && authenticated">
  <div id="view" class="d-flex flex-column mx-m">
    <app-nav></app-nav>
    <div class="main-grid" id="visible-view">
      <app-ads [position]="'LEFT'"></app-ads>
      <div id="main-content">
        <app-notices></app-notices>
        <ng-container>
          <router-outlet></router-outlet>
        </ng-container>
        <app-footer></app-footer>
      </div>
      <app-ads [position]="'RIGHT'"></app-ads>
    </div>
    <app-dialog-popup></app-dialog-popup>
  </div>
</div>
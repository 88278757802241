import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateRangeComponent} from './date-range.component';
import {RangeSelectModule} from "./range-select/range-select.module";
import {ReactiveFormsModule} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {RangeToggleModule} from "./range-toggle/range-toggle.module";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RangeToggleModule,
        RangeSelectModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatRippleModule,
        MatButtonToggleModule
    ],
    declarations: [DateRangeComponent],
    exports: [DateRangeComponent]
})
export class DateRangeModule {
}

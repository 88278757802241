import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import * as fromExclusiveOffersActions from '../actions/exclusive-offers.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {InvoicesRestService} from '../../services/api/invoices.rest-service';
import {WebshopRestService} from '../../services/api/webshop-rest.service';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {ProductType} from '../../models/shop/product-type.model';

@Injectable()
export class ExclusiveOffersEffects {
    /**
     * In case of SearchInvoicesAction we're going to call the InvoicesService and then
     * we'll post a LoadInvoicesCompleteAction to the reducer or in case of failure
     * LoadInvoicesFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadExclusiveOffers$: Observable<any> = this.actions$
        .pipe(ofType<fromExclusiveOffersActions.LoadExclusiveOffersAction>(
            fromExclusiveOffersActions.ExclusiveOffersActionTypes.LOAD_EXCLUSIVE_OFFERS
        ))
        .pipe(map(action => action), switchMap(() =>
            this.productRestService.loadExclusiveOffers().pipe(
                switchMap(data => {
                    const prodResp = new ProductsResponse({...data.returnObject, ...{type: ProductType.OffersExclusive}});
                        return [
                            new fromExclusiveOffersActions.LoadExclusiveOffersCompleteAction(prodResp),
                            new fromExclusiveOffersActions.GetBatchFromCacheAction()
                        ];
                }),
                catchError((error) => of(new fromExclusiveOffersActions.LoadExclusiveOffersFailAction(error)))
            )
        ));

    @Effect()
    searchExclusiveOffers$: Observable<any> = this.actions$
        .pipe(ofType<fromExclusiveOffersActions.SearchShopAction>(
            fromExclusiveOffersActions.ExclusiveOffersActionTypes.SEARCH_EXCLUSIVE_OFFERS
        ))
        .pipe(map(action => action), switchMap(() => [
            new fromExclusiveOffersActions.LoadExclusiveOffersAction()
        ]));

    /**
     * @param {InvoicesRestService} productRestService
     * @param {Actions} actions$
     */
    constructor(
        private productRestService: WebshopRestService,
        private actions$: Actions
    ) {
    }
}

<div class="dialog-top position-relative" *ngIf="!loading">
    <img class="w-100" src="{{data.image}}" alt="{{data.image_alt}}"/>
    <!--     <div *ngIf="data.highlight" class=" circle highlight text-center d-flex align-items-center justify-content-center">-->
    <!--     <span [innerHTML]="data.highlight"></span>-->
</div>

<div mat-dialog-content class="mt-xxl mb-0" *ngIf="!loading">
    <h2 mat-dialog-title class="pb-0 survey-title" [innerHTML]="data.title"></h2>
    <div *ngFor="let part of parsedBody">
        <div [innerHTML]="part.data" *ngIf="part.type == 'string'"></div>
        <app-forms [form]="part.data" *ngIf="part.type=='form'" (success)="onSuccess($event)"
                   [rating]="true"></app-forms>
        <div class="email w-100" *ngIf="part.type =='email'">
            <div class="d-flex justify-content-start align-items-center mb-s">
                <span class="oval">{{ part.email }}</span>
                <button class="btn btn-primary mt-0 ml-m" (click)="onSubscribe(part.email, part.data)"><i
                        class="fal fa-check"></i> OK
                </button>
            </div>
            <ng-container class="mt-m">
                <notification *ngIf="success" type="success" html="{{message}}"></notification>
            </ng-container>
        </div>
    </div>

</div>
<div mat-dialog-actions class="my-0 " *ngIf="!sent">
    <div class="d-flex justify-content-start align-items-end w-100">
        <button class="btn btn-primary" (click)="redirect()">Aboeinstellung Aktualisieren</button>
        <button class="btn btn-secondary" [mat-dialog-close]="true" (click)="subscribe(data.image_alt)">Später
            Erinnern
        </button>
    </div>
</div>
export class TreuhandConfigModel {
    idf: string;
    documentMappings: { [id: string]: string };
    send: { [id: string]: boolean };

    constructor(obj: any = {}) {
        this.idf = obj.idf;
        this.documentMappings = obj.documentMappings;
        this.send = obj.send;
    }
}

<div class="temp-nav-icon dropdown nav-item">
  <span class="username dropdown-toggle nav-link py-0" *ngIf="user" role="button" data-toggle="dropdown" aria-expanded="false">
    <i class="fal fa-user"></i>{{ user.name }}
  </span>
  <div class="dropdown-menu">
    <a [routerLink]="['/apothekenstammdaten']"
       class="dropdown-item py-xxs"
       (click)="trackingEvent('NAVIGATION.PREFERENCES.PHARMACY-DATA.LINK')" *ngIf="isOwner()">
      <span>{{ 'NAVIGATION.PREFERENCES.PHARMACY-DATA.LINK' | translate }}</span></a>
    <a [routerLink]="['/fragenundantworten']"
       routerLinkActive="navLinkActive"
       [routerLinkActiveOptions]="{exact:true}"
       class="dropdown-item py-xxs"
       (click)="trackingEvent('Hilfecenter')">
      Hilfecenter
    </a>
    <a (click)="logout()"
       data-cy="quick-nav-logout"
       class="px-m">
      <button class="btn btn-secondary my-2 btn-small"><span class="fal fa-unlock-alt"></span> Logout</button></a>
  </div>
</div>


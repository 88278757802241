import {Injectable, OnDestroy} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {Observable, Subject} from 'rxjs';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';

@Injectable({
    providedIn: 'root'
})

export class CategoryManagementSubRestService extends RestServiceAbstract {

    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    public requestSubscriptionInformation(): Observable<Response> {
        return this.get(`/usermanagement/subscription`).pipe(
            map(data => {
                return new Response(data);
            })
        );
    }

    public subscribeToCategoryManagement(): Observable<Response> {
        return this.post(`/usermanagement/subscription/subscribe`, null).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(res => {
                return new Response(res);
            })
        );
    }

    public optOutOfCategoryManagement(): Observable<Response> {
        return this.post(`/usermanagement/subscription/opt-out`, null).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            })
        );
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }

}

import {Inject, Injectable} from '@angular/core';
import {DokuLightService} from './doku-light.service';
import {ExtraPrismaService} from './extra-prisma.service';
import {TopPrismaService} from './top-prisma.service';
import {SpecialOffersService} from './special-offers.service';
import {ProductServiceAbstract} from './product-service.abstract';
import {ProductType} from '../../models/shop/product-type.model';
import * as fromReducers from '../../store/reducers';
import {ProductRequest} from '../../models/shop/product-request.model';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {Paragraph73Service} from './paragraph73.service';
import {ExclusiveOffersService} from './exclusive-offers.service';
import {ProFaxService} from './pro-fax.service';
import {PromoService} from './promo.service';
import {UberService} from './uber.service';
import {SubsequentDeliveriesService} from '../subsequent-deliveries.service';

@Injectable()
export class WebshopServiceFactory {
    constructor(
        @Inject(DokuLightService) private dokuLightService: DokuLightService,
        @Inject(ExtraPrismaService) private extraPrismaService: ExtraPrismaService,
        @Inject(TopPrismaService) private topPrismaService: TopPrismaService,
        @Inject(SpecialOffersService) private specialOffersService: SpecialOffersService,
        @Inject(Paragraph73Service) private paragraph73Service: Paragraph73Service,
        @Inject(ExclusiveOffersService) private exclusiveOffersService: ExclusiveOffersService,
        @Inject(ProFaxService) private proFaxService: ProFaxService,
        @Inject(PromoService) private promoService: PromoService,
        @Inject(UberService) private uberService: UberService,
        @Inject(SubsequentDeliveriesService) private subsequentDeliveriesService: SubsequentDeliveriesService) {
    }

    static fetchWebshopRequestFromState(type: ProductType, state: fromReducers.State): ProductRequest {
        switch (type) {
            case ProductType.DokuLight:
                return state.dokuLight.dokuLightRequest;
            case ProductType.ExtraPrisma:
                return state.extraPrisma.extraPrismaRequest;
            case ProductType.TopPrisma:
                return state.topPrisma.topPrismaRequest;
            case ProductType.OffersPin:
                return state.pin.specialOffersRequest;
            case ProductType.Paragraph73:
                return state.paragraph73.paragraph73Request;
            case ProductType.OffersExclusive:
                return state.exclusiveOffers.exclusiveOffersRequest;
            case ProductType.Profax:
                return state.proFax.proFaxRequest;
            case ProductType.Promo:
                return state.promo.promoRequest;
            case ProductType.Uber:
                return state.uber.uberRequest;
            case ProductType.SubsequentDelivery:
                return state.subsequentDeliveries.productsRequest;
            default:
                return null;
        }
    }

    static fetchWebshopResponseFromState(type: ProductType, state: fromReducers.State): ProductsResponse {
        switch (type) {
            case ProductType.DokuLight:
                return state.dokuLight.dokuLightResponse;
            case ProductType.ExtraPrisma:
                return state.extraPrisma.extraPrismaResponse;
            case ProductType.TopPrisma:
                return state.topPrisma.topPrismaResponse;
            case ProductType.OffersPin:
                return state.pin.specialOffersResponse;
            case ProductType.Paragraph73:
                return state.paragraph73.paragraph73Response;
            case ProductType.OffersExclusive:
                return state.exclusiveOffers.exclusiveOffersResponse;
            case ProductType.Profax:
                return state.proFax.proFaxResponse;
            case ProductType.Promo:
                return state.promo.promoResponse;
            case ProductType.Uber:
                return state.uber.uberResponse;
            case ProductType.SubsequentDelivery:
                return state.subsequentDeliveries.productsResponse;
            default:
                return null;
        }
    }

    createWebshopService(type: ProductType): ProductServiceAbstract {
        switch (type) {
            case ProductType.DokuLight:
                return this.dokuLightService;
            case ProductType.ExtraPrisma:
                return this.extraPrismaService;
            case ProductType.TopPrisma:
                return this.topPrismaService;
            case ProductType.OffersPin:
                return this.specialOffersService;
            case ProductType.Paragraph73:
                return this.paragraph73Service;
            case ProductType.OffersExclusive:
                return this.exclusiveOffersService;
            case ProductType.Profax:
                return this.proFaxService;
            case ProductType.Promo:
                return this.promoService;
            case ProductType.Uber:
                return this.uberService;
            case ProductType.SubsequentDelivery:
                return this.subsequentDeliveriesService;
            default:
                return null;
        }
    }

}

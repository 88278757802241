import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UberweiserOpenDialogModel} from './uberweiser-open-dialog.model';

@Component({
    selector: 'uberweiser-open-dialog',
    templateUrl: './uberweiser-open-dialog.html',
    styleUrls: ['./uberweiser-open-dialog.scss']
})

export class UberweiserOpenDialogComponent {
    title: string;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<UberweiserOpenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UberweiserOpenDialogModel) {
        this.title = data.title;
        this.message = data.message;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

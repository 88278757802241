import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Injectable, OnDestroy} from '@angular/core';
import * as fromReducers from '../store/reducers';
import * as actions from '../store/actions/subsequent-deliveries.actions';
import {LogService} from './utility/log.service';
import {SubsequentDeliveriesResponse} from '../models/subsequent-deliveries/subsequent.deliveries.response';
import {SubsequentDeliveriesRequest} from '../models/subsequent-deliveries/subsequent.deliveries.request';
import {SubsequentDeliveriesUpdate} from '../models/subsequent-deliveries/subsequent.deliveries.update';
import {SubsequentDeliveriesRestService} from './api/subsequent.deliveries.rest-service';
import {ProductServiceAbstract} from './shop/product-service.abstract';
import {WebshopRestService} from './api/webshop-rest.service';
import {ProductsResponse} from '../models/shop/products-response.model';
import {ProductRequest} from '../models/shop/product-request.model';
import {Product} from '../models/shop/product.model';
import {takeUntil} from 'rxjs/operators';
import {SubsequentDeliveryAddRequestModel} from '../models/subsequent-deliveries/subsequent.delivery.add.request.model';

@Injectable({
    providedIn: 'root',
})
export class SubsequentDeliveriesService extends ProductServiceAbstract implements OnDestroy {
    public ngUnsubscribe$ = new Subject<any>();
    deliveries$: Observable<SubsequentDeliveriesResponse>;
    deliveriesResponse: SubsequentDeliveriesResponse;
    public deliveryAvailability$ = new Subject<any>();

    constructor(
        public store: Store<fromReducers.State>,
        public log: LogService,
        public webshopRestService: WebshopRestService,
        public subsequentDeliveriesRestService: SubsequentDeliveriesRestService) {
        super(store, webshopRestService);
    }

    getDeliveriesList(idf) {
        const deliverisRequest = new SubsequentDeliveriesRequest({idf: idf});
        this.store.dispatch(new actions.LoadSubsequentDeliveries(deliverisRequest));

        return this.getDeliveriesObservable();
    }

    updateDelivery(delivery, qty) {
        const forUpdate = delivery;
        forUpdate.quantity = qty;
        const updated = new SubsequentDeliveriesUpdate(forUpdate);

        return this.subsequentDeliveriesRestService.updateSubsequentDelivery(updated);
    }

    deleteDelivery(delivery) {
        const forDelete = delivery;
        forDelete.quantity = 0;
        const deleted = new SubsequentDeliveriesUpdate(forDelete);

        return this.subsequentDeliveriesRestService.updateSubsequentDelivery(deleted);
    }

    getDeliveriesObservable() {
        return this.store.pipe(select(fromReducers.getSubsequentDeliveries));
    }

    getDeliveriesLoadingObservable() {
        return this.store.pipe(select(fromReducers.getSubsequentDeliveriesLoading));
    }

    getDeliveriesErrorObservable() {
        return this.store.pipe(select(fromReducers.getSubsequentDeliveriesError));
    }

    /**
     * Add Subsequent Delivery
     */
    newSubsequentDeliveryRequest(request: SubsequentDeliveryAddRequestModel) {
        this.store.dispatch(new actions.AddNewSubsequentDelivery());
        this.subsequentDeliveriesRestService
            .addNewSubsequentDelivery(request)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response: any) => {
                if (response.errorCode === null) {
                    this.store.dispatch(new actions.AddNewSubsequentDeliveryComplete(response.returnObject));
                    this.getDeliveriesList(request.idf);
                } else {
                    this.store.dispatch(new actions.AddNewSubsequentDeliveryFailed(response.errorCode));
                }
            });
    }

    newSubsequentDeliveryLoadingObservable() {
        return this.store.pipe(select(fromReducers.getAddSubsequentDeliveryLoading));
    }

    newSubsequentDeliveryErrorObservable() {
        return this.store.pipe(select(fromReducers.getAddSubsequentDeliveryErrorResponse));
    }

    checkAvailability(request) {
        this.deliveryAvailability$ = new Subject<any>();
        this.subsequentDeliveriesRestService.subsequentDeliveryAvailability(request)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response: any) => {
                if (response.errorCode === null) {
                    return this.deliveryAvailability$.next(response.returnObject.deliveryInformation);
                }
                return this.deliveryAvailability$;
            });
        return this.deliveryAvailability$.asObservable();
    }

    /**
     * Product search
    */
    displayNewBatch(request: ProductRequest): void {
    }

    searchProducts(request: ProductRequest): void {
        this.store.dispatch(new actions.SearchShopAction(request));
    }

    updateScrollingPosition(pos: number): void {
    }

    resetSearch(): void {
        this.store.dispatch(new actions.ResetSearchResult());
    }

    /**
     * GETTERS
    */
    getProductsLoadingObservable(): Observable<boolean> {
        this.productsLoading = this.store.pipe(select(fromReducers.getProductsLoading));
        return this.productsLoading;
    }

    getProductsResponseObservable(): Observable<ProductsResponse> {
        this.productsResponse$ = this.store.pipe(select(fromReducers.getProductsResponse));
        return this.productsResponse$;
    }

    getProductsRequestObservable(): Observable<ProductRequest> {
        this.productsRequest$ = this.store.pipe(select(fromReducers.getProductsRequest));
        return this.productsRequest$;
    }

    getProductsObservable(): Observable<Product[]> {
        this.products$ = this.store.pipe(select(fromReducers.getProducts));
        return this.products$;
    }

    getScrollPositionObservable(): Observable<number> {
        return this.scrollPosition$;
    }

    getProductCacheObservable(): Observable<Product[]> {
        return this.productCache$;
    }
    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

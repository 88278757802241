import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import * as fromMessageActions from '../actions/message.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MessageRestService} from "../../services/api/message/message-rest.service";

@Injectable()
export class MessageEffects {

    @Effect()
    initMessages$: Observable<Action> = this.actions$
        .pipe(ofType<fromMessageActions.InitMessagesAction>(fromMessageActions.MessageActionTypes.INIT_MESSAGES))
        .pipe(map(action => action), switchMap(() =>
            this.messageService.initMessages().pipe(
                map(data => new fromMessageActions.InitMessagesCompleteAction(data.returnObject)),
                catchError((error) => of(new fromMessageActions.ErrorAction(error)))
            )
        ));

    @Effect()
    loadMessages$: Observable<Action> = this.actions$
        .pipe(ofType<fromMessageActions.LoadMessagesAction>(fromMessageActions.MessageActionTypes.LOAD_MESSAGES))
        .pipe(map(action => action.payload), switchMap((rq) =>
            this.messageService.loadMessages(rq).pipe(
                map(data => new fromMessageActions.LoadMessagesCompleteAction(data.returnObject)),
                catchError((error) => of(new fromMessageActions.ErrorAction(error)))
            )
        ));

    @Effect()
    loadMessage$: Observable<Action> = this.actions$
        .pipe(ofType<fromMessageActions.LoadMessageAction>(fromMessageActions.MessageActionTypes.LOAD_MESSAGE))
        .pipe(map(action => action), switchMap((rq) =>
            this.messageService.loadMessage(rq).pipe(
                map(data => new fromMessageActions.LoadMessageCompleteAction(data.returnObject)),
                catchError((error) => of(new fromMessageActions.ErrorAction(error)))
            )
        ));

    constructor(
        private messageService: MessageRestService,
        private actions$: Actions
    ) {}
}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {TreuhandRestService} from '../../../services/api/treuhand-rest.service';
import * as fromTreuhandActions from '../../actions/integrations/treuhand.actions';
import {TreuhandConfigModel} from '../../../models/integrations/treuhand.config.model';

@Injectable()
export class TreuhandEffects {
    constructor(private actions$: Actions,
                private treuhandRestService: TreuhandRestService) {
    }

    getTreuhand$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromTreuhandActions.GetTreuhandConfigAction>(fromTreuhandActions.TreuhandActionTypes.GET_TREUHAND_CONFIG))
            .pipe(map(action => action.payload), switchMap(idf =>
                this.treuhandRestService.getTreuhandConfiguration(idf).pipe(
                    map(data => new fromTreuhandActions.GetTreuhandConfigCompleteAction(new TreuhandConfigModel(data.returnObject))),
                    catchError((error) => of(new fromTreuhandActions.GetTreuhandConfigFailAction(error)))
                )
            ));
    });

    setTreuhand$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromTreuhandActions.SetTreuhandConfigAction>(fromTreuhandActions.TreuhandActionTypes.SET_TREUHAND_CONFIG))
            .pipe(map(action => action.payload), switchMap(request =>
                this.treuhandRestService.setTreuhandConfiguration(request).pipe(
                    map(data => new fromTreuhandActions.SetTreuhandConfigCompleteAction(request)),
                    catchError((error) => of(new fromTreuhandActions.SetTreuhandConfigFailAction(error)))
                )
            ));
    });

    copyTreuhand$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromTreuhandActions.CopyTreuhandConfigAction>(fromTreuhandActions.TreuhandActionTypes.COPY_TREUHAND_CONFIG))
            .pipe(map(action => action.payload), switchMap(request =>
                this.treuhandRestService.copyConfiguration(request).pipe(
                    map(data => new fromTreuhandActions.CopyTreuhandConfigCompleteAction(request)),
                    catchError((error) => of(new fromTreuhandActions.CopyTreuhandConfigFailAction(error)))
                )
            ));
    });

    treuhandConfigIdfs$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromTreuhandActions.GetTreuhandConfigIdfsAction>(fromTreuhandActions.TreuhandActionTypes.GET_TREUHAND_CONFIG_IDFS))
            .pipe(map(action => action.payload), switchMap(idf =>
                this.treuhandRestService.getIdfsWithConfiguration(idf).pipe(
                    map(data => new fromTreuhandActions.GetTreuhandConfigIdfsCompleteAction(data.returnObject)),
                    catchError((error) => of(new fromTreuhandActions.GetTreuhandConfigIdfsFailAction(error)))
                )
            ));
    });

}

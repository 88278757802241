import {AuthorizationNodeInterface} from '../interface/authorization-node.interface';

export class AuthorizationNodeModel implements AuthorizationNodeInterface {
    authority: string;
    children: AuthorizationNodeModel[];
    granted: number;
    label: string;
    readonly: boolean;
    idf: string;
    expandable: boolean;
    level: number;
    feature: string;

    constructor(obj?: any) {
        if (obj && obj.idf === true) {
            this.idf = obj.label;
        }
        if (obj && obj.idf && (typeof obj.idf === 'string' || obj.idf instanceof String)) {
            this.idf = obj.idf;
        }
        if (obj && obj.children && obj.children.length > 0) {
            this.children = obj.children.map(node => new AuthorizationNodeModel({...node, idf: this.idf})) || null;
        } else {
            delete this.children;
        }

        this.authority = obj && obj.authority || null;
        this.label = obj && obj.label || null;
        this.readonly = obj && obj.readonly || null;
        this.expandable = obj && obj.expandable || null;
        this.level = obj && obj.level || null;
        this.feature = obj && obj.feature || null;
    }

}

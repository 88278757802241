import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PriceScaleComponent} from './price-scale.component';
import {PriceScaleP73Component} from './price-scale-p73/price-scale-p73.component';
import {PriceScalePaybackComponent} from './price-scale-payback/price-scale-payback.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PriceScaleComponent,
        PriceScaleP73Component,
        PriceScalePaybackComponent
    ],
    exports: [
        PriceScaleComponent,
        PriceScaleP73Component,
        PriceScalePaybackComponent
    ]

})
export class PriceScaleModule {
}

import {Injectable} from '@angular/core';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Subject, throwError} from 'rxjs';

import {HttpErrorResponse} from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {Response} from '../../models/response.model';
import {of} from 'rxjs/internal/observable/of';


@Injectable({
    providedIn: 'root',
})

export class DatevService extends RestServiceAbstract {
    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    tokenUrl = '/datev/token';

    getDatevUrl(clientId) {
        const check = this.randomString(43);
        sessionStorage.setItem('datevCheck', check);

        return this.DATEV_LINK + '/authorize?state=01234567890123456789&scope=openid offline_access datev:iam:client:' + clientId + ' datev:accounting:clients accounting:clients:read accounting:documents&response_type=code id_token&redirect_uri=' + this.DATEV_REDIRECT + '&client_id=641bacd9e46d90fdaff5dbd1408f3cf0&nonce=bf3dc569bde068db1976ee1fd740382d&code_challenge='
            + this.encodeString(check) + '&code_challenge_method=S256&response_mode=query';

    }

    getToken(code, clientId, idf) {
        return this.post(this.tokenUrl,
            {
                redirect: this.DATEV_REDIRECT,
                check: sessionStorage.getItem('datevCheck'),
                code: code,
                client: clientId,
                idf: idf
            }).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }));
    }

    hasDatev(idf) {
        return this.get('/datev/datevUser', {params: {idf: idf}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError (err => {
                return throwError(err);
            }));
    }

    getDatevClients() {
        return this.get('/datev/clients').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }));
    }

    saveConfiguration(data, idf) {
        data['idf'] = idf;

        return this.post('/datev/configuration/', data).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            }));
    }

    getConfiguration(idf) {
        return this.get('/datev/configuration/customer', {params: {idf: idf}} ).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            }));
    }

    getDatevLogs(idf) {
        return this.get('/datev/notification/latestLogs', {params: {idf: idf}} ).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            }));
    }

    getLatestError (idf) {
        return this.get('/datev/notification/lastError', {params: {idf: idf}} ).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            }));
    }


    private randomString(length) {
        const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        let result = '';
        for (let i = 0; i < length; i++) {
           result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    private stringToAscii(str) {
        let reserved = '';
        const code = str.match(/&#(d+);/g);
        if (code === null) {
            return str;
        }
        for (let i = 0; i < code.length; i++) {
            reserved += String.fromCharCode(code[i].replace(/[&#;]/g, ''));
        }
        return reserved;
    }

    private encodeString(string) {
        return CryptoJS.SHA256(string).toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    }

    deactivate(idf) {
        return this.get('/datev/unsubscribe',{params: {idf: idf}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            }));
    }

    getPhoenixDocuments(idf) {
        return this.get('/resource/documents/allDocTypes').pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            })
        );
    }


    getDatevDocuments(idf) {
        return this.get('/datev/configuration/getDocumentTypes',{params: {idf: idf}}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            }),
            catchError (err => {
                console.log(err);
                return of({errorCode:0, returnObject: null});
            })
        );
    }

    sendLatestFiles(idf) {

        const idfList = {'idfs': [idf]};

        return this.post('/datev/documentsForIdfs', idfList).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(resp => {
                return new Response(resp);
            }));
    }

    migrateToken(code, clientId: string, idf: string) {
        return this.post('/datev/migrate',
            {
                redirect: this.DATEV_REDIRECT,
                check: sessionStorage.getItem('datevCheck'),
                code: code,
                client: clientId,
                idf: idf
            }).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }));
    }
}

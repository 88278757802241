import * as fromSubUserActions from '../actions/sub-user.actions';
import {Inject, Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../services/api/auth.service';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {UserRestService} from '../../services/api/user.rest-service';
import {SubUsersResponseModel} from '../../../modules/account/components/sub-user-management/model/sub-users-response.model';
import {SubUserRestService} from '../../../modules/account/components/sub-user-management/service/sub-user.rest-service';

@Injectable()
export class SubUserEffects {

    /**
     * @type {Observable<any[]>}
     */
    @Effect()
    loadSubUsers$: Observable<Action> = this.actions$
        .pipe(ofType<fromSubUserActions.LoadSubUsers>(fromSubUserActions.SubUserActionTypes.LoadSubUsers))
        .pipe(map(action => action), switchMap(() =>
                this.subUserRestService.loadSubUsers().pipe(
                    switchMap(data => [
                        new fromSubUserActions.LoadSubUsersComplete(new SubUsersResponseModel(data.returnObject))
                    ]),
                    catchError((error) => of(new fromSubUserActions.LoadSubUsersFailed(error)))
                )
            )
        );

    /**
     * @type {Observable<any[]>}
     */
    @Effect()
    initAuthTree$: Observable<Action> = this.actions$
        .pipe(ofType<fromSubUserActions.InitAuthTree>(fromSubUserActions.SubUserActionTypes.InitAuthTree))
        .pipe(map(action => action), switchMap(() =>
                this.subUserRestService.requestAuthTree().pipe(
                    switchMap(data => [
                        new fromSubUserActions.InitAuthTreeComplete(data.returnObject),
                    ]),
                    catchError((error) => of(new fromSubUserActions.InitAuthTreeFailed(error)))
                )
            )
        );

    /**
     * @param {AuthService} authService
     * @param {UserRestService} userRestService
     * @param {Actions} actions$
     */
    constructor(
        private actions$: Actions,
        private subUserRestService: SubUserRestService,
        @Inject('CMS_URL') private CMS_URL: String
    ) {
    }
}
